import React from 'react'

// general imports
import { IconPropsType } from '../types'

const PaddingIcon = ({ width = 12, height = 12 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333ZM10.6667 10.6667H1.33333V1.33333H10.6667V10.6667ZM5.33333 2.66667H6.66667V4H5.33333V2.66667ZM2.66667 2.66667H4V4H2.66667V2.66667ZM8 2.66667H9.33333V4H8V2.66667Z'
        fill='#336175'
      />
    </svg>
  )
}

export default PaddingIcon
