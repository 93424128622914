// general imports
import { IconPropsType } from '../types'

const StackedLinesIcon = ({ width = 16, height = 16 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.33325 14.3333L1.33325 13.3333L6.33325 8.33333L8.99992 11L13.7333 5.66666L14.6666 6.6L8.99992 13L6.33325 10.3333L2.33325 14.3333ZM2.33325 10.3333L1.33325 9.33333L6.33325 4.33333L8.99992 7L13.7333 1.66666L14.6666 2.6L8.99992 9L6.33325 6.33333L2.33325 10.3333Z'
        fill='#717171'
      />
    </svg>
  )
}

export default StackedLinesIcon
