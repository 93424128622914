import React from 'react'
import { IconPropsType } from '../types'

const UnderlineCheckmarkIcon = ({ width = 17, height = 16 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.63 1.41L15.22 0L6.02999 9.19L1.78 4.95L0.369995 6.36L6.02999 12.02L16.63 1.41Z'
        fill='black'
      />
      <path d='M15.5 14H1.5V16H15.5V14Z' fill='black' />
    </svg>
  )
}

export default UnderlineCheckmarkIcon
