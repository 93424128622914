// general imports
import { IconPropsType } from '../types'

const ControlButtonIcon = ({ width = 45, height = 40 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 45 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='44' height='39' rx='4.5' fill='white' />
      <path
        d='M21.5 26C21.2167 26 20.9793 25.904 20.788 25.712C20.596 25.5207 20.5 25.2833 20.5 25C20.5 24.7167 20.596 24.4793 20.788 24.288C20.9793 24.096 21.2167 24 21.5 24H23.5C23.7833 24 24.021 24.096 24.213 24.288C24.4043 24.4793 24.5 24.7167 24.5 25C24.5 25.2833 24.4043 25.5207 24.213 25.712C24.021 25.904 23.7833 26 23.5 26H21.5ZM14.5 16C14.2167 16 13.9793 15.9043 13.788 15.713C13.596 15.521 13.5 15.2833 13.5 15C13.5 14.7167 13.596 14.479 13.788 14.287C13.9793 14.0957 14.2167 14 14.5 14H30.5C30.7833 14 31.0207 14.0957 31.212 14.287C31.404 14.479 31.5 14.7167 31.5 15C31.5 15.2833 31.404 15.521 31.212 15.713C31.0207 15.9043 30.7833 16 30.5 16H14.5ZM17.5 21C17.2167 21 16.979 20.904 16.787 20.712C16.5957 20.5207 16.5 20.2833 16.5 20C16.5 19.7167 16.5957 19.479 16.787 19.287C16.979 19.0957 17.2167 19 17.5 19H27.5C27.7833 19 28.0207 19.0957 28.212 19.287C28.404 19.479 28.5 19.7167 28.5 20C28.5 20.2833 28.404 20.5207 28.212 20.712C28.0207 20.904 27.7833 21 27.5 21H17.5Z'
        fill='#202325'
      />
      <rect x='0.5' y='0.5' width='44' height='39' rx='4.5' stroke='#E3E5E6' />
    </svg>
  )
}

export default ControlButtonIcon
