import React from 'react'
import { IconPropsType } from '../types'

const CheckmarkIcon = ({ width = 84, height = 84, color = '#007fb6' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 84 84'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.4167 42.0002L38.0834 53.7502L57.6667 26.3335M42 81.1668C63.6318 81.1668 81.1667 63.6319 81.1667 42.0002C81.1667 20.3684 63.6318 2.8335 42 2.8335C20.3683 2.8335 2.83337 20.3684 2.83337 42.0002C2.83337 63.6319 20.3683 81.1668 42 81.1668Z'
        stroke={color}
        strokeWidth='5'
      />
    </svg>
  )
}

export default CheckmarkIcon
