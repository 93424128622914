import React from 'react'

// stylesheet
import styles from './notfound.module.css'

const NotFoundPage = () => {
  return <div className={styles.not__found}>NotFoundPage</div>
}

export default NotFoundPage
