import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { getExceptionPayload } from '../../../../lib/utils/get-exception-payload'
import { TargetService } from '../../../../services/api/admin/target/target.service'
import { ApiRequestErrorType } from '../../../../types/api/api.types'

const targetService = new TargetService()

export const setTargetThunk = createAsyncThunk(
  '/transaction/set-target',
  async (data: { target: string; token: string }, { rejectWithValue }) => {
    try {
      const response = await targetService.setTarget(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getTargetThunk = createAsyncThunk(
  '/transaction/get-target',
  async ({ token, date }: { token: string; date: string }, { rejectWithValue }) => {
    try {
      const response = await targetService.getTarget(token, date)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
