import { DashboardDefaultType } from '../../default'
import { APP_CONSTANTS } from '../../repository/constants/constants'
import { DashboardType } from '../../repository/data/dashboard-links'
import { GuardPropType } from '../../types/router.types'

export const guestGuard = (props: GuardPropType) => {
  console.log('Guard Props: ', props)
  return '/'
}

export const determineDashboardPath = () => {
  return DashboardDefaultType === (3 as DashboardType)
    ? APP_CONSTANTS.APP_PATHS.SUPER_ADMIN
    : APP_CONSTANTS.APP_PATHS.DASHBOARD
}

const verifyAuthRouteAccess = (props: GuardPropType) => {
  const { state, location } = props
  if (
    state.authSlice.user != null &&
    state.authSlice.user?.verifiedEmail &&
    state.authSlice.accessToken
  ) {
    return determineDashboardPath()
  }
  if (state.authSlice.user != null && !state.authSlice.user?.verifiedEmail) {
    return APP_CONSTANTS.APP_PATHS.LOGIN
  }
  return location.pathname
}

const verifyUserAuthenticity = (props: GuardPropType) => {
  const { state, location } = props

  if (state.authSlice.user && state.authSlice.accessToken && state.authSlice.isSuccess) {
    if (!state.authSlice.user?.verifiedEmail) {
      return APP_CONSTANTS.APP_PATHS.LOGIN
    }
    if (state.authSlice.user.superAdmin) {
      if (!location.pathname.startsWith('/super-admin')) return APP_CONSTANTS.APP_PATHS.SUPER_ADMIN
    } else if (!state.authSlice.user.superAdmin) {
      if (!location.pathname.startsWith('/dashboard')) return APP_CONSTANTS.APP_PATHS.DASHBOARD
    } else {
      return APP_CONSTANTS.APP_PATHS.LOGIN
    }
    return location.pathname
  }

  return APP_CONSTANTS.APP_PATHS.LOGIN
}

// eslint-disable-next-line
export default { guestGuard, verifyAuthRouteAccess, verifyUserAuthenticity }
