import { createAsyncThunk } from '@reduxjs/toolkit'
import { getExceptionPayload } from '../../lib/utils/get-exception-payload'
import { NotificationService } from '../../services/api/notifications.service'
import { ApiRequestErrorType } from '../../types/api/api.types'

const notificationService = new NotificationService()

export const getNotificationsThunk = createAsyncThunk(
  '/notifications',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await notificationService.getNotifications(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const readNotificationThunk = createAsyncThunk(
  '/notifications/read',
  async (
    { token, notificationId }: { token: string; notificationId: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await notificationService.readNotification(notificationId, token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteNotificationThunk = createAsyncThunk(
  '/notifications/delete',
  async (
    { token, notificationId }: { token: string; notificationId: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await notificationService.deleteNotification(notificationId, token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
