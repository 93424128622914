import { createSlice } from '@reduxjs/toolkit'

// general imports
import { TargetInterface } from '../../../../components/molecules/target-card/TargetCard.molecule'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api/api.types'
import { getTargetThunk, setTargetThunk } from '../thunk/target.thunk'

export type RegistrationState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  // eslint-disable-next-line
  target: TargetInterface | any
}

export const initialState: RegistrationState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  target: {},
}

const targetAdminSlice = createSlice({
  name: 'targetAdminSlice',
  initialState: initialState,
  reducers: {
    resetTargetState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setTargetThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(setTargetThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.target = action.payload
      })
      .addCase(setTargetThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getTargetThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(getTargetThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.target = action.payload
      })
      .addCase(getTargetThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetTargetState } = targetAdminSlice.actions
export default targetAdminSlice.reducer
