// general imports
import { IconPropsType } from '../types'

const BarGraphOutlineIcon = ({ width = 14, height = 14 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1074_8037)'>
        <path
          d='M8.16659 5.25H5.83325C5.67854 5.25 5.53017 5.31146 5.42077 5.42086C5.31138 5.53025 5.24992 5.67863 5.24992 5.83334V12.8333C5.24992 12.988 5.31138 13.1364 5.42077 13.2458C5.53017 13.3552 5.67854 13.4167 5.83325 13.4167H8.16659C8.3213 13.4167 8.46967 13.3552 8.57906 13.2458C8.68846 13.1364 8.74992 12.988 8.74992 12.8333V5.83334C8.74992 5.67863 8.68846 5.53025 8.57906 5.42086C8.46967 5.31146 8.3213 5.25 8.16659 5.25ZM7.58325 12.25H6.41659V6.41667H7.58325V12.25ZM12.8333 0.583336H10.4999C10.3452 0.583336 10.1968 0.644794 10.0874 0.75419C9.97804 0.863586 9.91659 1.01196 9.91659 1.16667V12.8333C9.91659 12.988 9.97804 13.1364 10.0874 13.2458C10.1968 13.3552 10.3452 13.4167 10.4999 13.4167H12.8333C12.988 13.4167 13.1363 13.3552 13.2457 13.2458C13.3551 13.1364 13.4166 12.988 13.4166 12.8333V1.16667C13.4166 1.01196 13.3551 0.863586 13.2457 0.75419C13.1363 0.644794 12.988 0.583336 12.8333 0.583336ZM12.2499 12.25H11.0833V1.75H12.2499V12.25ZM3.49992 8.75H1.16659C1.01188 8.75 0.863503 8.81146 0.754106 8.92086C0.64471 9.03025 0.583252 9.17863 0.583252 9.33334V12.8333C0.583252 12.988 0.64471 13.1364 0.754106 13.2458C0.863503 13.3552 1.01188 13.4167 1.16659 13.4167H3.49992C3.65463 13.4167 3.803 13.3552 3.9124 13.2458C4.02179 13.1364 4.08325 12.988 4.08325 12.8333V9.33334C4.08325 9.17863 4.02179 9.03025 3.9124 8.92086C3.803 8.81146 3.65463 8.75 3.49992 8.75ZM2.91659 12.25H1.74992V9.91667H2.91659V12.25Z'
          fill='#717171'
        />
      </g>
      <defs>
        <clipPath id='clip0_1074_8037'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BarGraphOutlineIcon
