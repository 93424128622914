// general imports
import { IconPropsType } from '../types'

const DownloadIcon = ({ width = 24, height = 24, color = 'currenColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.25038 15.5026V15.9972C5.21722 15.9604 4.22884 15.5587 3.4621 14.8611C2.66371 14.1348 2.162 13.1389 2.05344 12.065C1.94489 10.9911 2.23714 9.91503 2.87401 9.04358C3.51088 8.17214 4.44741 7.56687 5.50355 7.34416L5.82121 7.27718L5.88927 6.95974C6.18907 5.56149 6.9593 4.30834 8.07143 3.40938C9.18357 2.51041 10.5704 2.02002 12.0004 2.02002C13.4304 2.02002 14.8172 2.51041 15.9293 3.40938C17.0415 4.30834 17.8117 5.56149 18.1115 6.95974L18.1796 7.27718L18.4972 7.34416C19.5534 7.56687 20.4899 8.17214 21.1268 9.04358C21.7636 9.91503 22.0559 10.9911 21.9473 12.065C21.8388 13.1389 21.3371 14.1348 20.5387 14.8611C19.7719 15.5587 18.7835 15.9604 17.7504 15.9972V15.5026C18.7268 15.5052 19.6704 15.139 20.3905 14.4743C21.1462 13.7767 21.5939 12.8075 21.635 11.7799C21.6761 10.7523 21.3073 9.75045 20.6097 8.99476C19.9122 8.23906 18.943 7.79143 17.9154 7.75032L17.9054 7.74992H17.8954H17.6931L17.6717 7.5744L17.6717 7.57439L17.6711 7.5696C17.4889 6.18668 16.8101 4.91716 15.7614 3.99752C14.7126 3.07788 13.3653 2.57083 11.9704 2.57083C10.5755 2.57083 9.22819 3.07788 8.17941 3.99752C7.13063 4.91716 6.45191 6.18668 6.26967 7.5696L6.26618 7.59605L6.26553 7.62273L6.26243 7.74992H6.10538H6.09539L6.0854 7.75032C5.05779 7.79143 4.0886 8.23906 3.39104 8.99476C2.69347 9.75045 2.32468 10.7523 2.36578 11.7799C2.40689 12.8075 2.85452 13.7767 3.61022 14.4743C4.33032 15.139 5.27394 15.5052 6.25038 15.5026Z'
        fill={color}
        stroke={color}
      />
      <path
        d='M12.75 19.6275V10.5H11.25V19.6275L9.3075 17.6925L8.25 18.75L12 22.5L15.75 18.75L14.6925 17.6925L12.75 19.6275Z'
        fill={color}
      />
    </svg>
  )
}

export default DownloadIcon
