import React from 'react'

// general imports
import { IconPropsType } from '../types'

const BellIcon = ({ width = 48, height = 48, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42 24C42 14.0625 33.9375 6 24 6C14.0625 6 6 14.0625 6 24C6 33.9375 14.0625 42 24 42C33.9375 42 42 33.9375 42 24Z'
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M34.2375 29.3438C32.7066 27.5306 31.6257 26.7684 31.6257 21.7687C31.6257 17.1881 29.2088 15.5597 27.2194 14.7684C27.0822 14.7112 26.9584 14.6259 26.8561 14.5181C26.7537 14.4102 26.675 14.2822 26.625 14.1422C26.2772 12.9938 25.3013 12 24 12C22.6988 12 21.721 12.9938 21.375 14.1431C21.3253 14.2832 21.2468 14.4112 21.1446 14.5191C21.0424 14.627 20.9188 14.7122 20.7816 14.7694C18.7903 15.5625 16.3753 17.1881 16.3753 21.7697C16.3753 26.7694 15.2935 27.5316 13.7625 29.3447C13.1278 30.0947 13.7016 31.5009 14.8116 31.5009H33.1875C34.291 31.5 34.8694 30.0938 34.2375 29.3438ZM20.6475 33C20.5948 32.9997 20.5426 33.0105 20.4943 33.0318C20.446 33.0531 20.4028 33.0843 20.3674 33.1234C20.332 33.1625 20.3053 33.2086 20.2889 33.2588C20.2726 33.3089 20.267 33.3619 20.2725 33.4144C20.4835 35.1694 22.0416 36 24 36C25.9378 36 27.4819 35.1422 27.7182 33.42C27.7245 33.3671 27.7196 33.3135 27.7036 33.2626C27.6877 33.2118 27.6611 33.165 27.6256 33.1252C27.5901 33.0855 27.5466 33.0538 27.4979 33.0322C27.4492 33.0106 27.3964 32.9996 27.3432 33H20.6475Z'
        fill={color}
      />
    </svg>
  )
}

export default BellIcon
