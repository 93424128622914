import React from 'react'
import { IconPropsType } from '../types'

const EyeOffIcon = ({ width = 25, height = 25 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.51221 5.3491L3.79221 4.0791L20.5122 20.7991L19.2422 22.0791L16.1622 18.9991C15.0122 19.3791 13.7922 19.5791 12.5122 19.5791C7.51221 19.5791 3.24221 16.4691 1.51221 12.0791C2.20221 10.3191 3.30221 8.7691 4.70221 7.5391L2.51221 5.3491ZM12.5122 9.0791C13.3079 9.0791 14.0709 9.39517 14.6335 9.95778C15.1961 10.5204 15.5122 11.2835 15.5122 12.0791C15.5127 12.4197 15.4552 12.7578 15.3422 13.0791L11.5122 9.2491C11.8335 9.13608 12.1716 9.0786 12.5122 9.0791ZM12.5122 4.5791C17.5122 4.5791 21.7822 7.6891 23.5122 12.0791C22.6961 14.1523 21.3092 15.9518 19.5122 17.2691L18.0922 15.8391C19.4751 14.8825 20.5904 13.5882 21.3322 12.0791C20.5238 10.429 19.2686 9.03887 17.7095 8.06661C16.1503 7.09434 14.3497 6.57898 12.5122 6.5791C11.4222 6.5791 10.3522 6.7591 9.35221 7.0791L7.81221 5.5491C9.25221 4.9291 10.8422 4.5791 12.5122 4.5791ZM3.69221 12.0791C4.50065 13.7292 5.75578 15.1193 7.31494 16.0916C8.8741 17.0639 10.6747 17.5792 12.5122 17.5791C13.2022 17.5791 13.8822 17.5091 14.5122 17.3691L12.2322 15.0791C11.5364 15.0045 10.887 14.694 10.3922 14.1991C9.89733 13.7043 9.58679 13.0549 9.51221 12.3591L6.11221 8.9491C5.12221 9.7991 4.29221 10.8591 3.69221 12.0791Z'
        fill='#A9A9A9'
      />
    </svg>
  )
}

export default EyeOffIcon
