import React from 'react'

// general imports
import { IconPropsType } from '../types'

const MailNotificationIcon = ({
  width = 48,
  height = 48,
  color = 'currentColor',
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 128 128'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_263_498)'>
        <path
          d='M85.3566 25.6C84.3935 27.6237 83.7419 29.7814 83.4238 32L32.0958 32.0064C27.4878 32.0576 25.7598 32.4352 24.0062 33.3824C22.5086 34.176 21.3822 35.3088 20.5822 36.8064C19.795 38.2784 19.3982 39.7183 19.2574 42.8416L60.7998 63.6159L88.5566 49.728C90.067 51.5008 91.8462 53.0303 93.8366 54.2592L62.2334 70.0544C61.5194 70.4118 60.6977 70.4894 59.9294 70.2719L59.3662 70.0544L19.2062 49.984V83.104C19.2574 87.712 19.635 89.4399 20.5822 91.1936C21.3758 92.6911 22.5086 93.8176 24.0062 94.6176C25.619 95.4816 27.2062 95.872 31.0014 95.9744L33.3054 96H88.2878L90.5918 95.9744C94.3998 95.872 95.9806 95.4816 97.5998 94.6176C99.091 93.824 100.224 92.6911 101.017 91.1936C101.881 89.5807 102.272 87.9936 102.374 84.1984L102.4 81.8943V57.3632C104.522 57.667 106.677 57.667 108.8 57.3632V81.8815C108.8 89.0111 108.057 91.6032 106.662 94.208C105.298 96.7847 103.191 98.8919 100.614 100.256C98.0094 101.651 95.4174 102.394 88.2878 102.394H33.3118C26.1758 102.394 23.5902 101.651 20.9854 100.256C18.4087 98.8919 16.3015 96.7847 14.9374 94.208C13.6382 91.776 12.9022 89.3568 12.8126 83.2512L12.7998 46.112C12.7998 38.976 13.5422 36.3904 14.9374 33.7856C16.3326 31.1807 18.3806 29.1328 20.9854 27.7376C23.4174 26.4384 25.8366 25.7024 31.9422 25.6128L85.3566 25.6ZM105.6 19.2C109.843 19.2 113.913 20.8857 116.914 23.8862C119.914 26.8868 121.6 30.9565 121.6 35.2C121.6 39.4434 119.914 43.5131 116.914 46.5137C113.913 49.5142 109.843 51.2 105.6 51.2C101.356 51.2 97.2867 49.5142 94.2861 46.5137C91.2855 43.5131 89.5998 39.4434 89.5998 35.2C89.5998 30.9565 91.2855 26.8868 94.2861 23.8862C97.2867 20.8857 101.356 19.2 105.6 19.2Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_263_498'>
          <rect width='128' height='128' rx='5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MailNotificationIcon
