import axios, { AxiosResponse, ResponseType } from 'axios'
import { TokenPrimeService } from '../../services/token/token.service'
// import { APP_CONSTANTS } from '../../repository/constants/constants'

const tokenServicePrime = new TokenPrimeService()
let backendUrl: string
export const InternalServerError = {
  message: 'Internal error occured during the request.',
  code: -500,
}

export const onFulfilledRequest = (response: AxiosResponse) => response

// eslint-disable-next-line
export const onRejectedRequest = (error: any) => Promise.reject(InternalServerError)
// eslint-disable-next-line
export const publicApiRequest = async (url?: string, responseType?: ResponseType) => {
  // let config={}
  // if (token) {
  //   config = {
  //     Accept: 'application/json',
  //   'Content-Type': contentType !== undefined ? contentType : 'application/json',
  //     Authorization: `Bearer ${token}`,
  //   }
  // } else {
  //   config = {
  //     Accept: 'application/json',
  //   }
  // }

  if (!backendUrl) {
    const response = await tokenServicePrime.getBackendUrl()
    backendUrl = response.backendUrl
  }
  return axios.create({
    baseURL: url ? url : backendUrl,
    responseType: responseType,
  })
}
;(await publicApiRequest()).interceptors.response.use(onFulfilledRequest, onRejectedRequest)
