import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { TransactionPropType } from '../../../types/molecules/transaction-table.molecule'
import { getAllTransactionsThunk } from '../thunks/transactions.thunk'
import { TransactionCard } from '../../../types/molecules/recent-transactions-component-organism.type'

export type getAllTransactionsState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  allTransactions: TransactionPropType[]
  recentTransaction: TransactionCard[]
}

export const initialState: getAllTransactionsState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  allTransactions: [] as TransactionPropType[],
  recentTransaction: [] as TransactionCard[],
}

const getAllTransactionsSlice = createSlice({
  name: 'getAllTransactionsSlice',
  initialState: initialState,
  reducers: {
    resetGetAllTransactionsState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTransactionsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getAllTransactionsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        // state.requestResponse.data = action.payload.description
        console.log(action.payload)
      })
      .addCase(getAllTransactionsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetGetAllTransactionsState } = getAllTransactionsSlice.actions
export default getAllTransactionsSlice.reducer
