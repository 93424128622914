import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { editUserProfileThunk } from '../thunks/user-profile.thunk'

export type EditUserProfileState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
}

export const initialState: EditUserProfileState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const editUserProfileSlice = createSlice({
  name: 'editUserProfileSlice',
  initialState: initialState,
  reducers: {
    resetEditUserProfileState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editUserProfileThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(editUserProfileThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        console.log('a payload of edit', action.payload)

        // state.requestResponse.data = action.payload.description
      })
      .addCase(editUserProfileThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetEditUserProfileState } = editUserProfileSlice.actions
export default editUserProfileSlice.reducer
