import React from 'react'

// general imports
import { IconPropsType } from '../types'

const UpwardArrowIcon = ({ width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='12' fill='white' />
      <path
        d='M8.66699 15.3332L15.3337 8.6665'
        stroke='#299D91'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.66699 8.6665H15.3337V15.3332'
        stroke='#299D91'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UpwardArrowIcon
