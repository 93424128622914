import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api/api.types'
import { RegistrationDataType } from '../../../../types/auth.types'
import {
  activateUserAccountThunk,
  createUserAccountAdminThunk,
  deleteUserOrgThunk,
  emailVerificationCodeAdminThunk,
  getConfirmationCodeAdminThunk,
  getUsersOrgThunk,
  resendActivationLinkThunk,
} from '../thunk/user.thunk'

export type RegistrationState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: RegistrationDataType[] | any
  isVerifiedAdmin: boolean
  isUserAccountCreate: boolean
}

export const initialState: RegistrationState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  users: [],
  isVerifiedAdmin: false,
  isUserAccountCreate: false,
}

const userRegistrationAdminSlice = createSlice({
  name: 'userRegistrationAdminSlice',
  initialState: initialState,
  reducers: {
    resetUserRegistrationAdminState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
      state.isVerifiedAdmin = false
      state.isUserAccountCreate = false
    },
    setUserAdmin: (state, action) => {
      state.users = { ...state.users, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUserAccountAdminThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(createUserAccountAdminThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.users = state.users ? [...state.users, action.payload] : [action.payload]
        state.isUserAccountCreate = true
      })
      .addCase(createUserAccountAdminThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getUsersOrgThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(getUsersOrgThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.users = action.payload
      })
      .addCase(getUsersOrgThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(deleteUserOrgThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(deleteUserOrgThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.users = action.payload
      })
      .addCase(deleteUserOrgThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getConfirmationCodeAdminThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getConfirmationCodeAdminThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.requestResponse.success = action.payload.success
      })
      .addCase(getConfirmationCodeAdminThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(emailVerificationCodeAdminThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(emailVerificationCodeAdminThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.requestResponse.success = action.payload.success
        state.isVerifiedAdmin = action.payload.success
      })
      .addCase(emailVerificationCodeAdminThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(activateUserAccountThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(activateUserAccountThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
      })
      .addCase(activateUserAccountThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.requestResponse.data = action.payload
      })
      .addCase(resendActivationLinkThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(resendActivationLinkThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('mein pay resend', action.payload)
      })
      .addCase(resendActivationLinkThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.requestResponse.data = action.payload
      })
  },
})

export const { resetUserRegistrationAdminState, setUserAdmin } = userRegistrationAdminSlice.actions
export default userRegistrationAdminSlice.reducer
