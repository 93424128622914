import { createSlice } from '@reduxjs/toolkit'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { Organization } from '../../../types/organisationInfo'
import { ServiceProvider } from '../../../types/settings.type'
import {
  addServiceProviderThunk,
  confirmUpdateOrgEmailThunk,
  confirmUpdateOrgPhoneNumberThunk,
  deleteProviderAccountThunk,
  editOrgSettingsThunk,
  getOrgProfileThunk,
  getServiceProvidersThunk,
  sendOtpThunk,
  updateEmailThunk,
  updatePhoneNumberThunk,
} from '../thunks/settings.thunk'

interface SettingsState {
  status: ApiRequestStatus
  addProviderStatus: ApiRequestStatus
  sendOtpStatus: ApiRequestStatus
  // eslint-disable-next-line
  serviceProviders: ServiceProvider[]
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  organizationInfos: Organization
}

const initialState: SettingsState = {
  status: ApiRequestStatus.IDLE,
  addProviderStatus: ApiRequestStatus.IDLE,
  sendOtpStatus: ApiRequestStatus.IDLE,
  serviceProviders: [] as ServiceProvider[],
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  organizationInfos: {
    organisationId: '',
    name: '',
    username: '',
    email: '',
    phoneNumber: '',
    status: '',
    category: '',
    location: '',
    website: '',
    profilePicture: '',
    coverPicture: '',
    createdAt: '',
    updatedAt: '',
  },
}

const SettingsSlice = createSlice({
  name: 'settingSlice',
  initialState: initialState,
  reducers: {
    resetOrgState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceProvidersThunk.pending, (state) => {
        state.status = ApiRequestStatus.PENDING
      })
      .addCase(getServiceProvidersThunk.fulfilled, (state, action) => {
        state.status = ApiRequestStatus.FULFILLED
        state.serviceProviders = action.payload as ServiceProvider[]
      })
      .addCase(getServiceProvidersThunk.rejected, (state) => {
        state.status = ApiRequestStatus.REJECTED
      })
      .addCase(addServiceProviderThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
      })
      .addCase(addServiceProviderThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('response data', action)
      })
      .addCase(addServiceProviderThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
        console.log('response eer', action)
      })
      .addCase(sendOtpThunk.pending, (state) => {
        state.sendOtpStatus = ApiRequestStatus.PENDING
      })
      .addCase(sendOtpThunk.fulfilled, (state, action) => {
        state.sendOtpStatus = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(sendOtpThunk.rejected, (state) => {
        state.sendOtpStatus = ApiRequestStatus.REJECTED
      })
      .addCase(editOrgSettingsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(editOrgSettingsThunk.fulfilled, (state, action) => {
        state.requestResponse.data = action.payload
        state.requestResponse.status = ApiRequestStatus.FULFILLED
      })
      .addCase(editOrgSettingsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(deleteProviderAccountThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(deleteProviderAccountThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
      })
      .addCase(deleteProviderAccountThunk.pending, (state) => {
        state.sendOtpStatus = ApiRequestStatus.PENDING
      })
      .addCase(getOrgProfileThunk.fulfilled, (state, action) => {
        state.status = ApiRequestStatus.FULFILLED
        state.organizationInfos = action.payload
        state.requestResponse.status = ApiRequestStatus.FULFILLED
      })
      .addCase(getOrgProfileThunk.rejected, (state) => {
        state.status = ApiRequestStatus.REJECTED
        state.requestResponse.status = ApiRequestStatus.REJECTED
      })
      .addCase(getOrgProfileThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
      })
      .addCase(updateEmailThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(updateEmailThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('edit email', action.payload)
      })
      .addCase(updateEmailThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(updatePhoneNumberThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(updatePhoneNumberThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('edit phone', action.payload)
      })
      .addCase(updatePhoneNumberThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(confirmUpdateOrgEmailThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(confirmUpdateOrgEmailThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('confirm email', action.payload)
      })
      .addCase(confirmUpdateOrgEmailThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(confirmUpdateOrgPhoneNumberThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(confirmUpdateOrgPhoneNumberThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload

        console.log('confirm phone number', action.payload)
      })
      .addCase(confirmUpdateOrgPhoneNumberThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetOrgState } = SettingsSlice.actions
export default SettingsSlice.reducer
