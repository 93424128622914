import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { getUpcomingBillThunk } from '../thunks/transactions.thunk'

import { BillCard } from '../../../types/molecules/upcoming-bill-component.type'

export type getUpcomingBillState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  upcomingBill: BillCard[]
}

export const initialState: getUpcomingBillState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  upcomingBill: [] as BillCard[],
}

const getUpcomingBillSlice = createSlice({
  name: 'getUpcomingBillSlice',
  initialState: initialState,
  reducers: {
    resetGetUpcomingBillState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUpcomingBillThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getUpcomingBillThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.upcomingBill = action.payload
        console.log(action.payload)
      })
      .addCase(getUpcomingBillThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetGetUpcomingBillState } = getUpcomingBillSlice.actions
export default getUpcomingBillSlice.reducer
