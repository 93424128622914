import { publicApiRequest } from '../../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../../lib/utils/addTokenReq'
import { reqBeneficiaryDataType } from '../../../types/initiator.types'

/**
 * Add the token to the request headers
 */

/**
 * Beneficiary service
 */
export class BeneficiaryService {
  /**
   * Get list of beneficiaries
   * @returns Promise<any>
   */
  public async getBeneficiaries(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/beneficiaries', addTokenToHeaders(token))
  }

  /**
   * Create a new beneficiary
   * @returns Promise<any>
   */
  public async createBeneficiary(data: reqBeneficiaryDataType) {
    return await (
      await publicApiRequest()
    ).post('/transaction/beneficiaries', data, addTokenToHeaders(data.token))
  }

  /**
   * Get all beneficiaries
   * @returns Promise<any>
   */
  public async getAllBeneficiaries(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/beneficiaries/all', addTokenToHeaders(token))
  }

  /**
   * Delete a beneficiary by ID
   * @returns Promise<any>
   */
  public async deleteBeneficiary(data: reqBeneficiaryDataType) {
    return await (
      await publicApiRequest()
    ).delete(`/transaction/beneficiaries/${data.beneficiaryId}`, addTokenToHeaders(data.token))
  }

  /**
   * Delete a beneficiary by IDs
   * @returns Promise<any>
   */
  public async deleteBeneficiaries(data: { checkedBeneficiaries: string[]; token: string }) {
    const beneficiaryIds = { beneficiaryIds: data.checkedBeneficiaries }
    return await (
      await publicApiRequest()
    ).delete('/transaction/beneficiaries', {
      data: beneficiaryIds,
      headers: addTokenToHeaders(data.token).headers,
    })
  }

  /**
   * Update a beneficiary by ID
   * @returns Promise<any>
   */
  public async updateBeneficiary(data: reqBeneficiaryDataType) {
    return await (
      await publicApiRequest()
    ).patch(`/transaction/beneficiaries/${data.id}`, data, addTokenToHeaders(data.token))
  }

  /**
   * Get beneficiaries by organization email
   * @returns Promise<any>
   */
  public async getBeneficiariesByOrgEmail(data: reqBeneficiaryDataType) {
    return await (
      await publicApiRequest()
    ).get(`/transaction/beneficiaries/${data.organizationEmail}`, addTokenToHeaders(data.token))
  }
}
