import React from 'react'
import { IconPropsType } from '../types'

const IncreaseIcon = ({ width = 10, height = 5 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66634 0L7.62051 0.954167L5.58717 2.9875L3.92051 1.32083L0.833008 4.4125L1.42051 5L3.92051 2.5L5.58717 4.16667L8.21217 1.54583L9.16634 2.5V0H6.66634Z'
        fill='#036E67'
      />
    </svg>
  )
}

export default IncreaseIcon
