import React from 'react'

// general imports
import { IconPropsType } from '../types'

const KeyIcon = ({ width = 48, height = 48, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 128 128'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M78.6875 22.875C74.5591 22.8741 70.4879 23.8402 66.8002 25.6959C63.1124 27.5517 59.9106 30.2454 57.4513 33.5614C54.9921 36.8773 53.3437 40.7232 52.6384 44.7909C51.9331 48.8586 52.1904 53.0349 53.3897 56.9853L22.875 87.5V105.125H40.5L71.0148 74.6102C74.6511 75.714 78.4826 76.0209 82.2483 75.5099C86.014 74.999 89.6252 73.6822 92.836 71.6494C96.0467 69.6165 98.7815 66.9154 100.854 63.7301C102.926 60.5447 104.288 56.95 104.845 53.191C105.403 49.4319 105.143 45.5969 104.084 41.9471C103.026 38.2974 101.193 34.9188 98.7101 32.0416C96.2276 29.1644 93.1539 26.8563 89.6986 25.2744C86.2434 23.6925 82.4877 22.8741 78.6875 22.875ZM78.6875 69.875C76.6651 69.8739 74.6538 69.575 72.7185 68.9879L69.3492 67.9656L66.8611 70.4537L57.5169 79.7979L53.4661 75.75L49.3125 79.9036L53.3633 83.9544L48.7044 88.6133L44.6536 84.5625L40.5 88.7161L44.5508 92.7669L38.0677 99.25H28.75V89.9322L57.5434 61.1389L60.0344 58.6508L59.0121 55.2815C57.7566 51.1425 57.8381 46.7131 59.2452 42.6231C60.6523 38.5332 63.3132 34.9911 66.8495 32.5007C70.3858 30.0103 74.6173 28.6983 78.9422 28.7516C83.267 28.8048 87.465 30.2204 90.9389 32.797C94.4129 35.3737 96.9859 38.9802 98.2919 43.1035C99.5979 47.2268 99.5705 51.657 98.2135 55.7638C96.8565 59.8706 94.239 63.445 90.7335 65.9784C87.2279 68.5118 83.0127 69.8754 78.6875 69.875Z'
        fill={color}
      />
      <path
        d='M81.625 52.25C84.8697 52.25 87.5 49.6197 87.5 46.375C87.5 43.1303 84.8697 40.5 81.625 40.5C78.3803 40.5 75.75 43.1303 75.75 46.375C75.75 49.6197 78.3803 52.25 81.625 52.25Z'
        fill={color}
      />
    </svg>
  )
}

export default KeyIcon
