// general imports
import { IconPropsType } from '../types'

const RightIcon = ({ width = 16, height = 16 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 12L10 8L6 4'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default RightIcon
