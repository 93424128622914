export const APP_CONSTANTS = {
  STORAGE_KEY: {
    CURRENT_USER: 'camsol-payment-gateway-regex-key-34763-0001',
    PREVIOUS_ROUTE: 'camsol-payment-gateway-regex-key-34765-0002',
    APPLICATION_LANGUAGE: 'camsol-payment-gateway-regex-key-34765-0003',
    USER_AUTHENTICATED: 'camsol-payment-gateway-regex-key-34765-0004',
    PHONE_CODES: 'camsol-payment-gateway-regex-key-34765-0005',
    ACCESS_TOKEN: 'camsol-payment-gateway-regex-key-34765-0006',
  },
  APP_PATHS: {
    ICONS_PAGE: '/icons',
    HOME_PAGE: '/',

    LOGIN: '/login',
    REGISTER: '/sign-up',
    VERIFY_PIN: '/verify=pin',
    FORGOT_PASS: '/forgot-password',

    DASHBOARD: '/dashboard',
    DASHBOARD_INDEX: '/',
    DASHBOARD_PROFILE: 'profile',
    DASHBOARD_SETTINGS: 'settings',
    DASHBOARD_USERS: 'users',
    DASHBOARD_BENEFICIARIES: 'beneficiaries',
    DASHBOARD_TRANSACTIONS: 'transactions',
    DASHBOARD_EXPENSES: 'expenses',
    DASHBOARD_BALANCES: 'balances',
    DASHBOARD_HISTORY: 'history',
    DASHBOARD_TARGET: 'target',
    DASHBOARD_STRIPE: '/dashboard/stripe',
    DASHBOARD_STRIPE_SUCCESS: '/dashboard/balances/success',

    SUPER_ADMIN: '/super-admin',
    SUPER_INDEX: '/',
    SUPER_SUBSCRIPTION: 'subscriptions',
    SUPER_API_DOC: 'api-docs',
    SUPER_SERVICES: 'services',
    SUPER_REPORTS: 'reports',
    SUPER_ORGANIZATIONS: 'organizations',
    SUPER_ORGANIZATIONS_REQUEST: 'organisation-request',
    SUPER_PROCESS_REFILLS: 'process-refills',
    SUPER_INDIVIDUALS: 'individuals',
    SUPER_SETTINGS: 'settings',

    ACTIVATE_ACCOUNT: 'activate-account',
  },
}

export const LANGUAGE_CONSTANTS = [
  {
    label: 'English',
    value: 'english',
  },
  {
    label: 'French',
    value: 'french',
  },
]

export const CITY_CONSTANTS = [
  {
    label: 'Yaounde',
    value: 'yaounde',
  },
  {
    label: 'Douala',
    value: 'douala',
  },
  {
    label: 'Buea',
    value: 'buea',
  },
  {
    label: 'Garoua',
    value: 'garoua',
  },
  {
    label: 'Bafoussam',
    value: 'bafoussam',
  },
  {
    label: 'Bertoua',
    value: 'bertoua',
  },
  {
    label: 'Ngaoundere',
    value: 'ngaoundere',
  },
  {
    label: 'Ebolowa',
    value: 'ebolowa',
  },
  {
    label: 'Bamenda',
    value: 'bamenda',
  },
  {
    label: 'Maroua',
    value: 'maroua',
  },
]

export const ROLES = [
  { label: 'Validator', value: 'validator' },
  { label: 'Initiator', value: 'initiator' },
]

export const COUNTRY_CONSTANTS = [
  {
    label: 'Cameroon',
    value: 'cameroon',
  },
  {
    label: 'Nigeria',
    value: 'nigeria',
  },
]

export const ACCOUNT_CONSTANTS = [
  {
    label: 'MTN MOBILE MONEY',
    value: 'MOMO',
  },
  // {
  //   label: 'ORANGE MONEY',
  //   value: 'OM',
  // },
]

export const EMAIL_CONSTANTS = [
  {
    label: 'johndoe@gmail.com',
    value: 'johndoe@gmail.com',
  },
  {
    label: 'cesarndjoko72@gmail.com',
    value: 'cesarndjoko72@gmail.com',
  },
]
export const OPERATORS_CONSTANTS = [
  {
    label: 'MTN MOBILE MONEY',
    value: 'MOMO',
  },
  {
    label: 'ORANGE MOBILE MONEY',
    value: 'OM',
  },
]

export const MONTH_RANGE_CONSTANTS = [
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
  {
    label: '01 May ~ 31 May',
    value: '01 May ~ 31 May',
  },
]

export const MONTH_YEAR_CONSTANTS = [
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
  {
    label: 'Mar 2022',
    value: 'Mar 2022',
  },
]
export const POSITIONS_CONSTANTS = [
  {
    label: 'Team Lead',
    value: 'Team Lead',
  },
]
