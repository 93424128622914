import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import {
  emailVerificationCodeThunk,
  emailVerificationCodeUserThunk,
  getEmailConfirmationCodeThunk,
  resendEmailConfirmationCode,
} from '../thunks/auth.thunk'

export type EmailConfirmationState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  isVerified?: boolean
}

export const initialState: EmailConfirmationState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
    success: false,
  },
  isVerified: false,
}

const emailConfirmationSlice = createSlice({
  name: 'emailConfirmationSlice',
  initialState: initialState,
  reducers: {
    resetAuthConfirmationState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmailConfirmationCodeThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getEmailConfirmationCodeThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.requestResponse.success = action.payload.success
      })
      .addCase(getEmailConfirmationCodeThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(emailVerificationCodeThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(emailVerificationCodeThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.isVerified = action.payload.success
        state.requestResponse.success = action.payload.success
      })
      .addCase(emailVerificationCodeThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(emailVerificationCodeUserThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(emailVerificationCodeUserThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.isVerified = action.payload.success
        state.requestResponse.success = action.payload.success
      })
      .addCase(emailVerificationCodeUserThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(resendEmailConfirmationCode.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(resendEmailConfirmationCode.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.requestResponse.success = action.payload.success
      })
      .addCase(resendEmailConfirmationCode.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetAuthConfirmationState } = emailConfirmationSlice.actions
export default emailConfirmationSlice.reducer
