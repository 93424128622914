import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { createSlice } from '@reduxjs/toolkit'
import { geAllBeneficiariesThunk } from '../thunks/transactions.thunk'
// import { TransactionCard } from '../../../types/molecules/recent-transactions-component-organism.type'
import { BeneficiariesType } from '../../../types/beneficiaries.types'

export type geAllBeneficiariesState = {
  requestResponse: ApiRequestDataType
  allBeneficiaries: BeneficiariesType[]
}

export const initialState: geAllBeneficiariesState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  allBeneficiaries: [] as BeneficiariesType[],
}

const geAllBeneficiariesSlice = createSlice({
  name: 'geAllBeneficiariesSlice',
  initialState: initialState,
  reducers: {
    resetgeAllBeneficiariesState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(geAllBeneficiariesThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(geAllBeneficiariesThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.allBeneficiaries = action.payload
        console.log(action.payload)
      })
      .addCase(geAllBeneficiariesThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
      })
  },
})

export const { resetgeAllBeneficiariesState } = geAllBeneficiariesSlice.actions
export default geAllBeneficiariesSlice.reducer
