import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../../../lib/utils/addTokenReq'
import { Data } from '../../../../types/expenses.type'
export class ExpensesServiceAdmin {
  public async getExpenses(token: string) {
    const response = await (
      await publicApiRequest()
    ).get<Data>('/transaction/expenses', addTokenToHeaders(token))
    return response.data
  }
}
