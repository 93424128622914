import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { getExceptionPayload } from '../../../../lib/utils/get-exception-payload'
import {
  TransactionService,
  deleteFileTransaction,
  otpCode,
  processFileTransaction,
  uploadFileBulk,
} from '../../../../services/api/initiator/transaction.service'
import { ApiRequestErrorType } from '../../../../types/api/api.types'

const transactionService = new TransactionService()

export const getTransactionThunk = createAsyncThunk(
  '/Transaction',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getTransaction(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getTransactionHistoryThunk = createAsyncThunk(
  '/transaction/get-transaction-history',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getTransactionHistory(token)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const uploadTransactionFileThunk = createAsyncThunk(
  '/transaction/upload-file',
  async (
    data: uploadFileBulk & { setUploadProgress: (progress: number) => void },
    { rejectWithValue },
  ) => {
    const { setUploadProgress, ...restData } = data
    try {
      const response = await transactionService.uploadTransactionFile(restData, setUploadProgress)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getUploadedTransactionFilesThunk = createAsyncThunk(
  '/transaction/uploaded-files',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getUploadedTransactionFiles(token)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const sendInitiatorOtpThunk = createAsyncThunk(
  '/transaction/request-otp',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.sendInitiatorOtp(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const verifyInitiatorOtpThunk = createAsyncThunk(
  '/transaction/verify-otp',
  async (data: otpCode, { rejectWithValue }) => {
    try {
      const response = await transactionService.verifyInitiatorOtp(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const processFileTransactionThunk = createAsyncThunk(
  '/transaction/process-file',
  async (data: processFileTransaction, { rejectWithValue }) => {
    try {
      const response = await transactionService.processFileTransaction(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteTransactionFileThunk = createAsyncThunk(
  '/transaction/delete-file/id',
  async (data: deleteFileTransaction, { rejectWithValue }) => {
    try {
      const response = await transactionService.deleteTransactionFile(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
