import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import {
  forgotPasswordThunk,
  resetPasswordThunk,
  verifyResetOtpCodeThunk,
} from '../thunks/auth.thunk'

export type ResetPasswordState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  isVerified: boolean
}

export const initialState: ResetPasswordState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  isVerified: false,
}

const resetPasswordSlice = createSlice({
  name: 'resetPasswordSlice',
  initialState: initialState,
  reducers: {
    resetPasswordState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(verifyResetOtpCodeThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(verifyResetOtpCodeThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.isVerified = !!action.payload.otpCode
      })
      .addCase(verifyResetOtpCodeThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(resetPasswordThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
      })
      .addCase(resetPasswordThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetPasswordState } = resetPasswordSlice.actions
export default resetPasswordSlice.reducer
