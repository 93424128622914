import React from 'react'

// general imports
import { IconPropsType } from '../types'

const CurrencyExchangeIcon = ({ width = 16, height = 16 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.59366 7.39996C7.40699 7.00663 6.83366 6.75996 6.83366 6.13329C6.83366 5.45329 7.57366 5.20663 8.04033 5.20663C8.91366 5.20663 9.23366 5.86663 9.30699 6.09996L10.3603 5.65329C10.2603 5.35329 9.81366 4.37329 8.66699 4.15996V3.33329H7.33366V4.17329C5.68033 4.54663 5.67366 6.07996 5.67366 6.14663C5.67366 7.65996 7.17366 8.08663 7.90699 8.35329C8.96033 8.72663 9.42699 9.06663 9.42699 9.70663C9.42699 10.46 8.72699 10.78 8.10699 10.78C6.89366 10.78 6.54699 9.53329 6.50699 9.38663L5.40033 9.83329C5.82033 11.2933 6.92033 11.6866 7.33366 11.8066V12.6666H8.66699V11.84C8.93366 11.78 10.6003 11.4466 10.6003 9.69329C10.6003 8.76663 10.1937 7.95329 8.59366 7.39996ZM2.00033 14H0.666992V9.99996H4.66699V11.3333H3.01366C4.08699 12.94 5.92033 14 8.00033 14C11.3137 14 14.0003 11.3133 14.0003 7.99996H15.3337C15.3337 12.0533 12.0537 15.3333 8.00033 15.3333C5.52033 15.3333 3.32699 14.1 2.00033 12.22V14ZM0.666992 7.99996C0.666992 3.94663 3.94699 0.666626 8.00033 0.666626C10.4803 0.666626 12.6737 1.89996 14.0003 3.77996V1.99996H15.3337V5.99996H11.3337V4.66663H12.987C11.9137 3.05996 10.0803 1.99996 8.00033 1.99996C4.68699 1.99996 2.00033 4.68663 2.00033 7.99996H0.666992Z'
        fill='#336175'
      />
    </svg>
  )
}

export default CurrencyExchangeIcon
