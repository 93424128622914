import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: require('./locales/en.json'),
  },
  fr: {
    translation: require('./locales/fr.json'),
  },
  de: {
    translation: require('./locales/de.json'),
  },
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'fr', // Default language if translation is unavailable
    interpolation: {
      escapeValue: false, // Allows using HTML tags in translations
    },
  })

export default i18n
