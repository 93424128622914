import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../lib/utils/addTokenReq'
import {
  ServiceProvider,
  UpdateProfileType,
  changePasswordType,
  otpConfirmationType,
} from '../../types/settings.type'
import { confirmUpdateUserEmailTypes, confirmUpdateUserPhoneNumberTypes } from './profile.service'

export interface uploadOrgInfoData {
  jsonData: UpdateProfileType
  imageData: string
  token: string
}

export class SettingsService {
  public async getServiceProviders(token: string) {
    const response = await (
      await publicApiRequest()
    ).get<ServiceProvider[]>('/transaction/get-service-providers', addTokenToHeaders(token))
    return response.data
  }

  public async addServiceProvider(data: ServiceProvider, token: string) {
    const response = await (
      await publicApiRequest()
    ).post('/transaction/add-service-provider', data, addTokenToHeaders(token))
    return response.data
  }
  public async sendOtp(data: otpConfirmationType, token: string) {
    return await (
      await publicApiRequest()
    ).patch('/transaction/confirm-service-provider', data, addTokenToHeaders(token))
  }
  public async changePassword(data: changePasswordType, token: string) {
    return await (
      await publicApiRequest()
    ).put('/auth/change-password', data, addTokenToHeaders(token))
  }

  /**
   * Update a beneficiary by ID
   * @returns Promise<any>
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async updateBeneficiary(data: any) {
    return await (
      await publicApiRequest()
    ).patch(`/profile/edit-organization'/${data.id}`, data, addTokenToHeaders(data.token))
  }

  public async editOrgSettings(data: uploadOrgInfoData) {
    const formData = new FormData()

    formData.append('jsonData', JSON.stringify(data.jsonData))
    formData.append('image', data.imageData)

    return await (
      await publicApiRequest()
    ).patch('/profile/edit-organisation', formData, {
      headers: {
        Authorization: `Bearer ${data.token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  /**
   * Delete a provider account by ID
   * @returns Promise<any>
   */
  public async deleteProviderAccount(providerId: string, token: string) {
    return await (
      await publicApiRequest()
    ).delete(`/transaction/delete-service-provider/${providerId}`, addTokenToHeaders(token))
  }

  public async getOrgProfile(token: string) {
    const response = await (
      await publicApiRequest()
    ).get('/profile/get-organisation-profile', addTokenToHeaders(token))
    return response.data
  }

  public async updateOrgEmail(email: string, token: string) {
    return await (
      await publicApiRequest()
    ).patch('/auth/organisations/update-email', { email: email }, addTokenToHeaders(token))
  }
  public async confirmEditEmail(data: confirmUpdateUserEmailTypes) {
    return await (
      await publicApiRequest()
    ).patch(
      '/auth/organisations/confirm-email-update',
      { email: data.email, otpCode: data.otpCode },
      addTokenToHeaders(data.token),
    )
  }

  public async updateOrgPhoneNumber(phoneNumber: string, token: string) {
    return await (
      await publicApiRequest()
    ).patch(
      '/auth/organisations/update-phone-number',
      { phoneNumber: phoneNumber },
      addTokenToHeaders(token),
    )
  }

  public async confirmEditPhoneNumber(data: confirmUpdateUserPhoneNumberTypes) {
    return await (
      await publicApiRequest()
    ).patch(
      '/auth/organisations/confirm-phone-number-update',
      { phoneNumber: data.phoneNumber, otpCode: data.otpCode },
      addTokenToHeaders(data.token),
    )
  }
}
