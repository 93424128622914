import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { confirmUpdateUserPhoneNumberThunk } from '../thunks/user-profile.thunk'

export type confirmUpdateUserPhoneNumberState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
}

export const initialState: confirmUpdateUserPhoneNumberState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const confirmUpdateUserPhoneNumberSlice = createSlice({
  name: 'confirmUpdateUserPhoneNumberSlice',
  initialState: initialState,
  reducers: {
    resetconfirmUpdateUserPhoneNumberState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmUpdateUserPhoneNumberThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(confirmUpdateUserPhoneNumberThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        console.log('a payload', action.payload)

        // state.requestResponse.data = action.payload.description
      })
      .addCase(confirmUpdateUserPhoneNumberThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetconfirmUpdateUserPhoneNumberState } = confirmUpdateUserPhoneNumberSlice.actions
export default confirmUpdateUserPhoneNumberSlice.reducer
