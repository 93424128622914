import { createSlice } from '@reduxjs/toolkit'

import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import {
  // OrganizationOwnerResponseTypes,
  OrganizationResponseTypes,
  OrganizationTransactionsResponseTypes,
  OrganizationUsersResponseTypes,
  ServiceProviderResponseTypes,
  WalletResponseTypes,
  unapprovedOrganisationsType,
  unfilteredOrganisation,
} from '../../../types/organisation.type'
import {
  approvedOrganisationThunk,
  deleteOrganisationServiceProvidersThunk,
  deleteOrganisationUserThunk,
  deleteUnapprovedOrganisationsThunk,
  editOrganisationCoverPhotoThunk,
  getAllOrganisationsThunk,
  getOrganisationNumberOfUsersThunk,
  getOrganisationOwnerThunk,
  getOrganisationServiceProvidersThunk,
  getOrganisationTransactionsThunk,
  getOrganisationUsersThunk,
  getOrganisationWalletDetailsThunk,
  getOrganisationsThunk,
  getUnapprovedOrganisationsThunk,
  singleOrganisationDetailsThunk,
} from '../thunk/organisation.thunk'

export type GetAllOrganisationsState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType
  organizations: OrganizationResponseTypes[]
  singleOrganisation?: OrganizationResponseTypes
  numberOfUsers: number
  organisationUsers: OrganizationUsersResponseTypes[]
  organisationOwner: OrganizationUsersResponseTypes
  organisationTransactions: OrganizationTransactionsResponseTypes[]
  organisationWallet: WalletResponseTypes
  serviceProviders: ServiceProviderResponseTypes[]
  unapprovedOrganisations: unapprovedOrganisationsType[]
  unfilteredOrganisations: unfilteredOrganisation[]
}

export const initialState: GetAllOrganisationsState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  organizations: [] as OrganizationResponseTypes[],
  singleOrganisation: {} as OrganizationResponseTypes,
  numberOfUsers: 0,
  organisationUsers: [] as OrganizationUsersResponseTypes[],
  organisationOwner: {} as OrganizationUsersResponseTypes,
  organisationTransactions: [] as OrganizationTransactionsResponseTypes[],
  organisationWallet: {} as WalletResponseTypes,
  serviceProviders: [] as ServiceProviderResponseTypes[],
  unapprovedOrganisations: [] as unapprovedOrganisationsType[],
  unfilteredOrganisations: [] as unfilteredOrganisation[],
  // organisationServiceProviders:
}

const getAllOrganisationsSlice = createSlice({
  name: 'getAllOrganisationsSlice',
  initialState: initialState,
  reducers: {
    resetGetAllOrganisationsState: (state) => {
      // ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
      state.requestResponse.status = ApiRequestStatus.IDLE
      // (state.organizations = {} as OrganizationResponseTypes)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrganisationsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getAllOrganisationsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.organizations = action.payload
      })
      .addCase(getAllOrganisationsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      // getUnapprovedOrganisationsThunk---------------------------------
      .addCase(getUnapprovedOrganisationsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getUnapprovedOrganisationsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.unapprovedOrganisations = action.payload
      })
      .addCase(getUnapprovedOrganisationsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      // ==========================================================
      .addCase(singleOrganisationDetailsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(singleOrganisationDetailsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.singleOrganisation = action.payload
      })
      .addCase(singleOrganisationDetailsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getOrganisationUsersThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getOrganisationUsersThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.organisationUsers = action.payload
      })
      .addCase(getOrganisationUsersThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getOrganisationNumberOfUsersThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getOrganisationNumberOfUsersThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.numberOfUsers = action.payload
      })
      .addCase(getOrganisationNumberOfUsersThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getOrganisationOwnerThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getOrganisationOwnerThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.organisationOwner = action.payload
      })
      .addCase(getOrganisationOwnerThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getOrganisationTransactionsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getOrganisationTransactionsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.organisationTransactions = action.payload
      })
      .addCase(getOrganisationTransactionsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getOrganisationWalletDetailsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })

      .addCase(getOrganisationWalletDetailsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.organisationWallet = action.payload
      })
      .addCase(getOrganisationWalletDetailsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(deleteOrganisationServiceProvidersThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(deleteOrganisationServiceProvidersThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.serviceProviders = action.payload
        // console.log(action.payload)
      })
      .addCase(deleteOrganisationServiceProvidersThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })

      // deleteUnapprovedOrganisationsThunk----------------------------------------------
      .addCase(deleteUnapprovedOrganisationsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(deleteUnapprovedOrganisationsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(deleteUnapprovedOrganisationsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      // =======================================

      // -approvedOrganisationThunk---------------------------------
      .addCase(approvedOrganisationThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(approvedOrganisationThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(approvedOrganisationThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })

      // ---------------------
      .addCase(getOrganisationServiceProvidersThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getOrganisationServiceProvidersThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.serviceProviders = action.payload

        // state.organizations = action.payload
      })
      .addCase(getOrganisationServiceProvidersThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(deleteOrganisationUserThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(deleteOrganisationUserThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.organisationUsers = action.payload

        // state.organizations = action.payload
      })
      .addCase(deleteOrganisationUserThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(editOrganisationCoverPhotoThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(editOrganisationCoverPhotoThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        console.log(action.payload)

        // state.organizations = action.payload
      })
      .addCase(editOrganisationCoverPhotoThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      // unfiltered organisation
      .addCase(getOrganisationsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getOrganisationsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.unfilteredOrganisations = action.payload.content
      })
      .addCase(getOrganisationsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetGetAllOrganisationsState } = getAllOrganisationsSlice.actions
export default getAllOrganisationsSlice.reducer
