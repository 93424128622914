import React from 'react'

// general imports
import { IconPropsType } from '../types'

const DownloadAltIcon = ({ width = 12, height = 12 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 5.00004H8.66667C8.26667 5.00004 8 5.26671 8 5.66671C8 6.06671 8.26667 6.33337 8.66667 6.33337H10C10.4 6.33337 10.6667 6.60004 10.6667 7.00004V11.6667C10.6667 12.0667 10.4 12.3334 10 12.3334H2C1.6 12.3334 1.33333 12.0667 1.33333 11.6667V7.00004C1.33333 6.60004 1.6 6.33337 2 6.33337H3.33333C3.73333 6.33337 4 6.06671 4 5.66671C4 5.26671 3.73333 5.00004 3.33333 5.00004H2C0.866667 5.00004 0 5.86671 0 7.00004V11.6667C0 12.8 0.866667 13.6667 2 13.6667H10C11.1333 13.6667 12 12.8 12 11.6667V7.00004C12 5.86671 11.1333 5.00004 10 5.00004ZM3.53333 8.80004L5.53333 10.8C5.66667 10.9334 5.8 11 6 11C6.2 11 6.33333 10.9334 6.46667 10.8L8.46667 8.80004C8.73333 8.53337 8.73333 8.13337 8.46667 7.86671C8.2 7.60004 7.8 7.60004 7.53333 7.86671L6.66667 8.73337V1.00004C6.66667 0.600041 6.4 0.333374 6 0.333374C5.6 0.333374 5.33333 0.600041 5.33333 1.00004V8.73337L4.46667 7.86671C4.2 7.60004 3.8 7.60004 3.53333 7.86671C3.26667 8.13337 3.26667 8.53337 3.53333 8.80004Z'
        fill='#336175'
      />
    </svg>
  )
}

export default DownloadAltIcon
