import React from 'react'

// general imports
import { IconPropsType } from '../types'

const RightArrowIcon = ({ width = 6, height = 10, color = '#191919' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 9L5 5L1 1' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default RightArrowIcon
