import { DashboardType } from './repository/data/dashboard-links'
import { LocalStorageService } from './services/storage/local-storage.service'

const userDefaultDashboard = () => {
  const user = LocalStorageService.getCurrentUser()
  const roles = user?.roles

  if (roles) {
    if (user.superAdmin) return DashboardType.SUPER_ADMIN
    if (roles.includes('ADMIN') && !user.superAdmin) return DashboardType.ADMIN
    if (roles.includes('INITIATOR')) return DashboardType.INITIATOR
    if (roles.includes('VALIDATOR')) return DashboardType.VALIDATOR
  }
}

export const DashboardDefaultType = userDefaultDashboard()
