import React from 'react'
// general imports
import { IconPropsType } from '../types'
const PersonIcon = ({ width = 48, height = 48, color = '#E2E2E2' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='24' cy='24' r='24' fill={color} />
      <path
        d='M24.5 17.6375C25.805 17.6375 26.8625 18.695 26.8625 20C26.8625 21.305 25.805 22.3625 24.5 22.3625C23.195 22.3625 22.1375 21.305 22.1375 20C22.1375 18.695 23.195 17.6375 24.5 17.6375ZM24.5 27.7625C27.8413 27.7625 31.3625 29.405 31.3625 30.125V31.3625H17.6375V30.125C17.6375 29.405 21.1588 27.7625 24.5 27.7625ZM24.5 15.5C22.0138 15.5 20 17.5138 20 20C20 22.4862 22.0138 24.5 24.5 24.5C26.9862 24.5 29 22.4862 29 20C29 17.5138 26.9862 15.5 24.5 15.5ZM24.5 25.625C21.4963 25.625 15.5 27.1325 15.5 30.125V33.5H33.5V30.125C33.5 27.1325 27.5037 25.625 24.5 25.625Z'
        fill='#383838'
      />
    </svg>
  )
}

export default PersonIcon
