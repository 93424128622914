import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { verifyOtpAfterLoginThunk } from '../thunks/auth.thunk'

export type VerifyOtpState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
}

export const initialState: VerifyOtpState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const verifyOtpSlice = createSlice({
  name: 'verifyOtpSlice',
  initialState: initialState,
  reducers: {
    resetVerifyOtpState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtpAfterLoginThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(verifyOtpAfterLoginThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(verifyOtpAfterLoginThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetVerifyOtpState } = verifyOtpSlice.actions
export default verifyOtpSlice.reducer
