// general imports
import { IconPropsType } from '../types'

const AdsClickIcon = ({ width = 24, height = 24, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.4551 17.74C8.27512 17.59 5.74512 14.97 5.74512 11.75C5.74512 8.44 8.43512 5.75 11.7451 5.75C14.9651 5.75 17.5851 8.28 17.7351 11.46L15.6351 10.83C15.2251 9.06 13.6351 7.75 11.7451 7.75C9.53512 7.75 7.74512 9.54 7.74512 11.75C7.74512 13.64 9.05512 15.23 10.8251 15.64L11.4551 17.74ZM21.7451 11.75C21.7451 12.05 21.7351 12.35 21.7051 12.65L19.7351 12.06C19.7451 11.96 19.7451 11.85 19.7451 11.75C19.7451 7.33 16.1651 3.75 11.7451 3.75C7.32512 3.75 3.74512 7.33 3.74512 11.75C3.74512 16.17 7.32512 19.75 11.7451 19.75C11.8451 19.75 11.9551 19.75 12.0551 19.74L12.6451 21.71C12.3451 21.74 12.0451 21.75 11.7451 21.75C6.22512 21.75 1.74512 17.27 1.74512 11.75C1.74512 6.23 6.22512 1.75 11.7451 1.75C17.2651 1.75 21.7451 6.23 21.7451 11.75ZM17.9751 16.01L21.7451 14.75L11.7451 11.75L14.7451 21.75L16.0051 17.98L20.2751 22.25L22.2551 20.27L17.9751 16.01Z'
        fill={color}
      />
    </svg>
  )
}

export default AdsClickIcon
