import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../lib/utils/addTokenReq'

export class NotificationService {
  /**
   * Get Notifications
   * @returns Promise<any>
   */

  public async getNotifications(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/notifications', addTokenToHeaders(token))
  }

  public async readNotification(notificationId: string, token: string) {
    return await (
      await publicApiRequest()
    ).patch(`/transaction/read-notification/${notificationId}`, null, addTokenToHeaders(token))
  }
  public async deleteNotification(notificationId: string, token: string) {
    return await (
      await publicApiRequest()
    ).delete(`/transaction/delete-notification/${notificationId}`, addTokenToHeaders(token))
  }
}
