import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import { ApiRequestErrorType } from '../../../types/api/api.types'
import {
  ProfileService,
  confirmUpdateUserEmailTypes,
  confirmUpdateUserPhoneNumberTypes,
  updateUserPhoneNumberTypes,
  updateUseremailTypes,
  uploadUserProfileInfoData,
  userProfileType,
} from '../../../services/api/profile.service'

// import { UserProfileInfoType } from '../../../pages/dashboard/modules/profile/DashboardProfile.page'

const profileService = new ProfileService()

export const getUserProfileInfoThunk = createAsyncThunk(
  '/profile/user-profile-info',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await profileService.getUserProfileInfo(token)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const addUserProfileThunk = createAsyncThunk(
  '/profile/register/',
  async (data: userProfileType, { rejectWithValue }) => {
    try {
      const response = await profileService.addUserProfile(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getUserProfilePictureThunk = createAsyncThunk(
  '/profile/user-profile-pic/',
  async (data: userProfileType, { rejectWithValue }) => {
    try {
      const response = await profileService.getUserProfilePicture(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const editUserProfileThunk = createAsyncThunk(
  '/profile/edit',
  async (data: uploadUserProfileInfoData, { rejectWithValue }) => {
    try {
      const response = await profileService.editUserProfileInfo(data)
      console.log('edit request', response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteUserProfilePictureThunk = createAsyncThunk(
  'delete/profile/user-profile-pic/',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await profileService.DeleteUserProfilePicture(token)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const updateUserEmailThunk = createAsyncThunk(
  '/auth/users/update-email',
  async (data: updateUseremailTypes, { rejectWithValue }) => {
    try {
      const response = await profileService.updateUserEmail(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const confirmUpdateUserEmailThunk = createAsyncThunk(
  '/auth/users/confirm-email-update',
  async (data: confirmUpdateUserEmailTypes, { rejectWithValue }) => {
    try {
      const response = await profileService.confirmUpdateUserEmail(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const updateUserPhoneNumberThunk = createAsyncThunk(
  '/auth/users/update-phone-number',
  async (data: updateUserPhoneNumberTypes, { rejectWithValue }) => {
    try {
      const response = await profileService.updateUserPhoneNumber(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const confirmUpdateUserPhoneNumberThunk = createAsyncThunk(
  '/auth/users/confirm-phone-number-update',
  async (data: confirmUpdateUserPhoneNumberTypes, { rejectWithValue }) => {
    try {
      const response = await profileService.confirmUpdateUserPhoneNumber(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
