import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
// app router
import AppRouter from './router/constructor/Router.constructor'

// general imports
import { RootState } from './app/store'
import { getPhoneNumbersThunk } from './features/phone-number/thunks/phone-number.thunk'
import { useAppDispatch, useAppSelector } from './lib/hooks'

const App = () => {
  const dispatch = useAppDispatch()
  const { requestResponse } = useAppSelector((state: RootState) => state.phoneNumber)

  useEffect(() => {
    if (requestResponse.data !== undefined && requestResponse.data !== null) {
      return
    } else {
      dispatch(getPhoneNumbersThunk())
    }
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <div className='app'>
        <AppRouter />
      </div>
    </I18nextProvider>
  )
}

export default App
