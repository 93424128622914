import React from 'react'

// general imports
import { IconPropsType } from '../types'

const CalendarIcon = ({
  width = 25,
  height = 26,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 105 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke={`${stroke}`}
      {...props}
    >
      <path
        d='M60.6817 38.8239C60.7419 38.9651 60.815 39.1006 60.9001 39.2284C60.9863 39.3549 61.0837 39.4732 61.1913 39.582C61.3025 39.6877 61.4214 39.7851 61.547 39.8732C61.6763 39.9564 61.8119 40.0294 61.9526 40.0916C62.0954 40.1461 62.2417 40.1913 62.3904 40.2268C62.5415 40.2557 62.6952 40.2696 62.849 40.2684C63.0026 40.2705 63.156 40.2566 63.3066 40.2268C63.4562 40.1944 63.6027 40.1492 63.7445 40.0916C63.8865 40.0316 64.0226 39.9585 64.1511 39.8732C64.2805 39.7907 64.3996 39.6929 64.5058 39.582C64.6163 39.4759 64.714 39.3572 64.7969 39.2284C64.882 39.1006 64.9551 38.9651 65.0153 38.8239C65.0766 38.6837 65.1223 38.5372 65.1516 38.3871C65.1814 38.2364 65.1953 38.083 65.1932 37.9295C65.1912 37.3095 64.9441 36.7154 64.5058 36.2769C64.3971 36.1687 64.2784 36.0712 64.1511 35.9857C64.0226 35.9005 63.8865 35.8273 63.7445 35.7673C63.6027 35.7098 63.4562 35.6645 63.3066 35.6321C62.5386 35.4901 61.7495 35.7306 61.1913 36.2769C60.7536 36.7158 60.507 37.3097 60.5049 37.9295C60.5057 38.0834 60.5231 38.2369 60.5569 38.3871C60.5817 38.5369 60.6236 38.6835 60.6817 38.8239Z'
        fill={color}
      />
      <path
        d='M34.9773 35.6301C34.8286 35.6656 34.6823 35.7107 34.5395 35.7652C34.402 35.8278 34.2695 35.9008 34.1432 35.9836C34.0128 36.0688 33.8909 36.1663 33.7792 36.2748C33.6737 36.3858 33.5761 36.5039 33.487 36.6284C33.4018 36.7545 33.332 36.8903 33.279 37.033C33.2207 37.1742 33.1755 37.3204 33.1438 37.4698C33.0745 37.7709 33.0745 38.0839 33.1438 38.385C33.1742 38.5347 33.2195 38.6811 33.279 38.8218C33.332 38.9645 33.4018 39.1003 33.487 39.2264C33.5761 39.3509 33.6737 39.469 33.7792 39.58C33.8885 39.6913 34.0107 39.789 34.1432 39.8712C34.2695 39.954 34.402 40.027 34.5395 40.0896C34.6823 40.1441 34.8286 40.1892 34.9773 40.2248C35.1316 40.254 35.2883 40.268 35.4453 40.2664C35.5961 40.268 35.7467 40.2541 35.8946 40.2248C36.0441 40.1921 36.1905 40.1468 36.3324 40.0896C36.4739 40.0291 36.6097 39.956 36.738 39.8712C36.9925 39.6995 37.2118 39.4806 37.3839 39.2264C37.4693 39.0985 37.5427 38.9631 37.6033 38.8218C37.6628 38.6811 37.7081 38.5347 37.7385 38.385C37.7683 38.2343 37.7822 38.081 37.7801 37.9274C37.7777 37.3073 37.5302 36.7132 37.0916 36.2748C36.5344 35.7276 35.7451 35.4869 34.9773 35.6301Z'
        fill={color}
      />
      <path
        d='M51.3041 37.033C51.2516 36.8901 51.1818 36.7543 51.0961 36.6285C50.5663 35.8431 49.6187 35.4501 48.6886 35.6301C48.539 35.6626 48.3926 35.7078 48.2507 35.7653C48.1091 35.8255 47.9734 35.8986 47.8451 35.9837C47.2614 36.3711 46.8828 37.0004 46.814 37.6976C46.7452 38.3948 46.9936 39.086 47.4905 39.58C47.5964 39.6911 47.7155 39.7889 47.8451 39.8712C47.9734 39.9562 48.1091 40.0293 48.2507 40.0896C48.3926 40.147 48.539 40.1922 48.6886 40.2248C48.8396 40.2545 48.9933 40.2684 49.1472 40.2664C49.7682 40.2641 50.3633 40.0176 50.8039 39.58C50.9124 39.4717 51.0102 39.3533 51.0961 39.2264C51.1818 39.1005 51.2516 38.9647 51.3041 38.8218C51.3629 38.6807 51.4085 38.5345 51.4404 38.385C51.5083 38.0837 51.5083 37.7711 51.4404 37.4698C51.4088 37.3202 51.3632 37.174 51.3041 37.033Z'
        fill={color}
      />
      <path
        d='M20.0772 46.056C19.637 46.4979 19.3898 47.0963 19.3898 47.72C19.3881 47.8701 19.4021 48.02 19.4314 48.1672C19.4638 48.3166 19.5094 48.4627 19.5676 48.604C19.6279 48.7456 19.701 48.8814 19.786 49.0096C19.8684 49.142 19.9661 49.2642 20.0772 49.3736C20.5206 49.8068 21.1141 50.0523 21.7339 50.059C21.8882 50.057 22.0418 50.0396 22.1926 50.007C22.3426 49.9763 22.4892 49.931 22.6304 49.8718C22.7729 49.8177 22.909 49.748 23.036 49.6638C23.1609 49.5751 23.2798 49.4782 23.3917 49.3736C23.4991 49.2612 23.5962 49.1394 23.6818 49.0096C23.7675 48.8803 23.8406 48.7431 23.9002 48.5999C23.9585 48.4586 24.0041 48.3124 24.0365 48.1631C24.0649 48.0157 24.0785 47.8659 24.077 47.7159C24.0769 47.0923 23.8301 46.4942 23.3906 46.0519C22.4535 45.1906 21.0122 45.1924 20.0772 46.056Z'
        fill={color}
      />
      <path
        d='M62.849 50.0475C63.0026 50.0496 63.156 50.0357 63.3066 50.0059C63.4574 49.9783 63.6044 49.933 63.7445 49.8707C63.8883 49.8189 64.0249 49.749 64.1511 49.6627C64.2757 49.5741 64.3942 49.4771 64.5058 49.3726C64.6162 49.2626 64.7139 49.1405 64.797 49.0086C64.8798 48.8826 64.9528 48.7505 65.0154 48.6134C65.0753 48.4727 65.121 48.3264 65.1516 48.1766C65.1804 48.0258 65.1944 47.8725 65.1932 47.719C65.1895 47.0959 64.9429 46.4989 64.5058 46.055C64.3942 45.9501 64.2757 45.8528 64.1511 45.7638C64.0247 45.6779 63.8881 45.608 63.7445 45.5558C63.6045 45.4934 63.4575 45.4484 63.3066 45.4216C62.5402 45.2698 61.7482 45.5069 61.1913 46.055C61.0836 46.1674 60.9862 46.2893 60.9001 46.419C60.815 46.5472 60.7419 46.683 60.6817 46.8246C60.622 46.9644 60.58 47.1111 60.5569 47.2614C60.5231 47.4112 60.5057 47.5643 60.5049 47.7179C60.5071 48.338 60.7538 48.9321 61.1913 49.3715C61.6359 49.8024 62.2299 50.0446 62.849 50.0475Z'
        fill={color}
      />
      <path
        d='M37.6034 46.8256C37.5428 46.684 37.4693 46.5482 37.3839 46.42C37.3018 46.2872 37.2036 46.165 37.0917 46.056C36.5355 45.5075 35.7436 45.2703 34.9774 45.4227C34.8273 45.4525 34.6806 45.4975 34.5395 45.5568C34.4003 45.6116 34.2674 45.6813 34.1433 45.7648C34.0155 45.8535 33.8939 45.9508 33.7793 46.056C33.3427 46.4958 33.0956 47.0889 33.0908 47.7086C33.0926 47.8625 33.1101 48.0158 33.1428 48.1662C33.173 48.3194 33.2183 48.4692 33.278 48.6134C33.3332 48.7521 33.403 48.8845 33.486 49.0086C33.5753 49.1362 33.673 49.2578 33.7783 49.3726C33.8929 49.4774 34.0145 49.5744 34.1423 49.6627C34.2662 49.7466 34.3991 49.8164 34.5385 49.8707C34.6797 49.9299 34.8263 49.9752 34.9763 50.0059C35.1306 50.0352 35.2873 50.0491 35.4443 50.0475C35.5951 50.0492 35.7457 50.0352 35.8936 50.0059C36.0443 49.9781 36.1913 49.9327 36.3314 49.8707C36.4747 49.8184 36.611 49.7486 36.737 49.6627C36.8617 49.5746 36.9799 49.4776 37.0906 49.3726C37.2023 49.2634 37.3004 49.1412 37.3829 49.0086C37.4661 48.8827 37.5394 48.7506 37.6023 48.6134C37.6621 48.4692 37.7073 48.3194 37.7375 48.1662C37.7673 48.0155 37.7812 47.8622 37.7791 47.7086C37.7808 47.5588 37.7668 47.4093 37.7375 47.2624C37.7093 47.1123 37.6643 46.9657 37.6034 46.8256Z'
        fill={color}
      />
      <path
        d='M48.6882 45.4227C48.5374 45.4496 48.3904 45.4946 48.2504 45.5568C48.1072 45.6093 47.971 45.6792 47.8448 45.7648C47.72 45.8536 47.6015 45.9509 47.4902 46.056C47.0503 46.4982 46.8032 47.0963 46.8027 47.72C46.8027 49.0147 47.8522 50.0642 49.1469 50.0642C50.4415 50.0642 51.491 49.0147 51.491 47.72C51.4937 47.0162 51.1788 46.3487 50.6339 45.9031C50.0891 45.4576 49.3724 45.2814 48.683 45.4237L48.6882 45.4227Z'
        fill={color}
      />
      <path
        d='M20.0772 55.651C19.6367 56.0927 19.3894 56.6912 19.3898 57.315C19.3881 57.4648 19.402 57.6143 19.4314 57.7612C19.4638 57.9108 19.5094 58.0574 19.5676 58.199C19.6279 58.3403 19.701 58.4757 19.786 58.6036C19.8682 58.7361 19.9659 58.8583 20.0772 58.9676C20.5205 59.4011 21.1139 59.6469 21.7339 59.654C21.8882 59.652 22.0418 59.6346 22.1926 59.602C22.3426 59.5712 22.4892 59.526 22.6304 59.4668C22.7727 59.4123 22.9087 59.3426 23.036 59.2588C23.1609 59.1697 23.2798 59.0725 23.3917 58.9676C23.4993 58.8553 23.5964 58.7335 23.6819 58.6036C23.7676 58.476 23.8411 58.3405 23.9013 58.199C23.9595 58.0574 24.0051 57.9108 24.0375 57.7612C24.066 57.6142 24.0796 57.4647 24.0781 57.315C24.0784 56.6914 23.8316 56.0931 23.3917 55.651C22.4554 54.7879 21.0135 54.7879 20.0772 55.651Z'
        fill={color}
      />
      <path
        d='M37.6033 56.4092C37.5404 56.2723 37.467 56.1406 37.3839 56.015C37.302 55.882 37.2038 55.7597 37.0916 55.651C36.9809 55.5456 36.8627 55.4483 36.738 55.3598C36.6117 55.2744 36.4755 55.2045 36.3324 55.1518C36.1922 55.0899 36.0453 55.0445 35.8946 55.0166C35.592 54.9542 35.2799 54.9542 34.9773 55.0166C34.8273 55.0473 34.6807 55.0926 34.5395 55.1518C34.4002 55.2066 34.2674 55.2763 34.1432 55.3598C34.0154 55.4485 33.8938 55.5458 33.7792 55.651C33.6734 55.7654 33.5758 55.887 33.487 56.015C33.4039 56.1387 33.3342 56.2708 33.279 56.4092C33.2193 56.5534 33.174 56.7032 33.1438 56.8564C33.111 57.0068 33.0936 57.1601 33.0918 57.314C33.0946 57.931 33.3422 58.5216 33.7803 58.9561C33.8895 59.0674 34.0117 59.1652 34.1443 59.2473C34.2657 59.3379 34.399 59.4114 34.5405 59.4657C34.6817 59.5249 34.8284 59.5702 34.9784 59.6009C35.1323 59.6336 35.289 59.651 35.4463 59.6529C36.0652 59.6451 36.6563 59.3949 37.0927 58.9561C37.2068 58.8531 37.3052 58.734 37.3849 58.6025C37.4703 58.4747 37.5437 58.3393 37.6043 58.198C37.6655 58.0575 37.7108 57.9106 37.7395 57.7601C37.7689 57.6133 37.7828 57.4637 37.7811 57.314C37.7833 57.1604 37.7693 57.007 37.7395 56.8564C37.709 56.7032 37.6634 56.5534 37.6033 56.4092Z'
        fill={color}
      />
      <path
        d='M51.3041 56.4092C51.2493 56.2706 51.1795 56.1384 51.0961 56.015C50.7125 55.424 50.0815 55.039 49.3805 54.9682C48.6796 54.8975 47.9843 55.1486 47.4904 55.651C47.0525 56.0898 46.8054 56.6837 46.803 57.3036C46.8008 57.4568 46.8147 57.6098 46.8446 57.7601C46.8746 57.9133 46.9199 58.0632 46.9798 58.2073C47.042 58.3448 47.1154 58.477 47.1992 58.6025C47.2813 58.7351 47.3791 58.8573 47.4904 58.9665C47.6017 59.0717 47.7202 59.169 47.845 59.2577C47.9712 59.3434 48.1074 59.4132 48.2506 59.4657C48.3908 59.5278 48.5377 59.5732 48.6885 59.6009C48.8395 59.6307 48.9932 59.6446 49.1471 59.6425C49.7659 59.6394 50.3595 59.3972 50.8038 58.9665C50.9124 58.8546 51.0103 58.7327 51.0961 58.6025C51.1798 58.4789 51.2495 58.3463 51.3041 58.2073C51.4217 57.9202 51.4852 57.6138 51.4913 57.3036C51.4886 57.1498 51.4716 56.9966 51.4403 56.846C51.4087 56.6964 51.3631 56.5502 51.3041 56.4092Z'
        fill={color}
      />
      <path
        d='M23.9004 66.389C23.8379 66.2516 23.7645 66.1194 23.6809 65.9938C23.5955 65.8638 23.4984 65.742 23.3908 65.6298C22.4567 64.7611 21.0104 64.7611 20.0763 65.6298C19.965 65.739 19.8672 65.8612 19.7851 65.9938C19.7023 66.1197 19.6292 66.2518 19.5667 66.389C19.5114 66.5309 19.4659 66.6765 19.4305 66.8247C19.4016 66.9755 19.3877 67.1288 19.3889 67.2823C19.3868 67.4359 19.4007 67.5893 19.4305 67.7399C19.4629 67.8893 19.5085 68.0354 19.5667 68.1767C19.627 68.3183 19.7001 68.4541 19.7851 68.5823C19.8675 68.7147 19.9652 68.8369 20.0763 68.9463C20.9979 69.8461 22.4692 69.8461 23.3908 68.9463C23.4982 68.8339 23.5953 68.7121 23.6809 68.5823C23.7667 68.4544 23.8402 68.3186 23.9004 68.1767C23.9586 68.0354 24.0042 67.8893 24.0366 67.7399C24.0655 67.5892 24.0791 67.4358 24.0772 67.2823C24.0782 67.1289 24.0646 66.9756 24.0366 66.8247C24.0012 66.6765 23.9557 66.5309 23.9004 66.389Z'
        fill={color}
      />
      <path
        d='M37.6034 66.389C37.5405 66.2518 37.4671 66.1197 37.3839 65.9938C37.3018 65.861 37.2036 65.7387 37.0917 65.6298C36.4211 64.9609 35.4127 64.7637 34.5395 65.1306C34.4003 65.1853 34.2674 65.2551 34.1433 65.3386C34.0155 65.4273 33.8939 65.5246 33.7793 65.6298C33.341 66.0684 33.0936 66.6623 33.0908 67.2823C33.0926 67.4362 33.1101 67.5896 33.1428 67.7399C33.1715 67.8901 33.2169 68.0366 33.278 68.1767C33.331 68.3197 33.4008 68.4559 33.486 68.5823C33.5725 68.7085 33.6703 68.8265 33.7783 68.9349C33.8872 69.0465 34.0095 69.1443 34.1423 69.2261C34.2685 69.309 34.401 69.382 34.5385 69.4445C34.6797 69.5037 34.8263 69.549 34.9763 69.5797C35.1306 69.609 35.2873 69.6229 35.4443 69.6213C35.5951 69.6229 35.7457 69.609 35.8936 69.5797C36.0443 69.5518 36.1913 69.5064 36.3314 69.4445C36.4729 69.384 36.6087 69.3109 36.737 69.2261C36.8668 69.1444 36.9856 69.0465 37.0906 68.9349C37.2048 68.8323 37.3032 68.7135 37.3829 68.5823C37.4683 68.4541 37.5417 68.3184 37.6023 68.1767C37.6635 68.0366 37.7088 67.8901 37.7375 67.7399C37.7673 67.5893 37.7812 67.4359 37.7791 67.2823C37.7803 67.1288 37.7664 66.9755 37.7375 66.8247C37.7062 66.6757 37.6613 66.5298 37.6034 66.389Z'
        fill={color}
      />
      <path
        d='M51.304 66.389C51.2472 66.2477 51.1776 66.112 51.096 65.9834C51.0075 65.8585 50.9098 65.7403 50.8038 65.6298C50.2454 65.0841 49.4549 64.847 48.6884 64.9954C48.5392 65.0246 48.3928 65.0663 48.2506 65.1202C48.109 65.1804 47.9732 65.2535 47.845 65.3386C47.7178 65.4241 47.599 65.5216 47.4904 65.6298C47.0502 66.0671 46.8027 66.6619 46.8027 67.2823C46.8027 67.9028 47.0502 68.4976 47.4904 68.9349C47.596 69.0463 47.7151 69.1441 47.845 69.2261C47.9708 69.3151 48.107 69.3884 48.2506 69.4445C48.3907 69.5066 48.5377 69.552 48.6884 69.5797C48.8395 69.6094 48.9932 69.6234 49.1471 69.6213C49.768 69.6191 50.3632 69.3725 50.8038 68.9349C50.9125 68.8272 51.0104 68.7091 51.096 68.5823C51.1817 68.4561 51.2515 68.3199 51.304 68.1767C51.366 68.0368 51.4117 67.8903 51.4403 67.7399C51.4727 67.5895 51.4897 67.4362 51.4912 67.2823C51.4886 67.1285 51.4715 66.9753 51.4403 66.8247C51.4087 66.6755 51.3631 66.5296 51.304 66.389Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.528 48.4459V19.4363H68.7841V7H58.3623V19.4363H27.6647V7H17.2439V19.4363H0.5V67.6671C0.524084 78.0078 8.9136 86.3777 19.2543 86.3777H60.4225C66.745 95.1705 78.1188 98.7248 88.3229 95.0966C98.527 91.4684 105.104 81.5315 104.456 70.721C103.808 59.9104 96.0923 50.8299 85.528 48.4459ZM19.2543 75.9829C14.6569 75.983 10.9278 72.2603 10.9197 67.663V29.831H75.1093V48.3648C62.409 51.0104 54.0335 63.1745 56.093 75.9829H19.2543ZM60.566 72.1063C60.549 82.9377 69.3115 91.7344 80.1429 91.7597C90.9875 91.7603 99.7861 82.9826 99.8113 72.1381C99.8119 61.3066 91.036 52.5232 80.2046 52.5145C69.3731 52.5057 60.5831 61.2748 60.566 72.1063Z'
        fill={color}
      />
      <path
        d='M77.3441 76.0474L70.1338 68.8548L66.8193 72.162L77.3441 82.6629L93.4651 66.5783L90.1506 63.2711L77.3441 76.0474Z'
        fill={color}
      />
    </svg>
  )
}

export default CalendarIcon
