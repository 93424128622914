import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../../../lib/utils/addTokenReq'
import { RegistrationDataType } from '../../../../types/auth.types'
import { reqBeneficiaryDataType } from '../../../../types/initiator.types'

/**
 * manage user account adm service
 */

export type ConfirmationRequestTypeAdmin = Pick<
  RegistrationDataType,
  'email' | 'name' | 'token' | 'otpCode'
>

export class UserServiceAdmin {
  /**
   * Create a User Account
   * @returns Promise<any>
   */
  public async createUserAccount(data: Omit<RegistrationDataType, 'confirmPassword'>) {
    return await (
      await publicApiRequest()
    ).post('/auth/users/add-specified-user', data, addTokenToHeaders(data.token ?? ''))
  }

  /**
   * Get list of user in the organization
   * @returns Promise<any>
   */
  public async getUsersOrg(token: string) {
    return await (
      await publicApiRequest()
    ).get('/auth/users/get-company-employees', addTokenToHeaders(token))
  }

  /**
   * Delete a user of the org
   * @returns Promise<any>
   */
  public async deleteUserOrg(data: reqBeneficiaryDataType) {
    return await (
      await publicApiRequest()
    ).patch(`/auth/users/remove-user/${data.id}`, null, addTokenToHeaders(data.token ?? ''))
  }

  /**
   * Get confirmation code admin
   * @returns Promise<any>
   */
  public async getConfirmationCodeAdmin(data: ConfirmationRequestTypeAdmin) {
    return await (
      await publicApiRequest()
    ).post<{
      description: string
      success: boolean
    }>(
      '/auth/request-email-authorization',
      {
        email: data.email,
        name: data.name,
      },
      addTokenToHeaders(data.token ?? ''),
    )
  }

  /**
   * Verify add otp to create user account
   * @returns Promise<any>
   */
  public async emailVerificationCodeAdmin(data: ConfirmationRequestTypeAdmin) {
    return await (
      await publicApiRequest()
    ).patch(`/auth/verify-auth-otp/${data.otpCode}`, null, addTokenToHeaders(data.token ?? ''))
  }

  /**
   * Activate user account
   * @returns Promise<any>
   */
  public async activateUserAccount(token: string) {
    return await (await publicApiRequest()).patch(`/auth/users/activate-account/${token}`)
  }
  /**
   * Rensend activation link
   * @returns Promise<any>
   */
  public async resendActivationLink(token: string, employeeId: string) {
    return await (
      await publicApiRequest()
    ).get(`/auth/users/resend-activation-link/${employeeId}`, addTokenToHeaders(token))
  }
}
