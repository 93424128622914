import React from 'react'

// general imports
import { IconPropsType } from '../types'

const CheckboxIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 2,
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1' y='1.5' width='22' height='22' rx='2' fill='fff' />
      <rect x='1' y='1.5' width='22' height='22' rx='2' stroke={color} strokeWidth={stroke} />
    </svg>
  )
}

export default CheckboxIcon
