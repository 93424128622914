import React from 'react'
import { IconPropsType } from '../types'

const DeactivatePersonIcon = ({ width = 20, height = 20 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.75 18.0626L14.8334 16.1667C14.75 16.2778 14.6528 16.3612 14.5417 16.4167C14.4306 16.4723 14.3056 16.5001 14.1667 16.5001H2.50002C2.26391 16.5001 2.06613 16.4201 1.90669 16.2601C1.74669 16.1006 1.66669 15.9028 1.66669 15.6667V14.1667C1.66669 13.6945 1.78835 13.2603 2.03169 12.8642C2.27446 12.4687 2.59724 12.1667 3.00002 11.9584C3.86113 11.5278 4.73613 11.2048 5.62502 10.9892C6.51391 10.7742 7.41669 10.6667 8.33335 10.6667C8.51391 10.6667 8.69113 10.6701 8.86502 10.6767C9.03835 10.684 9.20835 10.6945 9.37502 10.7084L8.50002 9.8334H8.33335C7.41669 9.8334 6.63196 9.50701 5.97919 8.85423C5.32641 8.20145 5.00002 7.41673 5.00002 6.50006V6.33339L2.58335 3.91673C2.41669 3.75006 2.33669 3.55562 2.34335 3.33339C2.35058 3.11117 2.43752 2.91673 2.60419 2.75006C2.77085 2.58339 2.96891 2.50006 3.19835 2.50006C3.42724 2.50006 3.62502 2.58339 3.79169 2.75006L17.9375 16.8959C18.1042 17.0626 18.1875 17.257 18.1875 17.4792C18.1875 17.7014 18.1042 17.8959 17.9375 18.0626C17.7709 18.2292 17.5728 18.3126 17.3434 18.3126C17.1145 18.3126 16.9167 18.2292 16.75 18.0626ZM11.3334 7.91673L10 6.5834V6.50006C10 6.04173 9.83696 5.64951 9.51085 5.32339C9.18419 4.99673 8.79169 4.83339 8.33335 4.83339H8.25002L6.91669 3.50006C7.12502 3.38895 7.35085 3.30562 7.59419 3.25006C7.83696 3.19451 8.08335 3.16673 8.33335 3.16673C9.25002 3.16673 10.0347 3.49312 10.6875 4.14589C11.3403 4.79867 11.6667 5.58339 11.6667 6.50006C11.6667 6.75006 11.6389 6.99645 11.5834 7.23923C11.5278 7.48256 11.4445 7.70839 11.3334 7.91673ZM3.33335 14.8334H13.3334V14.6667L11.5417 12.8751C11.0139 12.6945 10.4792 12.559 9.93752 12.4684C9.39585 12.3784 8.86113 12.3334 8.33335 12.3334C7.55558 12.3334 6.78474 12.427 6.02085 12.6142C5.25696 12.802 4.50002 13.0834 3.75002 13.4584C3.62502 13.5278 3.52446 13.6251 3.44835 13.7501C3.37169 13.8751 3.33335 14.014 3.33335 14.1667V14.8334Z'
        fill='#FF5247'
      />
    </svg>
  )
}

export default DeactivatePersonIcon
