import React from 'react'

// general imports
import { IconPropsType } from '../types'

const HistoryIcon = ({ width = 48, height = 48, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 42C6.9 42 5.958 41.608 5.174 40.824C4.39 40.04 3.99867 39.0987 4 38V16C4 14.9 4.392 13.958 5.176 13.174C5.96 12.39 6.90134 11.9987 8 12H16V8C16 6.9 16.392 5.958 17.176 5.174C17.96 4.39 18.9013 3.99867 20 4H28C29.1 4 30.042 4.392 30.826 5.176C31.61 5.96 32.0013 6.90134 32 8V12H40C41.1 12 42.042 12.392 42.826 13.176C43.61 13.96 44.0013 14.9013 44 16V24.55C43.4 24.1167 42.7667 23.7413 42.1 23.424C41.4333 23.1067 40.7333 22.832 40 22.6V16H8V38H22.15C22.25 38.7 22.4 39.3833 22.6 40.05C22.8 40.7167 23.05 41.3667 23.35 42H8ZM20 12H28V8H20V12ZM36 46C33.2333 46 30.8747 45.0247 28.924 43.074C26.9733 41.1233 25.9987 38.7653 26 36C26 33.2333 26.9753 30.8747 28.926 28.924C30.8767 26.9733 33.2347 25.9987 36 26C38.7667 26 41.1253 26.9753 43.076 28.926C45.0267 30.8767 46.0013 33.2347 46 36C46 38.7667 45.0247 41.1253 43.074 43.076C41.1233 45.0267 38.7653 46.0013 36 46ZM39.3 40.7L40.7 39.3L37 35.6V30H35V36.4L39.3 40.7Z'
        fill={color}
      />
    </svg>
  )
}

export default HistoryIcon
