import React from 'react'

// general imports
import { IconPropsType } from '../types'

const NeedleIcon = ({ width = 36, height = 36, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7231 28.1883L35.9984 0.000854492L8.3308 24.4689C7.61461 24.1669 6.82753 24 6.00146 24C2.68776 24 0.00146484 26.6863 0.00146484 30C0.00146484 33.3137 2.68776 36 6.00146 36C9.31517 36 12.0015 33.3137 12.0015 30C12.0015 29.3686 11.9039 28.7599 11.7231 28.1883Z'
        fill={color}
      />
    </svg>
  )
}

export default NeedleIcon
