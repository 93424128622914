import React from 'react'
import { IconPropsType } from '../types'

const CloseIcon = ({ width = 36, height = 36, color = '#009fe3' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.9999 34.6668C27.2047 34.6668 34.6666 27.2049 34.6666 18.0002C34.6666 8.79542 27.2047 1.3335 17.9999 1.3335C8.79517 1.3335 1.33325 8.79542 1.33325 18.0002C1.33325 27.2049 8.79517 34.6668 17.9999 34.6668Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M22.1666 13.8335L13.8333 22.1668M13.8333 13.8335L22.1666 22.1668'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default CloseIcon
