// general imports
import { IconPropsType } from '../types'

const MinusIcon = ({ width = 24, height = 24, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.75 12H20.25'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default MinusIcon
