import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { ApiRequestErrorType } from '../../../types/api/api.types'
import { PhoneNumberService } from '../../../services/api'
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'

const phoneNumberService = new PhoneNumberService()

export type PhoneNumberType = {
  name: string
  callingCodes: string[]
  flags: {
    png: string
  }
  alpha3Code: string
}

export type ExtractedPhoneNumberType = {
  name: string
  callingCode: string
  flag: string
  alpha3Code: string
}

export const getPhoneNumbersThunk = createAsyncThunk<
  PhoneNumberType[],
  void,
  { rejectValue: ApiRequestErrorType }
>('/get/phoneNumbers', async (_, { rejectWithValue }) => {
  try {
    const response = await phoneNumberService.getAllPhoneNumbers()
    return response?.data
  } catch (ex) {
    return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
  }
})
