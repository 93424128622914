// general imports
import { IconPropsType } from '../types'

const AnalyticsIcon = ({ width = 18, height = 18 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.25 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM14.25 14.25H3.75V3.75H14.25V14.25Z'
        fill='#036E67'
      />
      <path d='M6.75 9H5.25V12.75H6.75V9Z' fill='#036E67' />
      <path d='M12.75 5.25H11.25V12.75H12.75V5.25Z' fill='#036E67' />
      <path d='M9.75 10.5H8.25V12.75H9.75V10.5Z' fill='#036E67' />
      <path d='M9.75 7.5H8.25V9H9.75V7.5Z' fill='#036E67' />
    </svg>
  )
}

export default AnalyticsIcon
