import React from 'react'

// general imports
import { IconPropsType } from '../types'

const AdminPanelSettingsIcon = ({ width = 14, height = 14 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.66634 9.41337C10.0787 9.41337 10.413 9.07908 10.413 8.66671C10.413 8.25433 10.0787 7.92004 9.66634 7.92004C9.25397 7.92004 8.91967 8.25433 8.91967 8.66671C8.91967 9.07908 9.25397 9.41337 9.66634 9.41337Z'
        fill='#336175'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.66634 10C9.17968 10 8.20634 10.24 8.17301 10.72C8.50634 11.1934 9.05301 11.5 9.66634 11.5C10.2797 11.5 10.8263 11.1934 11.1597 10.72C11.1263 10.24 10.153 10 9.66634 10Z'
        fill='#336175'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.333 5.72671V2.51337L5.33301 0.333374L0.333008 2.51337V5.78671C0.333008 8.81337 2.46634 11.6467 5.33301 12.3334C5.69967 12.2467 6.05301 12.12 6.39967 11.9667C7.11968 12.9934 8.31301 13.6667 9.66634 13.6667C11.873 13.6667 13.6663 11.8734 13.6663 9.66671C13.6663 7.68671 12.2263 6.04671 10.333 5.72671ZM5.66634 9.66671C5.66634 10.04 5.71967 10.4067 5.81967 10.7467C5.65967 10.82 5.49967 10.8934 5.33301 10.9467C3.21967 10.28 1.66634 8.12004 1.66634 5.78671V3.38671L5.33301 1.78671L8.99967 3.38671V5.72671C7.10634 6.04671 5.66634 7.68671 5.66634 9.66671ZM9.66634 12.3334C8.19301 12.3334 6.99967 11.14 6.99967 9.66671C6.99967 8.19337 8.19301 7.00004 9.66634 7.00004C11.1397 7.00004 12.333 8.19337 12.333 9.66671C12.333 11.14 11.1397 12.3334 9.66634 12.3334Z'
        fill='#336175'
      />
    </svg>
  )
}

export default AdminPanelSettingsIcon
