import { lazy } from 'react'

import { APP_CONSTANTS } from '../repository/constants/constants'
import { AppRouteType } from './../types/router.types'

import guardController from './guards'

export const appRoutes: Array<AppRouteType> = [
  {
    path: APP_CONSTANTS.APP_PATHS.ICONS_PAGE,
    component: lazy(() => import('../pages/icons/Icons.page')),
    guards: [],
  },
  {
    path: APP_CONSTANTS.APP_PATHS.ACTIVATE_ACCOUNT,
    component: lazy(() => import('../pages/activate-account/ActivateAccount')),
    guards: [],
  },
  {
    path: '/email',
    component: lazy(() => import('../pages/email-template/email-template')),
    guards: [],
  },

  {
    path: APP_CONSTANTS.APP_PATHS.DASHBOARD_STRIPE,
    component: lazy(() => import('../pages/stripe/Stripe.page')),
    guards: [],
  },
  {
    path: APP_CONSTANTS.APP_PATHS.DASHBOARD_STRIPE_SUCCESS,
    component: lazy(() => import('../pages/stripe/Success.page')),
    guards: [],
  },

  {
    path: APP_CONSTANTS.APP_PATHS.HOME_PAGE,
    component: lazy(() => import('../pages/auth/login/Login.page')),
    // guards: [],
    guards: [guardController.verifyAuthRouteAccess],
  },
  {
    path: APP_CONSTANTS.APP_PATHS.LOGIN,
    component: lazy(() => import('../pages/auth/login/Login.page')),
    guards: [guardController.verifyAuthRouteAccess],
    // guards: [],
  },
  {
    path: APP_CONSTANTS.APP_PATHS.REGISTER,
    component: lazy(() => import('../pages/auth/register/Register.page')),
    // guards: [],
    guards: [guardController.verifyAuthRouteAccess],
  },
  {
    path: APP_CONSTANTS.APP_PATHS.FORGOT_PASS,
    component: lazy(() => import('../pages/auth/forgot-password/ForgotPassword.page')),
    // guards: [],
    guards: [guardController.verifyAuthRouteAccess],
  },
  {
    path: APP_CONSTANTS.APP_PATHS.SUPER_ADMIN,
    component: lazy(() => import('../pages/super-admin/SuperAdmin.page')),
    guards: [guardController.verifyUserAuthenticity],
    // guards: [],
    nestedComponents: [
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_INDEX,
        component: lazy(() => import('../pages/super-admin/modules/index/SuperIndex.module')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_SUBSCRIPTION,
        component: lazy(
          () => import('../pages/super-admin/modules/subscription/SuperSubscription.module'),
        ),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_API_DOC,
        component: lazy(() => import('../pages/super-admin/modules/api-docs/SuperApiDocs.module')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_SERVICES,
        component: lazy(() => import('../pages/super-admin/modules/services/SuperServices.module')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_REPORTS,
        component: lazy(() => import('../pages/super-admin/modules/reports/SuperReports.module')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_ORGANIZATIONS,
        component: lazy(
          () => import('../pages/super-admin/modules/organizations/SuperOrganizations.module'),
        ),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_ORGANIZATIONS_REQUEST,
        component: lazy(
          () =>
            import(
              '../pages/super-admin/modules/organisation-request/SuperOrganisationRequest.module'
            ),
        ),
        guards: [],
      },

      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_PROCESS_REFILLS,
        component: lazy(
          () => import('../pages/super-admin/modules/process-refills/ProcessRefills.module'),
        ),
        guards: [],
      },

      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_INDIVIDUALS,
        component: lazy(
          () => import('../pages/super-admin/modules/individuals/SuperIndividuals.module'),
        ),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.SUPER_SETTINGS,
        component: lazy(() => import('../pages/super-admin/modules/settings/SuperSettings.module')),
        guards: [],
      },
    ],
  },
  {
    path: APP_CONSTANTS.APP_PATHS.DASHBOARD,
    component: lazy(() => import('../pages/dashboard/DashboardEntry.page')),
    guards: [guardController.verifyUserAuthenticity],
    // guards: [],
    nestedComponents: [
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_INDEX,
        component: lazy(() => import('../pages/dashboard/modules/index/DashboardIndex.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_USERS,
        component: lazy(() => import('../pages/dashboard/modules/users/DashboardUsers.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_BENEFICIARIES,
        component: lazy(() => import('../pages/dashboard/modules/beneficiares/Beneficiaries.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_TRANSACTIONS,
        component: lazy(
          () => import('../pages/dashboard/modules/transactions/DashboardTransactions.page'),
        ),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_EXPENSES,
        component: lazy(() => import('../pages/dashboard/modules/expenses/DashboardExpenses.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_BALANCES,
        component: lazy(() => import('../pages/dashboard/modules/balances/DashboardBalances.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_HISTORY,
        component: lazy(() => import('../pages/dashboard/modules/history/DashboardHistory.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_TARGET,
        component: lazy(() => import('../pages/dashboard/modules/target/DashboardTarget.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_PROFILE,
        component: lazy(() => import('../pages/dashboard/modules/profile/DashboardProfile.page')),
        guards: [],
      },
      {
        path: APP_CONSTANTS.APP_PATHS.DASHBOARD_SETTINGS,
        component: lazy(() => import('../pages/dashboard/modules/settings/DashboardSettings.page')),
        guards: [],
      },
    ],
  },
]
