import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { ProfileType } from '../../types/profile.type'

/**
 * Profile service
 */

export type EditUserInfoProfileType = Omit<
  ProfileType,
  'profilePicture' | 'email' | 'organizationId' | 'userId' | 'phoneNumber'
>

export interface uploadUserProfileInfoData {
  jsonData: EditUserInfoProfileType
  imageData: string
  token: string
}

export interface userProfileType {
  userId: string
  token: string
}

export interface updateUseremailTypes {
  newEmail: string
  token: string
  password: string
}

export interface confirmUpdateUserEmailTypes {
  email: string
  otpCode: string
  token: string
}
export interface updateUserPhoneNumberTypes {
  newPhoneNumber: string
  token: string
  passsword: string
}

export interface confirmUpdateUserPhoneNumberTypes {
  phoneNumber: string
  otpCode: string
  token: string
}

export class ProfileService {
  /**
   * Get Profile Information
   * @returns Promise<any>
   */

  public async getUserProfileInfo(token: string) {
    return await (
      await publicApiRequest()
    ).get('/profile/user-profile-info', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  public async editUserProfileInfo(data: uploadUserProfileInfoData) {
    const formData = new FormData()

    formData.append('jsonData', JSON.stringify(data.jsonData))
    formData.append('image', data.imageData)

    return await (
      await publicApiRequest()
    ).patch('/profile/edit', formData, {
      headers: {
        Authorization: `Bearer ${data.token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  /**
   * Get Profile Picture
   * @returns Promise<any>
   */
  public async getUserProfilePicture(data: userProfileType) {
    return await (
      await publicApiRequest()
    ).get(`/profile/user-profile-pic/${data.userId}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
  }

  public async DeleteUserProfilePicture(token: string) {
    return await (
      await publicApiRequest()
    ).get('profile/remove-profile-pic/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  /**
   * Add User Profile
   * @returns Promise<any>
   */
  public async addUserProfile(data: userProfileType) {
    return await (
      await publicApiRequest()
    ).post(`/profile/register/${data.userId}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
  }
  /**
   * Delete User Profile
   * @returns Promise<any>
   */
  public async DeleteUserProfile(data: userProfileType) {
    return await (
      await publicApiRequest()
    ).patch(`/profile/soft-delete/${data.userId}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
  }
  /**
   * Update User Email
   * @returns Promise<any>
   */
  public async updateUserEmail(data: updateUseremailTypes) {
    return await (
      await publicApiRequest()
    ).patch(
      '/auth/users/update-email',
      { newEmail: data.newEmail, password: data.password },
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      },
    )
  }
  /**
   * Confirmm Update User Email
   * @returns Promise<any>
   */
  public async confirmUpdateUserEmail(data: confirmUpdateUserEmailTypes) {
    return await (
      await publicApiRequest()
    ).patch(
      '/auth/users/confirm-email-update',
      { email: data.email, otpCode: data.otpCode },
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      },
    )
  }
  /**
   * Update User Phone number
   * @returns Promise<any>
   */
  public async updateUserPhoneNumber(data: updateUserPhoneNumberTypes) {
    return await (
      await publicApiRequest()
    ).patch(
      '/auth/users/update-phone-number',
      { newPhoneNumber: data.newPhoneNumber, password: data.passsword },
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      },
    )
  }
  /**
   * Confirmm Update User Phone number
   * @returns Promise<any>
   */
  public async confirmUpdateUserPhoneNumber(data: confirmUpdateUserPhoneNumberTypes) {
    return await (
      await publicApiRequest()
    ).patch(
      '/auth/users/confirm-phone-number-update',
      { phoneNumber: data.phoneNumber, otpCode: data.otpCode },
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      },
    )
  }
}
