import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import {
  AuthenticationService,
  OtpVerificationType,
  refreshTokenType,
} from '../../../services/api/auth.service'
import { ApiRequestErrorType } from '../../../types/api/api.types'
import {
  LoginDataType,
  RegistrationDataType,
  ResetPasswordDataType,
} from '../../../types/auth.types'

const authService = new AuthenticationService()

export type ConfirmationRequestType = Pick<RegistrationDataType, 'email' | 'name'>

export const getEmailConfirmationCodeThunk = createAsyncThunk(
  '/auth/request-email-verification',
  async (data: ConfirmationRequestType, { rejectWithValue }) => {
    try {
      const response = await authService.getConfirmationCode(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const emailVerificationCodeThunk = createAsyncThunk(
  '/auth/verify-email-otp',
  async (data: OtpVerificationType, { rejectWithValue }) => {
    try {
      const response = await authService.emailVerificationCode(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const emailVerificationCodeUserThunk = createAsyncThunk(
  '/auth/verify-email',
  async (data: OtpVerificationType, { rejectWithValue }) => {
    try {
      const response = await authService.emailVerificationUserCode(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const createUserAccountThunk = createAsyncThunk(
  '/auth/create-account',
  async (data: Omit<RegistrationDataType, 'confirmPassword'>, { rejectWithValue }) => {
    try {
      const response = await authService.createUserAccount(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const resendEmailConfirmationCode = createAsyncThunk(
  '/auth/resend-email-verification',
  async (data: ConfirmationRequestType, { rejectWithValue }) => {
    try {
      const response = await authService.resendEmailConfirmationCode(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const verifyOtpAfterLoginThunk = createAsyncThunk(
  '/auth/otp-login',
  async (data: OtpVerificationType, { rejectWithValue }) => {
    try {
      const response = await authService.verifyOTPAfterLogin(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const loginUserThunk = createAsyncThunk(
  '/auth/login',
  async (data: LoginDataType, { rejectWithValue }) => {
    try {
      const response = await authService.loginuser(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const resendOptCodeThunk = createAsyncThunk(
  '/auth/resendOtp',
  async (data: LoginDataType, { rejectWithValue }) => {
    try {
      const response = await authService.loginuser(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const forgotPasswordThunk = createAsyncThunk(
  '/auth/forgot-password',
  async (data: ConfirmationRequestType, { rejectWithValue }) => {
    try {
      const response = await authService.forgotPassword(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const resetPasswordThunk = createAsyncThunk(
  '/auth/resetPassword',
  async (data: ResetPasswordDataType, { rejectWithValue }) => {
    try {
      const response = await authService.resetPassword(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const verifyResetOtpCodeThunk = createAsyncThunk(
  '/auth/verify-reset-otp',
  async (data: OtpVerificationType, { rejectWithValue }) => {
    try {
      const response = await authService.verifyResetOtpCode(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const refreshTokenThunk = createAsyncThunk(
  '/auth/refresh-token',
  async (data: refreshTokenType, { rejectWithValue }) => {
    try {
      const response = await authService.refreshToken(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
