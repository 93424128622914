import React from 'react'

// general imports
import { IconPropsType } from '../types'

const PdfIcon = ({ width = 33, height = 32, color = '#F4F4F4' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.5999 2.07227L30.1639 7.87227V29.9283H9.37891V30.0003H30.2349V7.94527L24.5999 2.07227Z'
        fill='#909090'
      />
      <path d='M24.5311 2H9.30811V29.928H30.1641V7.873L24.5311 2Z' fill={color} />
      <path d='M9.15514 3.5H2.76514V10.327H22.8651V3.5H9.15514Z' fill='#7A7B7C' />
      <path d='M22.972 10.2109H2.89502V3.37891H22.972V10.2109Z' fill='#DD2025' />
      <path
        d='M9.55212 4.5337H8.24512V9.3337H9.27312V7.7147L9.50012 7.7277C9.72069 7.7239 9.93919 7.68439 10.1471 7.6107C10.3294 7.54799 10.4971 7.449 10.6401 7.3197C10.7856 7.19652 10.9003 7.04105 10.9751 6.8657C11.0754 6.57419 11.1112 6.2644 11.0801 5.9577C11.0739 5.7386 11.0355 5.52162 10.9661 5.3137C10.903 5.1636 10.8093 5.0283 10.691 4.91639C10.5728 4.80448 10.4325 4.71842 10.2791 4.6637C10.1465 4.61569 10.0095 4.58086 9.87012 4.5597C9.76454 4.54341 9.65794 4.53472 9.55112 4.5337M9.36212 6.8277H9.27312V5.3477H9.46612C9.5513 5.34155 9.63677 5.35463 9.71622 5.38596C9.79566 5.41729 9.86706 5.46607 9.92512 5.5287C10.0454 5.68971 10.1097 5.8857 10.1081 6.0867C10.1081 6.3327 10.1081 6.5557 9.88612 6.7127C9.72618 6.80066 9.54414 6.84127 9.36212 6.8277ZM13.0331 4.5207C12.9221 4.5207 12.8141 4.5287 12.7381 4.5317L12.5001 4.5377H11.7201V9.3377H12.6381C12.9889 9.34731 13.3382 9.28785 13.6661 9.1627C13.93 9.05802 14.1637 8.88928 14.3461 8.6717C14.5235 8.45215 14.6508 8.19653 14.7191 7.9227C14.7977 7.61256 14.836 7.29361 14.8331 6.9737C14.8525 6.59585 14.8233 6.21709 14.7461 5.8467C14.6729 5.57406 14.5358 5.32279 14.3461 5.1137C14.1973 4.94486 14.0152 4.80867 13.8111 4.7137C13.6359 4.63261 13.4516 4.57285 13.2621 4.5357C13.1868 4.52325 13.1105 4.51756 13.0341 4.5187M12.8521 8.4557H12.7521V5.3917H12.7651C12.9713 5.36798 13.1799 5.40517 13.3651 5.4987C13.5008 5.60702 13.6113 5.7435 13.6891 5.8987C13.7731 6.06203 13.8215 6.2413 13.8311 6.4247C13.8401 6.6447 13.8311 6.8247 13.8311 6.9737C13.8352 7.14533 13.8241 7.317 13.7981 7.4867C13.7673 7.66093 13.7103 7.8295 13.6291 7.9867C13.5372 8.13285 13.413 8.256 13.2661 8.3467C13.1427 8.42649 12.9966 8.46372 12.8501 8.4527M17.9301 4.5377H15.5001V9.3377H16.5281V7.4337H17.8281V6.5417H16.5281V5.4297H17.9281V4.5377'
        fill='#464648'
      />
      <path
        d='M22.2812 20.2545C22.2812 20.2545 25.4692 19.6765 25.4692 20.7655C25.4692 21.8545 23.4942 21.4115 22.2812 20.2545ZM19.9242 20.3375C19.4177 20.4494 18.9241 20.6133 18.4512 20.8265L18.8512 19.9265C19.2512 19.0265 19.6662 17.7995 19.6662 17.7995C20.1435 18.6029 20.6988 19.3572 21.3242 20.0515C20.8526 20.1218 20.3852 20.218 19.9242 20.3395V20.3375ZM18.6622 13.8375C18.6622 12.8885 18.9692 12.6295 19.2082 12.6295C19.4472 12.6295 19.7162 12.7445 19.7252 13.5685C19.6473 14.3971 19.4738 15.2138 19.2082 16.0025C18.8443 15.3404 18.656 14.5961 18.6612 13.8405L18.6622 13.8375ZM14.0132 24.3535C13.0352 23.7685 16.0642 21.9675 16.6132 21.9095C16.6102 21.9105 15.0372 24.9655 14.0132 24.3535ZM26.4002 20.8945C26.3902 20.7945 26.3002 19.6875 24.3302 19.7345C23.5091 19.7213 22.6883 19.7792 21.8772 19.9075C21.0914 19.1159 20.4148 18.223 19.8652 17.2525C20.2115 16.2519 20.421 15.2092 20.4882 14.1525C20.4592 12.9525 20.1722 12.2645 19.2522 12.2745C18.3322 12.2845 18.1982 13.0895 18.3192 14.2875C18.4377 15.0926 18.6613 15.8786 18.9842 16.6255C18.9842 16.6255 18.5592 17.9485 17.9972 19.2645C17.4352 20.5805 17.0512 21.2705 17.0512 21.2705C16.0739 21.5887 15.1539 22.0613 14.3262 22.6705C13.5022 23.4375 13.1672 24.0265 13.6012 24.6155C13.9752 25.1235 15.2842 25.2385 16.4542 23.7055C17.0758 22.9137 17.6438 22.0812 18.1542 21.2135C18.1542 21.2135 19.9382 20.7245 20.4932 20.5905C21.0482 20.4565 21.7192 20.3505 21.7192 20.3505C21.7192 20.3505 23.3482 21.9895 24.9192 21.9315C26.4902 21.8735 26.4142 20.9925 26.4042 20.8965'
        fill='#DD2025'
      />
      <path d='M24.4541 2.07715V7.95015H30.0871L24.4541 2.07715Z' fill='#909090' />
      <path d='M24.5308 2V7.873H30.1638L24.5308 2Z' fill='#F4F4F4' />
      <path
        d='M9.47497 4.45655H8.16797V9.25655H9.19997V7.63855L9.42797 7.65155C9.64854 7.64775 9.86704 7.60824 10.075 7.53455C10.2573 7.47182 10.425 7.37283 10.568 7.24355C10.7124 7.12004 10.826 6.9646 10.9 6.78955C11.0003 6.49805 11.0361 6.18825 11.005 5.88155C10.9987 5.66246 10.9603 5.44547 10.891 5.23755C10.8278 5.08745 10.7342 4.95215 10.6159 4.84024C10.4976 4.72833 10.3573 4.64228 10.204 4.58755C10.0708 4.53908 9.9331 4.50391 9.79297 4.48255C9.68739 4.46626 9.58079 4.45757 9.47397 4.45655M9.28497 6.75055H9.19597V5.27055H9.38997C9.47515 5.26441 9.56062 5.27748 9.64007 5.30881C9.71952 5.34014 9.79091 5.38892 9.84897 5.45155C9.96929 5.61256 10.0336 5.80855 10.032 6.00955C10.032 6.25555 10.032 6.47855 9.80997 6.63555C9.65004 6.72351 9.46799 6.76312 9.28597 6.74955M12.956 4.44355C12.845 4.44355 12.737 4.45155 12.661 4.45455L12.426 4.46055H11.646V9.26055H12.564C12.9148 9.27016 13.2641 9.2107 13.592 9.08555C13.8559 8.98087 14.0896 8.81213 14.272 8.59455C14.4493 8.375 14.5766 8.11939 14.645 7.84555C14.7235 7.53542 14.7618 7.21646 14.759 6.89655C14.7784 6.51871 14.7491 6.13994 14.672 5.76955C14.5988 5.49691 14.4616 5.24564 14.272 5.03655C14.1232 4.86771 13.941 4.73152 13.737 4.63655C13.5617 4.55546 13.3774 4.4957 13.188 4.45855C13.1126 4.4461 13.0363 4.44041 12.96 4.44155M12.778 8.37855H12.678V5.31455H12.691C12.8971 5.29083 13.1057 5.32803 13.291 5.42155C13.4266 5.52987 13.5372 5.66635 13.615 5.82155C13.6989 5.98488 13.7473 6.16415 13.757 6.34755C13.766 6.56755 13.757 6.74755 13.757 6.89655C13.761 7.06818 13.75 7.23985 13.724 7.40955C13.6931 7.58378 13.6362 7.75235 13.555 7.90955C13.4631 8.0557 13.3389 8.17886 13.192 8.26955C13.0686 8.34935 12.9225 8.38657 12.776 8.37555M17.853 4.46055H15.423V9.26055H16.451V7.35655H17.751V6.46455H16.451V5.35255H17.851V4.46055'
        fill='white'
      />
    </svg>
  )
}

export default PdfIcon
