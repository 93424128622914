import React from 'react'

// general imports
import { IconPropsType } from '../types'

const CheckIcon = ({ width = 48, height = 48, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 94 94'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.4168 47L43.0835 58.75L62.6668 31.3334M47.0002 86.1667C68.6319 86.1667 86.1668 68.6318 86.1668 47C86.1668 25.3683 68.6319 7.83337 47.0002 7.83337C25.3684 7.83337 7.8335 25.3683 7.8335 47C7.8335 68.6318 25.3684 86.1667 47.0002 86.1667Z'
        stroke={color}
        strokeWidth='5'
      />
    </svg>
  )
}

export default CheckIcon
