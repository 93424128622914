import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { updateUserEmailThunk } from '../thunks/user-profile.thunk'

export type updateUserEmailState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
}

export const initialState: updateUserEmailState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const updateUserEmailSlice = createSlice({
  name: 'updateUserEmailSlice',
  initialState: initialState,
  reducers: {
    resetupdateUserEmailState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserEmailThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(updateUserEmailThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        console.log('a payload', action.payload)

        // state.requestResponse.data = action.payload.description
      })
      .addCase(updateUserEmailThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetupdateUserEmailState } = updateUserEmailSlice.actions
export default updateUserEmailSlice.reducer
