import { createSlice } from '@reduxjs/toolkit'
import { notificationProp } from '../../components/molecules/modals/notification/notificationModal.molecule'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../types/api/api.types'
import {
  deleteNotificationThunk,
  getNotificationsThunk,
  readNotificationThunk,
} from './notifications.thunk'

export type RegistrationState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  notifications: notificationProp[]
}

export const initialState: RegistrationState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  notifications: [] as notificationProp[],
}

const notificationSlice = createSlice({
  name: 'NotificationSlice',
  initialState: initialState,
  reducers: {
    resetNotifications: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getNotificationsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.notifications = action.payload
      })
      .addCase(getNotificationsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(readNotificationThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(readNotificationThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(readNotificationThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(deleteNotificationThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(deleteNotificationThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(deleteNotificationThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetNotifications } = notificationSlice.actions
export default notificationSlice.reducer
