// general imports
import { IconPropsType } from '../types'

const ChevronIcon = ({ width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 17L18 12L13 7'
        stroke='#9F9F9F'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 17L11 12L6 7'
        stroke='#9F9F9F'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ChevronIcon
