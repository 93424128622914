// general imports
import { IconPropsType } from '../types'

const AppleIcon = ({ width = 32, height = 32 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.1255 28.0082C11.2871 27.9628 10.4981 27.5972 9.92146 26.9869C9.23158 26.3232 8.61596 25.5864 8.08546 24.7895C7.25676 23.6029 6.60171 22.304 6.14012 20.9322C5.61773 19.4564 5.34291 17.9043 5.32679 16.3389C5.28621 14.8028 5.6666 13.2849 6.42679 11.9495C6.98356 10.987 7.77771 10.1832 8.73346 9.61486C9.67864 9.04895 10.7572 8.74433 11.8588 8.73219C12.6892 8.78132 13.5053 8.97113 14.2721 9.29353C14.8841 9.56286 15.5268 9.75219 16.1868 9.85619C16.9148 9.70368 17.6262 9.4804 18.3108 9.18953C19.121 8.87317 19.9789 8.69601 20.8481 8.66553C20.9641 8.66553 21.0788 8.66553 21.1895 8.67886C23.0961 8.73353 24.8695 9.66953 25.9895 11.2122C25.1154 11.6797 24.388 12.3804 23.8883 13.2365C23.3886 14.0926 23.1361 15.0705 23.1588 16.0615C23.1501 16.8193 23.3021 17.5703 23.6047 18.2651C23.9074 18.9598 24.3539 19.5826 24.9148 20.0922C25.4254 20.5785 26.0199 20.9684 26.6695 21.2429C26.5361 21.6429 26.3828 22.0295 26.2201 22.4189C25.8508 23.2792 25.3951 24.0999 24.8601 24.8682C24.3542 25.6379 23.7654 26.3497 23.1041 26.9909C22.501 27.5894 21.6969 27.9424 20.8481 27.9815C20.1286 27.9496 19.4218 27.7808 18.7655 27.4842C18.0577 27.1759 17.2972 27.007 16.5255 26.9869C15.732 27.0025 14.9489 27.1704 14.2188 27.4815C13.5882 27.766 12.9145 27.9431 12.2255 28.0055L12.1255 28.0082ZM16.3255 8.66553C16.2255 8.66553 16.1255 8.66553 16.0255 8.65353C16.0044 8.49482 15.9933 8.33495 15.9921 8.17486C16.0357 6.84304 16.5562 5.57117 17.4588 4.59086C17.9636 4.02747 18.5755 3.57014 19.2588 3.24553C19.8955 2.91773 20.5914 2.72054 21.3055 2.66553C21.3255 2.84019 21.3255 3.01086 21.3255 3.17353C21.3067 4.48442 20.8142 5.74423 19.9388 6.72019C19.515 7.28849 18.9719 7.75712 18.3477 8.09319C17.7234 8.42925 17.0332 8.6246 16.3255 8.66553Z'
        fill='black'
      />
    </svg>
  )
}

export default AppleIcon
