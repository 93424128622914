import { IconPropsType } from '../types'

const HelpIcon = ({ width = 33, height = 33 }: IconPropsType) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 35 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.8335 26.5H19.1668V23.1667H15.8335V26.5ZM17.5002 -0.166656C8.30016 -0.166656 0.833496 7.30001 0.833496 16.5C0.833496 25.7 8.30016 33.1667 17.5002 33.1667C26.7002 33.1667 34.1668 25.7 34.1668 16.5C34.1668 7.30001 26.7002 -0.166656 17.5002 -0.166656ZM17.5002 29.8333C10.1502 29.8333 4.16683 23.85 4.16683 16.5C4.16683 9.15001 10.1502 3.16668 17.5002 3.16668C24.8502 3.16668 30.8335 9.15001 30.8335 16.5C30.8335 23.85 24.8502 29.8333 17.5002 29.8333ZM17.5002 6.50001C13.8168 6.50001 10.8335 9.48334 10.8335 13.1667H14.1668C14.1668 11.3333 15.6668 9.83334 17.5002 9.83334C19.3335 9.83334 20.8335 11.3333 20.8335 13.1667C20.8335 16.5 15.8335 16.0833 15.8335 21.5H19.1668C19.1668 17.75 24.1668 17.3333 24.1668 13.1667C24.1668 9.48334 21.1835 6.50001 17.5002 6.50001Z'
        fill='#009fe3'
      />
    </svg>
  )
}

export default HelpIcon
