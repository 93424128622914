import targetSlice from '../features/admin/target/slices/target.slice'
import userRegistrationAdminSlice from '../features/admin/user/slices/user.slice'
import authSlice from '../features/auth/slices/auth.slice'
import getConfirmationSlice from '../features/auth/slices/get-confirmation.slice'
import loginUserSlice from '../features/auth/slices/login-user.slice'
import registerSlice from '../features/auth/slices/register.slice'
import resetPasswordSlice from '../features/auth/slices/reset-password.slice'
import verifyOtpSlice from '../features/auth/slices/verify-otp.slice'
import expensesSlice from '../features/expenses/slices/expenses.slice'
import beneficiarySlice from '../features/initiator/beneficiary/slices/beneficiary.slice'
import transactionSlice from '../features/initiator/transaction/slices/transaction.slice'
import notificationSlice from '../features/notifications/notifications.slice'
import deleteOrganisationSlice from '../features/organisation/slices/delete-organisation.slice'
import getAllOrganisationsSlice from '../features/organisation/slices/get-all-organisations.slice'
import suspendOrganisationSlice from '../features/organisation/slices/suspend-organisation.slice'
import phoneNumberSlice from '../features/phone-number/slices/phone-number.slice'
import refillWalletSlice from '../features/refill-wallet/slices/refill-wallet.slice'
import settingsSlice from '../features/settings/slices/settings.slice'
import superAdminDashboardSlice from '../features/super-admin/dashboard/slices/overview.slice'
import toastSlice from '../features/toast-notification/slices/toast'
import addAutomaticTransactionSlice from '../features/transaction/slices/add-automatic-transaction.slice'
import addManualTransactionSlice from '../features/transaction/slices/add-manual-transaction.slice'
import getAllBeneficiariesSlice from '../features/transaction/slices/get-all-beneficiaries.slice'
import getAllTransactionsSlice from '../features/transaction/slices/get-all-transactions.slice'
import getTransactionHistorySlice from '../features/transaction/slices/get-transaction-history.slice'
import getTransactionsSlice from '../features/transaction/slices/get-transactions.slice'
import getUpcomingBillSlice from '../features/transaction/slices/get-upcoming-bill.slice'
import confirmUpdateEmailSlice from '../features/user-profile/slices/confirm-update-email.slice'
import confirmUpdatePhoneNumberSlice from '../features/user-profile/slices/confirm-update-phone-number.slice'
import deleteUserProfilePictureSlice from '../features/user-profile/slices/delete-user-profile-picture.slice'
import editUserProfileSlice from '../features/user-profile/slices/edit-user-profile.slice'
import getUserProfileInfoSlice from '../features/user-profile/slices/get-user-profile-info.slice'
import getUserProfilePictureSlice from '../features/user-profile/slices/get-user-profile-picture.slice'
import updateEmailSlice from '../features/user-profile/slices/update-email.slice'
import updatePhoneNumberSlice from '../features/user-profile/slices/update-phone-number.slice'
import getWalletBalanceSlice from '../features/wallet/slices/get-wallet-balance.slice'

// eslint-disable-next-line
export default {
  phoneNumber: phoneNumberSlice,
  requestVerificationCode: getConfirmationSlice,
  registerSlice,
  verifyOtpSlice,
  loginUserSlice,
  resetPasswordSlice,
  getUserProfileInfoSlice,
  editUserProfileSlice,
  deleteUserProfilePictureSlice,
  getUserProfilePictureSlice,
  getAllTransactionsSlice,
  authSlice,
  beneficiarySlice,
  userRegistrationAdminSlice,
  getTransactionHistorySlice,
  getTransactionsSlice,
  getWalletBalanceSlice,
  getUpcomingBillSlice,
  getAllBeneficiariesSlice,
  addAutomaticTransactionSlice,
  addManualTransactionSlice,
  superAdminDashboardSlice,
  updateEmailSlice,
  confirmUpdateEmailSlice,
  updatePhoneNumberSlice,
  confirmUpdatePhoneNumberSlice,
  expensesSlice,
  getAllOrganisationsSlice,
  suspendOrganisationSlice,
  deleteOrganisationSlice,
  settingsSlice,
  targetSlice,
  transactionSlice,
  refillWalletSlice,
  notificationSlice,
  toastSlice,
}
