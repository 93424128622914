import { IconPropsType } from '../types'
const TransacIcon = ({ color = 'currentColor' }: IconPropsType) => {
  return (
    <svg width={12} height={14} viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 1.33337L10 0.333374L9 1.33337L8 0.333374L7 1.33337L6 0.333374L5 1.33337L4 0.333374L3 1.33337L2 0.333374V9.66671H0V11.6667C0 12.7734 0.893333 13.6667 2 13.6667H10C11.1067 13.6667 12 12.7734 12 11.6667V0.333374L11 1.33337ZM8 12.3334H2C1.63333 12.3334 1.33333 12.0334 1.33333 11.6667V11H8V12.3334ZM10.6667 11.6667C10.6667 12.0334 10.3667 12.3334 10 12.3334C9.63333 12.3334 9.33333 12.0334 9.33333 11.6667V9.66671H3.33333V2.33337H10.6667V11.6667Z'
        fill={color}
      />
      <path d='M8 3.66671H4V5.00004H8V3.66671Z' fill={color} />
      <path d='M10 3.66671H8.66667V5.00004H10V3.66671Z' fill={color} />
      <path d='M8 5.66671H4V7.00004H8V5.66671Z' fill={color} />
      <path d='M10 5.66671H8.66667V7.00004H10V5.66671Z' fill={color} />
    </svg>
  )
}

export default TransacIcon
