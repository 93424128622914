import React from 'react'
import { IconPropsType } from '../types'
const SquareCheckboxIcon = ({ width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke='#CDCFD0' />
    </svg>
  )
}

export default SquareCheckboxIcon
