import React from 'react'
import { IconPropsType } from '../types'

const ClockIcon = ({ width = 10, height = 10 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 9C7.2 9 9 7.2 9 5C9 2.8 7.2 1 5 1C2.8 1 1 2.8 1 5C1 7.2 2.8 9 5 9ZM5 0C7.75 0 10 2.25 10 5C10 7.75 7.75 10 5 10C2.25 10 0 7.75 0 5C0 2.25 2.25 0 5 0ZM5.25 5.4L2.85 6.8L2.5 6.1L4.5 4.95V2.5H5.25V5.4Z'
        fill='#6C7072'
      />
    </svg>
  )
}

export default ClockIcon
