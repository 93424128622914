import { IconPropsType } from '../types'

const GalleryAddIcon = ({ width, height }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4999 7.75839C11.24 7.26392 12.11 7 13 7C13.5714 7 14.0346 7.46322 14.0346 8.03464C14.0346 8.60606 13.5714 9.06928 13 9.06928C12.5193 9.06928 12.0493 9.21184 11.6496 9.47893C11.2498 9.74602 10.9383 10.1256 10.7543 10.5698C10.5703 11.014 10.5222 11.5027 10.616 11.9742C10.7098 12.4457 10.9413 12.8788 11.2812 13.2188C11.6212 13.5587 12.0543 13.7902 12.5258 13.884C12.9973 13.9778 13.486 13.9297 13.9302 13.7457C14.3744 13.5617 14.754 13.2502 15.0211 12.8504C15.2882 12.4507 15.4307 11.9807 15.4307 11.5C15.4307 10.9286 15.8939 10.4654 16.4654 10.4654C17.0368 10.4654 17.5 10.9286 17.5 11.5C17.5 12.39 17.2361 13.26 16.7416 14.0001C16.2471 14.7401 15.5443 15.3169 14.7221 15.6575C13.8998 15.998 12.995 16.0872 12.1221 15.9135C11.2492 15.7399 10.4474 15.3113 9.81802 14.682C9.18868 14.0526 8.7601 13.2508 8.58647 12.3779C8.41283 11.505 8.50195 10.6002 8.84254 9.77792C9.18314 8.95566 9.75991 8.25285 10.4999 7.75839Z'
        fill='#8D8D8D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.4802 13C36.0434 13 36.5 13.4661 36.5 14.041V22.7574C36.5 27.2164 35.6375 30.6085 33.4439 32.8604C31.2489 35.1136 27.9407 36 23.594 36H13.406C9.06063 36 5.75258 35.114 3.55723 32.861C1.36323 30.6094 0.5 27.2173 0.5 22.7574V19.2528C0.5 18.6779 0.956559 18.2118 1.51975 18.2118C2.08295 18.2118 2.53951 18.6779 2.53951 19.2528V22.7574C2.53951 27.014 3.37587 29.7227 5.00321 31.3928C6.62922 33.0615 9.26429 33.9181 13.406 33.9181H23.594C27.7371 33.9181 30.3721 33.0611 31.9977 31.3923C33.6248 29.7221 34.4605 27.0134 34.4605 22.7574V14.041C34.4605 13.4661 34.9171 13 35.4802 13Z'
        fill='#8D8D8D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.59967 3.07527C5.82422 0.868234 9.1763 0 13.5827 0H20.4716C21.0396 0 21.5 0.456808 21.5 1.02031C21.5 1.58381 21.0396 2.04062 20.4716 2.04062H13.5827C9.37924 2.04062 6.70419 2.88106 5.05405 4.51821C3.40392 6.15535 2.55681 8.80935 2.55681 12.9797C2.55681 13.5432 2.09638 14 1.5284 14C0.960432 14 0.5 13.5432 0.5 12.9797C0.5 8.60801 1.37512 5.28231 3.59967 3.07527Z'
        fill='#8D8D8D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.5 6C23.5 5.44772 23.9792 5 24.5704 5H34.4296C35.0208 5 35.5 5.44772 35.5 6C35.5 6.55228 35.0208 7 34.4296 7H24.5704C23.9792 7 23.5 6.55228 23.5 6Z'
        fill='#8D8D8D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.5 0C30.0523 0 30.5 0.479239 30.5 1.07041V10.9296C30.5 11.5208 30.0523 12 29.5 12C28.9477 12 28.5 11.5208 28.5 10.9296V1.07041C28.5 0.479239 28.9477 0 29.5 0Z'
        fill='#8D8D8D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.5496 19.675L21.4723 25.8014L21.4535 25.8173C20.5931 26.5267 19.5135 26.9145 18.3993 26.9145C17.2851 26.9145 16.2055 26.5267 15.3451 25.8173C15.3358 25.8096 15.3267 25.8018 15.3177 25.7939L14.7713 25.3101C14.3139 24.941 13.7519 24.7253 13.1651 24.694C12.5746 24.6624 11.9894 24.8191 11.4934 25.1414L3.09721 30.8241C2.62855 31.1413 1.99204 31.0177 1.67551 30.5481C1.35899 30.0784 1.48232 29.4405 1.95097 29.1233L10.3681 23.4264C11.2295 22.8634 12.2473 22.5897 13.2741 22.6445C14.301 22.6994 15.2839 23.0802 16.0806 23.7318C16.0909 23.7402 16.1011 23.7489 16.1111 23.7578L16.6591 24.2429C17.1508 24.6434 17.7653 24.8622 18.3993 24.8622C19.0351 24.8622 19.6512 24.6422 20.1436 24.2396L27.2209 18.1131L27.2397 18.0972C28.1002 17.3878 29.1797 17 30.2939 17C31.4081 17 32.4877 17.3878 33.3481 18.0972L33.367 18.1132L36.1454 20.5185C36.5734 20.889 36.6206 21.537 36.2509 21.9659C35.8812 22.3947 35.2345 22.4421 34.8066 22.0716L32.0382 19.6749C31.5458 19.2723 30.9297 19.0523 30.2939 19.0523C29.6582 19.0523 29.0421 19.2723 28.5496 19.675Z'
        fill='#8D8D8D'
      />
    </svg>
  )
}

export default GalleryAddIcon
