import { createSlice } from '@reduxjs/toolkit'

// general imports
import { addManualTransactionTypesV2 } from '../../../services/api'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import {
  addAutomaticTransactionV2Thunk,
  addManualTransactionV2Thunk,
} from '../thunks/transactions.thunk'

export type addManualTransactionState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType
  automaticTransaction: addManualTransactionTypesV2
}

export const initialState: addManualTransactionState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  // automaticTransaction: {} as addManualTransactionTypes,
  automaticTransaction: {} as addManualTransactionTypesV2,
}

const addManualTransactionSlice = createSlice({
  name: 'addManualTransactionSlice',
  initialState: initialState,
  reducers: {
    resetaddManualTransactionState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAutomaticTransactionV2Thunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(addManualTransactionV2Thunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.automaticTransaction = action.payload
        // state.requestResponse.data = action.payload.description
        console.log(action.payload)
      })
      .addCase(addManualTransactionV2Thunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetaddManualTransactionState } = addManualTransactionSlice.actions
export default addManualTransactionSlice.reducer
