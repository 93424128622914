import { createSlice } from '@reduxjs/toolkit'
import { ApiRequestDataType, ApiRequestStatus } from '../../../types/api/api.types'
import { getExpensesThunk } from '../thunks/expenses.thunk'

export type MonthlyData = {
  [month: string]: number[]
}

export type ExpensesState = {
  requestResponse: ApiRequestDataType
  // data: MonthlyData[]
}
const initialState: ExpensesState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: [],
  },
}

const ExpenseSlice = createSlice({
  name: 'expenseSlice',
  initialState: initialState,
  reducers: {
    clearState(state) {
      state.requestResponse.status = ApiRequestStatus.IDLE
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpensesThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
      })
      .addCase(getExpensesThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(getExpensesThunk.rejected, (state) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
      })
  },
})
export const { clearState } = ExpenseSlice.actions
export default ExpenseSlice.reducer
