function SuspendTIcon() {
  return (
    <svg width='23' height='23' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99996 1.66667C5.39996 1.66667 1.66663 5.40001 1.66663 10C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 10C18.3333 5.40001 14.6 1.66667 9.99996 1.66667ZM9.99996 16.6667C6.31663 16.6667 3.33329 13.6833 3.33329 10C3.33329 6.31667 6.31663 3.33334 9.99996 3.33334C13.6833 3.33334 16.6666 6.31667 16.6666 10C16.6666 13.6833 13.6833 16.6667 9.99996 16.6667ZM13.3333 13.3333H6.66663V6.66667H13.3333V13.3333Z'
        fill='#FD8721'
      />
    </svg>
  )
}

export default SuspendTIcon
