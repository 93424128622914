// general imports
import { IconPropsType } from '../types'

const BusinessIcon = ({ width = 14, height = 14 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00008 4.08333V1.75H1.16675V12.25H12.8334V4.08333H7.00008ZM3.50008 11.0833H2.33341V9.91667H3.50008V11.0833ZM3.50008 8.75H2.33341V7.58333H3.50008V8.75ZM3.50008 6.41667H2.33341V5.25H3.50008V6.41667ZM3.50008 4.08333H2.33341V2.91667H3.50008V4.08333ZM5.83341 11.0833H4.66675V9.91667H5.83341V11.0833ZM5.83341 8.75H4.66675V7.58333H5.83341V8.75ZM5.83341 6.41667H4.66675V5.25H5.83341V6.41667ZM5.83341 4.08333H4.66675V2.91667H5.83341V4.08333ZM11.6667 11.0833H7.00008V9.91667H8.16675V8.75H7.00008V7.58333H8.16675V6.41667H7.00008V5.25H11.6667V11.0833ZM10.5001 6.41667H9.33341V7.58333H10.5001V6.41667ZM10.5001 8.75H9.33341V9.91667H10.5001V8.75Z'
        fill='#717171'
      />
    </svg>
  )
}

export default BusinessIcon
