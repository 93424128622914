import React from 'react'
import { IconPropsType } from '../types'

const GreenTickIcon = ({ width = 30, height = 30 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='15' cy='15' r='15' fill='#f2f6fb' />
      <path
        d='M19.9966 8.25222C19.767 8.12447 19.5145 8.04324 19.2535 8.01315C18.9925 7.98306 18.7281 8.00472 18.4755 8.07689C18.2229 8.14905 17.987 8.27031 17.7812 8.43371C17.5755 8.59712 17.404 8.79948 17.2766 9.02922L13.5636 15.7112L11.4386 13.5862C11.2541 13.3952 11.0334 13.2428 10.7894 13.138C10.5454 13.0332 10.2829 12.978 10.0174 12.9757C9.75182 12.9734 9.48846 13.024 9.24267 13.1246C8.99688 13.2251 8.77357 13.3736 8.58579 13.5614C8.398 13.7492 8.24949 13.9725 8.14893 14.2183C8.04837 14.4641 7.99777 14.7275 8.00008 14.993C8.00238 15.2586 8.05756 15.521 8.16237 15.765C8.26719 16.009 8.41956 16.2297 8.61058 16.4142L12.6106 20.4142C12.9886 20.7932 13.4986 21.0012 14.0246 21.0012L14.3016 20.9812C14.6081 20.9383 14.9005 20.8249 15.1558 20.6498C15.4111 20.4747 15.6222 20.2428 15.7726 19.9722L20.7726 10.9722C20.9002 10.7427 20.9814 10.4902 21.0115 10.2293C21.0416 9.96838 21.02 9.70408 20.9479 9.4515C20.8759 9.19892 20.7548 8.96301 20.5915 8.75724C20.4283 8.55146 20.2261 8.37986 19.9966 8.25222Z'
        fill='#007fb6'
      />
    </svg>
  )
}

export default GreenTickIcon
