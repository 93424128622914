import { createSlice } from '@reduxjs/toolkit'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { refillWalletThunk } from '../thunks/refill-wallet.thunk'

export type refillWalletState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestResponse: ApiRequestDataType<any>
}

export const initialState: refillWalletState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const refillWalletSlice = createSlice({
  name: 'refillWalletSlice',
  initialState: initialState,
  reducers: {
    resetRefillWalletState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
      state.requestResponse.error = {} as StoredErrorResponseType
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(refillWalletThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(refillWalletThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(refillWalletThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetRefillWalletState } = refillWalletSlice.actions
export default refillWalletSlice.reducer
