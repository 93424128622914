import { IconPropsType } from '../types'
const FacebookIcon = ({ width = 32, height = 32 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_177_2153)'>
        <path
          d='M32 16C32 7.1635 24.8365 0 16 0C7.1635 0 0 7.16337 0 16C0 23.986 5.851 30.6054 13.5 31.8056L13.5 20.625L9.4375 20.625L9.4375 16L13.5 16L13.5 12.475C13.5 8.465 15.8888 6.25 19.5434 6.25C21.294 6.25 23.125 6.5625 23.125 6.5625L23.125 10.5L21.1075 10.5C19.1198 10.5 18.5 11.7334 18.5 12.9987L18.5 16L22.9375 16L22.2281 20.625L18.5 20.625L18.5 31.8056C26.149 30.6054 32 23.9861 32 16Z'
          fill='#1877F2'
        />
        <path
          d='M22.2283 20.625L22.9377 16L18.5002 16V12.9988C18.5002 11.7333 19.1201 10.5 21.1077 10.5H23.1252L23.1252 6.5625C23.1252 6.5625 21.2942 6.25 19.5436 6.25C15.889 6.25 13.5002 8.465 13.5002 12.475L13.5002 16L9.43771 16L9.43771 20.625L13.5002 20.625L13.5002 31.8056C14.3272 31.9352 15.1631 32.0002 16.0002 32C16.8373 32.0003 17.6732 31.9353 18.5002 31.8056L18.5002 20.625L22.2283 20.625Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_177_2153'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FacebookIcon
