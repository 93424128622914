import React from 'react'
import { IconPropsType } from '../types'

const LeftArrowIcon = ({ width = 6, height = 10, color = '#191919' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5 9L1 5L5 1' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default LeftArrowIcon
