import React from 'react'

// general imports
import { IconPropsType } from '../types'

const NormalEyeIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      stroke={`${stroke}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.9998 8.25C11.7292 8.25 12.4287 8.53973 12.9444 9.05546C13.4601 9.57118 13.7498 10.2707 13.7498 11C13.7498 11.7293 13.4601 12.4288 12.9444 12.9445C12.4287 13.4603 11.7292 13.75 10.9998 13.75C10.2705 13.75 9.57102 13.4603 9.05529 12.9445C8.53957 12.4288 8.24984 11.7293 8.24984 11C8.24984 10.2707 8.53957 9.57118 9.05529 9.05546C9.57102 8.53973 10.2705 8.25 10.9998 8.25ZM10.9998 4.125C15.5832 4.125 19.4973 6.97583 21.0832 11C19.4973 15.0242 15.5832 17.875 10.9998 17.875C6.4165 17.875 2.50234 15.0242 0.916504 11C2.50234 6.97583 6.4165 4.125 10.9998 4.125ZM2.91484 11C3.65574 12.5128 4.8062 13.7874 6.23544 14.6788C7.66469 15.5703 9.31537 16.0429 10.9998 16.0429C12.6843 16.0429 14.335 15.5703 15.7642 14.6788C17.1935 13.7874 18.3439 12.5128 19.0848 11C18.3439 9.48722 17.1935 8.21265 15.7642 7.32118C14.335 6.42972 12.6843 5.95712 10.9998 5.95712C9.31537 5.95712 7.66469 6.42972 6.23544 7.32118C4.8062 8.21265 3.65574 9.48722 2.91484 11Z'
        fill={color}
      />
    </svg>
  )
}

export default NormalEyeIcon
