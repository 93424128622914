import { createAsyncThunk } from '@reduxjs/toolkit'
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import { WalletService } from '../../../services/api'
import {
  organisationRequestType,
  refillRequestType,
} from '../../../services/api/organisation/organisation.service'
import { ApiRequestErrorType } from '../../../types/api/api.types'

const walletService = new WalletService()

export const getWalletBalanceThunk = createAsyncThunk(
  '/transaction/wallet',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await walletService.getWalletBalance(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getRefillsThunk = createAsyncThunk(
  '/transaction/wallet-refills',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await walletService.getRefills(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getProcessingRefillsThunk = createAsyncThunk(
  '/transaction/processing-refills',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await walletService.getProcessingRefills(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const processingRefillsThunk = createAsyncThunk(
  '/transaction/process-refills',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await walletService.processingRefills(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const processSpecificRefillsThunk = createAsyncThunk(
  '/transaction/process-specific-refill/',
  async (data: refillRequestType, { rejectWithValue }) => {
    try {
      const response = await walletService.processSpecificRefills(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const processOrgRefillsThunk = createAsyncThunk(
  '/transaction/process-org-refill/',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await walletService.processOrgRefills(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
