import React from 'react'

// general imports
import { IconPropsType } from '../types'

const CheckboxCheckedIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 2,
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1' y='1.5' width='22' height='22' rx='2' fill='white' />
      <path
        d='M9.5501 18.5001L3.8501 12.8001L5.2751 11.3751L9.5501 15.6501L18.7251 6.4751L20.1501 7.9001L9.5501 18.5001Z'
        fill={color}
      />
      <rect x='1' y='1.5' width='22' height='22' rx='2' stroke='black' strokeWidth={stroke} />
    </svg>
  )
}

export default CheckboxCheckedIcon
