import React from 'react'

// general imports
import { IconPropsType } from '../types'

const LockIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      stroke={`${stroke}`}
    >
      <path
        d='M16.4998 7.79167H15.5832V5.95833C15.5832 3.42833 13.5298 1.375 10.9998 1.375C8.46984 1.375 6.4165 3.42833 6.4165 5.95833V7.79167H5.49984C4.4915 7.79167 3.6665 8.61667 3.6665 9.625V18.7917C3.6665 19.8 4.4915 20.625 5.49984 20.625H16.4998C17.5082 20.625 18.3332 19.8 18.3332 18.7917V9.625C18.3332 8.61667 17.5082 7.79167 16.4998 7.79167ZM8.24984 5.95833C8.24984 4.43667 9.47817 3.20833 10.9998 3.20833C12.5215 3.20833 13.7498 4.43667 13.7498 5.95833V7.79167H8.24984V5.95833ZM16.4998 18.7917H5.49984V9.625H16.4998V18.7917ZM10.9998 16.0417C12.0082 16.0417 12.8332 15.2167 12.8332 14.2083C12.8332 13.2 12.0082 12.375 10.9998 12.375C9.9915 12.375 9.1665 13.2 9.1665 14.2083C9.1665 15.2167 9.9915 16.0417 10.9998 16.0417Z'
        fill={color}
      />
    </svg>
  )
}

export default LockIcon
