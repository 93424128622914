import { createAsyncThunk } from '@reduxjs/toolkit'
import { WalletService } from '../../../services/api'
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import { ApiRequestErrorType } from '../../../types/api/api.types'

const walletService = new WalletService()

export const refillWalletThunk = createAsyncThunk(
  '/transaction/refill-wallet',
  async (data: { token: string; amount: string; accountNumber: string }, { rejectWithValue }) => {
    try {
      const response = await walletService.refillWallet(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
