import { PhoneNumberType } from '../../features/phone-number/thunks/phone-number.thunk'
import { publicApiRequest } from '../../lib/hooks/axios-instance'

/**
 * Phone number service
 * All API requests pertaining to working with global phone numbers
 */

export class PhoneNumberService {
  /**
   * Get all phone numbers method
   * @returns Promise<any>
   */
  public async getAllPhoneNumbers() {
    const response = await (
      await publicApiRequest('https://restcountries.com/v2')
    ).get<PhoneNumberType[]>('/all')
    return response
  }
}

/**
 * For future reference
 */

// export const fetchData = async () => {
//   const source = 'https://restcountries.com/v2/all'
//   const response = await fetch(source)
//   let data = await response.json()
//   data = data.map(
//     (country: {
//       name: string
//       callingCodes: string[]
//       flags: {
//         png: string
//       }
//     }) => {
//       return { name: country.name, callingCode: country.callingCodes[0], flag: country.flags.png }
//     },
//   )

//   return data
// }
