// general imports
import { IconPropsType } from '../types'

const DotsIcon = ({ width = 26, height = 26 }: IconPropsType) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        d='M5.41683 13.0003H5.42766M13.0002 13.0003H13.011M20.5835 13.0003H20.5943M6.50016 13.0003C6.50016 13.5986 6.01514 14.0837 5.41683 14.0837C4.81852 14.0837 4.3335 13.5986 4.3335 13.0003C4.3335 12.402 4.81852 11.917 5.41683 11.917C6.01514 11.917 6.50016 12.402 6.50016 13.0003ZM14.0835 13.0003C14.0835 13.5986 13.5985 14.0837 13.0002 14.0837C12.4019 14.0837 11.9168 13.5986 11.9168 13.0003C11.9168 12.402 12.4019 11.917 13.0002 11.917C13.5985 11.917 14.0835 12.402 14.0835 13.0003ZM21.6668 13.0003C21.6668 13.5986 21.1818 14.0837 20.5835 14.0837C19.9852 14.0837 19.5002 13.5986 19.5002 13.0003C19.5002 12.402 19.9852 11.917 20.5835 11.917C21.1818 11.917 21.6668 12.402 21.6668 13.0003Z'
        stroke='#009fe3'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default DotsIcon
