import { IconPropsType } from '../types'

const GoogleIcon = ({ width = 32, height = 32 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M29.074 13.389L28 13.389V13.3337L16 13.3337L16 18.667L23.5354 18.667C22.436 21.7717 19.482 24.0003 16 24.0003C11.582 24.0003 8.00004 20.4183 8.00004 16.0003C8.00004 11.5823 11.582 8.00033 16 8.00033C18.0394 8.00033 19.8947 8.76966 21.3074 10.0263L25.0787 6.25499C22.6974 4.03566 19.512 2.66699 16 2.66699C8.6367 2.66699 2.6667 8.63699 2.6667 16.0003C2.6667 23.3637 8.6367 29.3337 16 29.3337C23.3634 29.3337 29.3334 23.3637 29.3334 16.0003C29.3334 15.1063 29.2414 14.2337 29.074 13.389Z'
        fill='#FFC107'
      />
      <path
        d='M4.20436 9.79433L8.58503 13.007C9.77036 10.0723 12.641 8.00033 16.0004 8.00033C18.0397 8.00033 19.895 8.76966 21.3077 10.0263L25.079 6.25499C22.6977 4.03566 19.5124 2.66699 16.0004 2.66699C10.879 2.66699 6.43769 5.55833 4.20436 9.79433Z'
        fill='#FF3D00'
      />
      <path
        d='M16.0001 29.3333C19.4441 29.3333 22.5734 28.0153 24.9394 25.872L20.8127 22.38C19.4291 23.4322 17.7384 24.0013 16.0001 24C12.5321 24 9.58739 21.7886 8.47805 18.7026L4.13005 22.0526C6.33672 26.3706 10.8181 29.3333 16.0001 29.3333Z'
        fill='#4CAF50'
      />
      <path
        d='M29.0742 13.3888H28.0002V13.3335L16.0002 13.3335L16.0002 18.6668L23.5355 18.6668C23.0097 20.1444 22.0625 21.4356 20.8109 22.3808L20.8129 22.3795L24.9395 25.8715C24.6475 26.1368 29.3335 22.6668 29.3335 16.0002C29.3335 15.1062 29.2415 14.2335 29.0742 13.3888Z'
        fill='#1976D2'
      />
    </svg>
  )
}

export default GoogleIcon
