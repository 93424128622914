import axios, { AxiosRequestConfig } from 'axios'
const urlPrefix = process.env.REACT_APP_SERVER_ENV === 'DEV' ? 'http://localhost:8000' : ''
/**
 * Token service
 * Getting and storing the token in an HTTP-only cookie
 */

const config: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

export type TokenPairType = {
  accessToken: string
  refreshToken: string
  tokenExpires: number
}

export class TokenPrimeService {
  /**
   * Store token to HTTP-only cookie
   */
  public async storeTokenPairToCookie(data: TokenPairType) {
    const response = await axios.post(`${urlPrefix}/api/store-tokens/`, data, config)
    return response?.data
  }

  /**
   * Get token from HTTP-only cookie
   */
  public async getTokenPairToCookie() {
    const response = await axios.get(`${urlPrefix}/api/get-tokens/`, config)
    return response?.data
  }

  public async getBackendUrl() {
    const response = await axios.get(`${urlPrefix}/api/get-backend-url`, config)
    return response?.data
  }
  /**
   * Clear token from HTTP-only cookie
   */
  public async clearTokenPairToCookie() {
    const response = await axios.delete(`${urlPrefix}/api/delete-cookies`, config)
    return response?.data
  }

  // In token.service.ts
  public async refreshTokenPairToCookie() {
    const response = await axios.post('http://localhost:8000/auth/refresh-token', {}, config)
    return response?.data
  }

  // In environment
  public async getEnv() {
    const response = await axios.get(`${urlPrefix}/api/env`, {})
    // To be deleted
    return response?.data
  }
}
