import React from 'react'

// general imports
import { IconPropsType } from '../types'

const CaretIcon = ({ width = 24, height = 24, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7 9.5L12 14.5L17 9.5H7Z' fill={color} />
    </svg>
  )
}

export default CaretIcon
