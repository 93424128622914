import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api/api.types'
import { BeneficiaryDataType } from '../../../../types/initiator.types'
import {
  createBeneficiaryThunk,
  deleteBeneficiariesThunk,
  deleteBeneficiaryThunk,
  getAllBeneficiariesThunk,
  getBeneficiariesByOrgEmailThunk,
  getBeneficiariesThunk,
  updateBeneficiaryThunk,
} from '../thunk/beneficiary.thunk'

export type BeneficiaryState = {
  beneficiaries: BeneficiaryDataType[]
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestResponse: ApiRequestDataType<any>
}

const initialState: BeneficiaryState = {
  beneficiaries: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const beneficiarySlice = createSlice({
  name: 'beneficiarySlice',
  initialState,
  reducers: {
    resetBeneficiaryState: (state) => {
      state.beneficiaries = []
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
    },
    resetBeneficiaryReqRes: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
    },
    setBeneficiary: (state, action: PayloadAction<BeneficiaryDataType[]>) => {
      state.beneficiaries = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBeneficiariesThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(getBeneficiariesThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.beneficiaries = action.payload
      })
      .addCase(getBeneficiariesThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(createBeneficiaryThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(createBeneficiaryThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.beneficiaries = action.payload
      })
      .addCase(createBeneficiaryThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(getAllBeneficiariesThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
        state.isLoading = true
      })
      .addCase(getAllBeneficiariesThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.isSuccess = true
        state.isLoading = false
      })
      .addCase(getAllBeneficiariesThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.isError = true
        state.isLoading = false
      })
      .addCase(deleteBeneficiaryThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
        state.isLoading = true
      })
      .addCase(deleteBeneficiaryThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.isSuccess = true
        state.isLoading = false
        state.beneficiaries = action.payload
      })
      .addCase(deleteBeneficiaryThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.isError = true
        state.isLoading = false
      })
      .addCase(updateBeneficiaryThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
        state.isLoading = true
      })
      .addCase(deleteBeneficiariesThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.isSuccess = true
        state.isLoading = false
        state.beneficiaries = action.payload
      })
      .addCase(deleteBeneficiariesThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.isError = true
        state.isLoading = false
      })
      .addCase(deleteBeneficiariesThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
        state.isLoading = true
      })
      .addCase(updateBeneficiaryThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.beneficiaries = action.payload
        state.isSuccess = true
        state.isLoading = false
      })
      .addCase(updateBeneficiaryThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.isError = true
        state.isLoading = false
      })
      .addCase(getBeneficiariesByOrgEmailThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
        state.isLoading = true
      })
      .addCase(getBeneficiariesByOrgEmailThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.isSuccess = true
        state.isLoading = false
      })
      .addCase(getBeneficiariesByOrgEmailThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.isError = true
        state.isLoading = false
      })
  },
})

export const { resetBeneficiaryState, resetBeneficiaryReqRes } = beneficiarySlice.actions
export default beneficiarySlice.reducer
