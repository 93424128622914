import { publicApiRequest } from '../../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../../lib/utils/addTokenReq'

/**
 * Add the token to the request headers
 */

/**
 * Interface for uploading bulk files
 */
export interface uploadFileBulk {
  token: string
  file: FormData
}
export interface otpCode {
  otpCode: string
  token: string
}
export interface deleteFileTransaction {
  fileId: string
  token: string
}

export interface processFileTransaction extends deleteFileTransaction {
  paymentType: string
  reason: string
}

/**
 * Transaction service
 */
export class TransactionService {
  /**
   * Get all Transactions
   * @returns Promise<any>
   */
  public async getAllTransactions(token: string) {
    return await (await publicApiRequest()).get('/transaction', addTokenToHeaders(token))
  }
  /**
   * Get a specific Transaction
   * @returns Promise<any>
   */
  public async getTransaction(token: string) {
    return await (await publicApiRequest()).get('/transaction', addTokenToHeaders(token))
  }
  /**
   * Get a Transaction history
   * @returns Promise<any>
   */
  public async getTransactionHistory(token: string) {
    return await (await publicApiRequest()).get('/transaction/history', addTokenToHeaders(token))
  }
  /**
   * upload transaction file
   * @returns Promise<any>
   */
  public async uploadTransactionFile(
    data: uploadFileBulk,
    setUploadProgress: (progress: number) => void,
  ) {
    return await (
      await publicApiRequest()
    ).post('/transaction/upload-file', data.file, {
      headers: {
        Authorization: `Bearer ${data.token}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setUploadProgress(progress)
        }
      },
    })
  }
  /**
   * Get Uploaded Transaction Files
   * @returns Promise<any>
   */
  public async getUploadedTransactionFiles(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/uploaded-files', addTokenToHeaders(token))
  }
  /**
   * send initiator otp code
   * @returns Promise<any>
   */
  public async sendInitiatorOtp(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/request-otp', addTokenToHeaders(token))
  }
  /**
   * verify initiator otp code
   * @returns Promise<any>
   */
  public async verifyInitiatorOtp(data: otpCode) {
    return await (
      await publicApiRequest()
    ).patch('/transaction/verify-otp', { otpCode: data.otpCode }, addTokenToHeaders(data.token))
  }
  /**
   * process transaction file
   * @returns Promise<any>
   */
  public async processFileTransaction(data: processFileTransaction) {
    return await (
      await publicApiRequest()
    ).post('/transaction/process-file', data, addTokenToHeaders(data.token))
  }
  /**
   * delete transaction file uploaded
   * @returns Promise<any>
   */
  public async deleteTransactionFile(data: deleteFileTransaction) {
    return await (
      await publicApiRequest()
    ).delete(`/transaction/delete-file/${data.fileId}`, addTokenToHeaders(data.token))
  }
}
