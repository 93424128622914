import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LocalStorageService } from '../../../services/storage/local-storage.service'
import { TokenPrimeService } from '../../../services/token/token.service'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { AuthUserEntityType } from '../../../types/models/user-model.types'
import { refreshTokenThunk } from '../thunks/auth.thunk'

const tokenServicePrime = new TokenPrimeService()
const getUserFromLocalStorage = LocalStorageService.getCurrentUser()
const { accessToken, refreshToken, tokenExpires } =
  (await tokenServicePrime.getTokenPairToCookie()).description || {}

export type AuthState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: AuthUserEntityType | any
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: string
  accessToken: string
  refreshToken: string
  tokenExpires: number
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  hasTokenBeenRefreshed: boolean
}

// Initial state
const initialState: AuthState = {
  user: getUserFromLocalStorage || null,
  isError: false,
  isSuccess: !!getUserFromLocalStorage,
  isLoading: false,
  message: '',
  accessToken,
  refreshToken,
  tokenExpires,
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: {} || null,
  },
  hasTokenBeenRefreshed: false,
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.user = null
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.accessToken = ''
      state.refreshToken = ''
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
      LocalStorageService.clearAuthenticatedState()
      tokenServicePrime.clearTokenPairToCookie()
    },
    resetAuthReqRes: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
    },
    setUser: (state, action: PayloadAction<AuthUserEntityType>) => {
      const { userData, accessToken, refreshToken } = action.payload || {}
      state.user = userData
      state.isSuccess = true
      state.accessToken = accessToken || state.accessToken
      state.refreshToken = refreshToken || state.refreshToken
      state.isLoading = false
    },
    updateVerifyUser: (state, action: PayloadAction<AuthUserEntityType>) => {
      state.user = action.payload
      state.isSuccess = true
      state.isLoading = false
      LocalStorageService.storeCurrentUser(state.user)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshTokenThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(refreshTokenThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.user = action.payload?.userData
        state.refreshToken = action.payload.refreshToken
        state.accessToken = action.payload.accessToken
        state.hasTokenBeenRefreshed = true
      })
      .addCase(refreshTokenThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetAuthReqRes, updateVerifyUser, setUser, resetAuthState } = authSlice.actions
export default authSlice.reducer
