import React from 'react'
import { IconPropsType } from '../types'

const DecreaseIcon = ({ width = 10, height = 5 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66659 5L7.62075 4.04583L5.58742 2.0125L3.92075 3.67917L0.833252 0.5875L1.42075 0L3.92075 2.5L5.58742 0.833333L8.21242 3.45417L9.16659 2.5V5H6.66659Z'
        fill='#D30B0B'
      />
    </svg>
  )
}

export default DecreaseIcon
