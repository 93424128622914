import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import {
  ConfirmationRequestTypeAdmin,
  UserServiceAdmin,
} from '../../../../services/api/admin/user/user.service'

import { getExceptionPayload } from '../../../../lib/utils/get-exception-payload'
import { ApiRequestErrorType } from '../../../../types/api/api.types'
import { RegistrationDataType } from '../../../../types/auth.types'
import { reqBeneficiaryDataType } from '../../../../types/initiator.types'

const authServiceAdmin = new UserServiceAdmin()

export const createUserAccountAdminThunk = createAsyncThunk(
  '/auth/users/add-specified-user',
  async (data: Omit<RegistrationDataType, 'confirmPassword'>, { rejectWithValue }) => {
    try {
      const response = await authServiceAdmin.createUserAccount(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getUsersOrgThunk = createAsyncThunk(
  '/auth/users/get-company-employees',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await authServiceAdmin.getUsersOrg(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteUserOrgThunk = createAsyncThunk(
  '/auth/users/remove-user/',
  async (data: reqBeneficiaryDataType, { rejectWithValue }) => {
    try {
      const response = await authServiceAdmin.deleteUserOrg(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getConfirmationCodeAdminThunk = createAsyncThunk(
  '/auth/request-email-authorization',
  async (data: ConfirmationRequestTypeAdmin, { rejectWithValue }) => {
    try {
      const response = await authServiceAdmin.getConfirmationCodeAdmin(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const emailVerificationCodeAdminThunk = createAsyncThunk(
  '/auth/verify-auth-email',
  async (data: ConfirmationRequestTypeAdmin, { rejectWithValue }) => {
    try {
      const response = await authServiceAdmin.emailVerificationCodeAdmin(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const activateUserAccountThunk = createAsyncThunk(
  '/auth/users/activate-account',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await authServiceAdmin.activateUserAccount(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const resendActivationLinkThunk = createAsyncThunk(
  '/auth/users/resend-activation-link',
  async ({ token, employeeId }: { token: string; employeeId: string }, { rejectWithValue }) => {
    try {
      const response = await authServiceAdmin.resendActivationLink(token, employeeId)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
