import { ConfirmationRequestType } from '../../features/auth/thunks/auth.thunk'
import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { LoginDataType, RegistrationDataType, ResetPasswordDataType } from '../../types/auth.types'
import { UserEntityType } from '../../types/models/user-model.types'

/**
 * Authentication service
 */

export type OtpVerificationType = Pick<RegistrationDataType, 'otpCode' | 'email'>
export type refreshTokenType = { refreshToken: string }

export class AuthenticationService {
  /**
   * Get confirmation code
   * @returns Promise<any>
   */
  public async getConfirmationCode(data: ConfirmationRequestType) {
    return await (
      await publicApiRequest()
    ).post<{
      description: string
      success: boolean
    }>('/auth/request-email-verification', {
      email: data.email,
      name: data.name,
    })
  }

  /**
   * Create a User Account
   * @returns Promise<any>
   */
  public async createUserAccount(data: Omit<RegistrationDataType, 'confirmPassword'>) {
    return await (
      await publicApiRequest()
    ).post<{
      description: string
      success: string
    }>('/auth/create-account', data)
  }

  /**
   * Verify otp after register
   * @returns Promise<any>
   */
  public async emailVerificationCode(data: OtpVerificationType) {
    return await (
      await publicApiRequest()
    ).post<{
      description: string
      success: boolean
    }>('/auth/verify-email-otp', data)
  }

  /**
   * Verify eamil user created by admin
   * @returns Promise<any>
   */
  public async emailVerificationUserCode(data: OtpVerificationType) {
    return await (
      await publicApiRequest()
    ).put<{
      description: string
      success: boolean
    }>('/auth/verify-email', data)
  }

  /**
   * Get email confirmation mainly for register users
   * @returns Promise<any>
   */
  public async resendEmailConfirmationCode(data: ConfirmationRequestType) {
    return await (
      await publicApiRequest()
    ).post<{
      description: string
      success: boolean
    }>('/auth/resend-email-verification', {
      email: data.email,
    })
  }

  /**
   * Login a user
   * @returns Promise<any>
   */
  public async loginuser(data: LoginDataType) {
    return await (
      await publicApiRequest()
    ).post<{
      description: string
      success: string
    }>('/auth/login', data)
  }

  /**
   * Verify otp after login
   * @returns Promise<any>
   */
  public async verifyOTPAfterLogin(data: OtpVerificationType) {
    return await (
      await publicApiRequest()
    ).put<{
      accessToken: string
      refreshToken: string
      userData: UserEntityType
    }>('/auth/otp-login', data)
  }

  /**
   * get otp code to verify email and reset password
   * @returns Promise<any>
   */
  public async forgotPassword(data: ResetPasswordDataType) {
    return await (
      await publicApiRequest()
    ).post<{
      description: string
      success: boolean
    }>('/auth/forgot-password', {
      email: data.email,
    })
  }

  /**
   * Verify otp after login
   * @returns Promise<any>
   */
  public async resetPassword(data: ResetPasswordDataType) {
    return await (
      await publicApiRequest()
    ).put<{
      description: string
      success: string
    }>('/auth/reset-password', data)
  }

  /**
   * Verify reset otp code
   * @returns Promise<any>
   */
  public async verifyResetOtpCode(data: ResetPasswordDataType) {
    return await (
      await publicApiRequest()
    ).post<{
      otpCode: string
    }>('/auth/verify-reset-otp', data)
  }

  /**
   * refresh user token
   * @returns Promise<any>
   */
  public async refreshToken(data: refreshTokenType) {
    return await (
      await publicApiRequest()
    ).put<{
      accessToken: string
      refreshToken: string
      userData: UserEntityType
    }>('/auth/refresh-token', data)
  }
}
