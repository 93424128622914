import React from 'react'

// general imports
import { IconPropsType } from '../types'

const UpArrowIcon = ({ width = 16, height = 16, color = '#E73D1C' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      color={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 12.6663V3.33301'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.33325 7.99967L7.99992 3.33301L12.6666 7.99967'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UpArrowIcon
