import React from 'react'

// general imports
import { IconPropsType } from '../types'

const MailIcon = ({ width = 48, height = 48, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.1665 7.16663H24.4998C25.5607 7.16663 26.5781 7.58805 27.3283 8.3382C28.0784 9.08834 28.4998 10.1058 28.4998 11.1666V23.1666C28.4998 24.2275 28.0784 25.2449 27.3283 25.9951C26.5781 26.7452 25.5607 27.1666 24.4998 27.1666H7.1665C6.10564 27.1666 5.08822 26.7452 4.33808 25.9951C3.58793 25.2449 3.1665 24.2275 3.1665 23.1666V11.1666C3.1665 10.1058 3.58793 9.08834 4.33808 8.3382C5.08822 7.58805 6.10564 7.16663 7.1665 7.16663ZM7.1665 8.49996C6.49984 8.49996 5.91317 8.72663 5.45984 9.12663L15.8332 15.8333L26.2065 9.12663C25.7532 8.72663 25.1665 8.49996 24.4998 8.49996H7.1665ZM15.8332 17.4466L4.67317 10.2066C4.5665 10.5 4.49984 10.8333 4.49984 11.1666V23.1666C4.49984 23.8739 4.78079 24.5521 5.28089 25.0522C5.78098 25.5523 6.45926 25.8333 7.1665 25.8333H24.4998C25.2071 25.8333 25.8854 25.5523 26.3855 25.0522C26.8855 24.5521 27.1665 23.8739 27.1665 23.1666V11.1666C27.1665 10.8333 27.0998 10.5 26.9932 10.2066L15.8332 17.4466Z'
        fill={color}
      />
    </svg>
  )
}

export default MailIcon
