// general imports
import { IconPropsType } from '../types'

const PackageIcon = ({ width = 18, height = 18 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1181_10361)'>
        <path
          d='M6.20819 9.01605H1.60199C0.817193 9.01605 0.179993 8.37885 0.179993 7.59405V2.96985C0.179993 2.18505 0.817193 1.54785 1.60199 1.54785H6.20819C6.99299 1.54785 7.63019 2.18505 7.63019 2.96985V7.59405C7.63019 8.37885 6.99299 9.01605 6.20819 9.01605ZM6.56459 3.32625C6.56459 2.93385 6.24599 2.61525 5.85359 2.61525H1.95839C1.56599 2.61525 1.24739 2.93385 1.24739 3.32625V7.23765C1.24739 7.63005 1.56599 7.94865 1.95839 7.94865H5.85179C6.24419 7.94865 6.56279 7.63005 6.56279 7.23765V3.32625H6.56459Z'
          fill='#036E67'
        />
        <path
          d='M6.20819 17.7587H1.60199C0.817193 17.7587 0.179993 17.1215 0.179993 16.3367V11.8619C0.179993 11.0771 0.817193 10.4399 1.60199 10.4399H6.20819C6.99299 10.4399 7.63019 11.0771 7.63019 11.8619V16.3385C7.63019 17.1233 6.99299 17.7587 6.20819 17.7587ZM6.56459 12.2165C6.56459 11.8241 6.24599 11.5055 5.85359 11.5055H1.95839C1.56599 11.5055 1.24739 11.8241 1.24739 12.2165V15.9803C1.24739 16.3727 1.56599 16.6913 1.95839 16.6913H5.85179C6.24419 16.6913 6.56279 16.3727 6.56279 15.9803V12.2165H6.56459Z'
          fill='#036E67'
        />
        <path
          d='M12.123 9.19429L8.865 5.9381C8.3088 5.3819 8.3088 4.4819 8.865 3.9257L12.1356 0.656896C12.6918 0.100696 13.5918 0.100696 14.148 0.656896L17.4042 3.9131C17.9604 4.4693 17.9604 5.3693 17.4042 5.9255L14.1336 9.19429C13.5792 9.75049 12.6774 9.75049 12.123 9.19429ZM16.398 5.4233C16.6752 5.1461 16.6752 4.6943 16.398 4.4171L13.644 1.6631C13.3668 1.3859 12.915 1.3859 12.6378 1.6631L9.8712 4.4297C9.594 4.7069 9.594 5.1587 9.8712 5.4359L12.6252 8.1899C12.9024 8.4671 13.3542 8.4671 13.6314 8.1899L16.398 5.4233Z'
          fill='#036E67'
        />
        <path
          d='M15.0822 17.7587H10.476C9.68939 17.7587 9.05399 17.1215 9.05399 16.3367V11.8619C9.05399 11.0771 9.69119 10.4399 10.476 10.4399H15.0822C15.867 10.4399 16.5042 11.0771 16.5042 11.8619V16.3385C16.5042 17.1233 15.867 17.7587 15.0822 17.7587ZM15.4368 12.2165C15.4368 11.8241 15.1182 11.5055 14.7258 11.5055H10.8324C10.44 11.5055 10.1214 11.8241 10.1214 12.2165V15.9803C10.1214 16.3727 10.44 16.6913 10.8324 16.6913H14.7276C15.12 16.6913 15.4386 16.3727 15.4386 15.9803L15.4368 12.2165Z'
          fill='#036E67'
        />
      </g>
      <defs>
        <clipPath id='clip0_1181_10361'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PackageIcon
