import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { ProfileType } from '../../../types/profile.type'
import { getUserProfileInfoThunk } from '../thunks/user-profile.thunk'

export type GetUserProfileInfoState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  userProfile: ProfileType
}

export const initialState: GetUserProfileInfoState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  userProfile: {
    userId: '',
    email: '',
    name: '',
    phoneNumber: '',
    organizationId: '',
    country: '',
    city: '',
    streetAddress: '',
    language: '',
    profilePicture: '',
  },
}

const getUserProfileInfoSlice = createSlice({
  name: 'getUserProfileInfoSlice',
  initialState: initialState,
  reducers: {
    resetUserProfileInfoState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileInfoThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getUserProfileInfoThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.userProfile = action.payload
      })
      .addCase(getUserProfileInfoThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetUserProfileInfoState } = getUserProfileInfoSlice.actions
export default getUserProfileInfoSlice.reducer
