// general imports
import { IconPropsType } from '../types'

const AddPackageIcon = ({ width = 45, height = 44, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 45 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M35.3333 5.5H5.99998C3.96498 5.5 2.35165 7.13167 2.35165 9.16667L2.33331 31.1667C2.33331 33.2017 3.96498 34.8333 5.99998 34.8333H24.3333V31.1667H5.99998V20.1667H39V9.16667C39 7.13167 37.3683 5.5 35.3333 5.5ZM35.3333 12.8333H5.99998V9.16667H35.3333V12.8333ZM42.6666 29.3333V33H37.1666V38.5H33.5V33H28V29.3333H33.5V23.8333H37.1666V29.3333H42.6666Z'
        fill={color}
      />
    </svg>
  )
}

export default AddPackageIcon
