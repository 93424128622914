import React from 'react'

import { IconPropsType } from '../types'

const NotificationIcon = ({ width = 24, height = 24, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_726_291)'>
        <path
          d='M11.9996 22.8533C12.4196 22.8437 12.8226 22.6858 13.1374 22.4076C13.4521 22.1295 13.6584 21.7489 13.7196 21.3333H10.213C10.2759 21.7602 10.4918 22.1497 10.8205 22.4293C11.1491 22.7089 11.5682 22.8596 11.9996 22.8533V22.8533Z'
          fill={color}
        />
        <path
          d='M21.8996 18.7534L21.6729 18.5534C21.0299 17.9804 20.467 17.3234 19.9996 16.6C19.489 15.6017 19.1831 14.5115 19.0996 13.3934V10.1C19.0969 9.70001 19.0612 9.30089 18.9929 8.90671C17.8636 8.67461 16.8493 8.05939 16.1216 7.16523C15.3938 6.27108 14.9975 5.15291 14.9996 4.00005V3.58005C14.3035 3.23751 13.5557 3.01225 12.7862 2.91338V2.07338C12.7862 1.83734 12.6925 1.61096 12.5256 1.44406C12.3586 1.27715 12.1323 1.18338 11.8962 1.18338C11.6602 1.18338 11.4338 1.27715 11.2669 1.44406C11.1 1.61096 11.0062 1.83734 11.0062 2.07338V2.94671C9.28338 3.18975 7.70661 4.04788 6.56705 5.36267C5.42748 6.67746 4.80205 8.36014 4.80623 10.1V13.3934C4.72273 14.5115 4.41675 15.6017 3.90623 16.6C3.44682 17.3216 2.89303 17.9785 2.25956 18.5534L2.0329 18.7534V20.6334H21.8996V18.7534Z'
          fill={color}
        />
        <path
          d='M19.9996 7.33338C21.8405 7.33338 23.3329 5.841 23.3329 4.00005C23.3329 2.1591 21.8405 0.666718 19.9996 0.666718C18.1586 0.666718 16.6663 2.1591 16.6663 4.00005C16.6663 5.841 18.1586 7.33338 19.9996 7.33338Z'
          fill='#009fe3'
        />
      </g>
      <defs>
        <clipPath id='clip0_726_291'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotificationIcon
