import React from 'react'
import { IconPropsType } from '../types'

const CreditCardIcon = ({ width = 54, height = 39, color = '#007fb6' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 54 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M36.9999 26.1668C36.4695 26.1668 35.9608 26.3775 35.5857 26.7526C35.2106 27.1277 34.9999 27.6364 34.9999 28.1668C34.9999 28.6973 35.2106 29.206 35.5857 29.581C35.9608 29.9561 36.4695 30.1668 36.9999 30.1668H43.6666C44.197 30.1668 44.7057 29.9561 45.0808 29.581C45.4559 29.206 45.6666 28.6973 45.6666 28.1668C45.6666 27.6364 45.4559 27.1277 45.0808 26.7526C44.7057 26.3775 44.197 26.1668 43.6666 26.1668H36.9999ZM0.333252 9.50016C0.333252 7.20162 1.24634 4.99722 2.87166 3.3719C4.49697 1.74659 6.70138 0.833496 8.99992 0.833496H44.9999C46.138 0.833496 47.265 1.05767 48.3165 1.49321C49.368 1.92875 50.3234 2.56713 51.1282 3.3719C51.9329 4.17668 52.5713 5.13208 53.0069 6.18357C53.4424 7.23506 53.6666 8.36204 53.6666 9.50016V29.5002C53.6666 30.6383 53.4424 31.7653 53.0069 32.8167C52.5713 33.8682 51.9329 34.8236 51.1282 35.6284C50.3234 36.4332 49.368 37.0716 48.3165 37.5071C47.265 37.9427 46.138 38.1668 44.9999 38.1668H8.99992C6.70138 38.1668 4.49697 37.2537 2.87166 35.6284C1.24634 34.0031 0.333252 31.7987 0.333252 29.5002V9.50016ZM49.6666 12.8335V9.50016C49.6666 8.26249 49.1749 7.0755 48.2997 6.20033C47.4246 5.32516 46.2376 4.8335 44.9999 4.8335H8.99992C7.76224 4.8335 6.57526 5.32516 5.70009 6.20033C4.82492 7.0755 4.33325 8.26249 4.33325 9.50016V12.8335H49.6666ZM4.33325 16.8335V29.5002C4.33325 32.0762 6.42392 34.1668 8.99992 34.1668H44.9999C46.2376 34.1668 47.4246 33.6752 48.2997 32.8C49.1749 31.9248 49.6666 30.7378 49.6666 29.5002V16.8335H4.33325Z'
        fill={color}
      />
    </svg>
  )
}

export default CreditCardIcon
