import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import {
  confirmUpdateUserEmailTypes,
  confirmUpdateUserPhoneNumberTypes,
} from '../../../services/api'
import { SettingsService, uploadOrgInfoData } from '../../../services/api/settings.service'
import { ApiRequestErrorType } from '../../../types/api/api.types'
import {
  ServiceProvider,
  changePasswordType,
  otpConfirmationType,
} from '../../../types/settings.type'

const settingsService = new SettingsService()

export const getServiceProvidersThunk = createAsyncThunk(
  '/transaction/get-service-providers',
  async (token: string) => {
    try {
      const response = await settingsService.getServiceProviders(token)
      return response
    } catch (err) {
      return isRejectedWithValue(getExceptionPayload(err) as ApiRequestErrorType)
    }
  },
)

export const addServiceProviderThunk = createAsyncThunk(
  '/transaction/add-service-provider',
  async ({ token, data }: { token: string; data: ServiceProvider }) => {
    try {
      const response = await settingsService.addServiceProvider(data, token)
      return response
    } catch (err) {
      return isRejectedWithValue(getExceptionPayload(err) as ApiRequestErrorType)
    }
  },
)

export const sendOtpThunk = createAsyncThunk(
  '/transaction/confirm-service-provider',
  async ({ token, data }: { token: string; data: otpConfirmationType }) => {
    try {
      const response = await settingsService.sendOtp(data, token)
      return response.data
    } catch (err) {
      return isRejectedWithValue(getExceptionPayload(err) as ApiRequestErrorType)
    }
  },
)
export const changePasswordThunk = createAsyncThunk(
  '/auth/change-password',
  async ({ token, data }: { token: string; data: changePasswordType }) => {
    try {
      const response = await settingsService.changePassword(data, token)
      return response.data
    } catch (err) {
      return isRejectedWithValue(getExceptionPayload(err) as ApiRequestErrorType)
    }
  },
)

export const editOrgSettingsThunk = createAsyncThunk(
  '/profile/edit-organisation',
  async (data: uploadOrgInfoData, { rejectWithValue }) => {
    try {
      const response = await settingsService.editOrgSettings(data)
      console.log('edit request', response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteProviderAccountThunk = createAsyncThunk(
  '/transaction/delete-service-provider',
  async ({ providerId, token }: { providerId: string; token: string }, { rejectWithValue }) => {
    try {
      const response = await settingsService.deleteProviderAccount(providerId, token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getOrgProfileThunk = createAsyncThunk(
  '/profile/get-organisation-profile',
  async (token: string) => {
    try {
      const response = await settingsService.getOrgProfile(token)
      return response
    } catch (err) {
      return isRejectedWithValue(getExceptionPayload(err) as ApiRequestErrorType)
    }
  },
)
export const updateEmailThunk = createAsyncThunk(
  'settings/updateOrgEmail',
  async (payload: { email: string; token: string }, { rejectWithValue }) => {
    try {
      const { email, token } = payload
      const response = await settingsService.updateOrgEmail(email, token)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const confirmUpdateOrgEmailThunk = createAsyncThunk(
  '/auth/organisations/confirm-email-update',
  async (data: confirmUpdateUserEmailTypes, { rejectWithValue }) => {
    try {
      const response = await settingsService.confirmEditEmail(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const updatePhoneNumberThunk = createAsyncThunk(
  'settings/updateOrgPhoneNumber',
  async (payload: { phoneNumber: string; token: string }, { rejectWithValue }) => {
    try {
      const { phoneNumber, token } = payload
      const response = await settingsService.updateOrgPhoneNumber(phoneNumber, token)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const confirmUpdateOrgPhoneNumberThunk = createAsyncThunk(
  '/auth/organisations/confirm-phone-number-update',
  async (data: confirmUpdateUserPhoneNumberTypes, { rejectWithValue }) => {
    try {
      const response = await settingsService.confirmEditPhoneNumber(data)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
