import React from 'react'

// general imports
import { IconPropsType } from '../types'

const RadioButtonIcon = ({ width = 24, height = 24, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12.5' r='11.25' fill={color} stroke='#009fe3' strokeWidth='1.5' />
    </svg>
  )
}

export default RadioButtonIcon
