// general imports
import { IconPropsType } from '../types'

const DownDropdownArrowIcon = ({
  width = 12,
  height = 8,
  color = 'currentColor',
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.72619 7.7L0 1.53333L1.14524 0.299999L5.72619 5.23333L10.3071 0.299999L11.4524 1.53333L5.72619 7.7Z'
        fill={color}
      />
    </svg>
  )
}

export default DownDropdownArrowIcon
