function GreenTickRoundIcon() {
  return (
    <svg width='94' height='94' viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.4167 47L43.0834 58.75L62.6667 31.3334M47 86.1667C68.6318 86.1667 86.1667 68.6318 86.1667 47C86.1667 25.3683 68.6318 7.83337 47 7.83337C25.3683 7.83337 7.83337 25.3683 7.83337 47C7.83337 68.6318 25.3683 86.1667 47 86.1667Z'
        stroke='#009fe3'
        strokeWidth='5'
      />
    </svg>
  )
}

export default GreenTickRoundIcon
