import { createSlice } from '@reduxjs/toolkit'
// import { OrganizationResponseTypes } from '../../../types/organization.type'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { suspendOrganisationThunk } from '../thunk/organisation.thunk'
// import { OrganizationResponseTypes } from '../../../types/organisation.type'

export type suspendOrganisationState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
}

export const initialState: suspendOrganisationState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const suspendOrganisationSlice = createSlice({
  name: 'suspendOrganisationSlice',
  initialState: initialState,
  reducers: {
    resetSuspendOrganisationState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
      // (state.organizations = {} as OrganizationResponseTypes)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(suspendOrganisationThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(suspendOrganisationThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description

        // state.requestResponse.data = action.payload.description
      })
      .addCase(suspendOrganisationThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetSuspendOrganisationState } = suspendOrganisationSlice.actions
export default suspendOrganisationSlice.reducer
