function RejectedIcon() {
  return (
    <svg width='23' height='19' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.833374 15.9167H19.1667L10 0.0833282L0.833374 15.9167ZM10.8334 13.4167H9.16671V11.75H10.8334V13.4167ZM10.8334 10.0833H9.16671V6.74999H10.8334V10.0833Z'
        fill='#FF5247'
      />
    </svg>
  )
}

export default RejectedIcon
