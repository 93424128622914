import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api/api.types'
import { TransactionPropType } from '../../../../types/molecules/transaction-table.molecule'
import { UploadedFilePropType } from '../../../../types/molecules/uploaded-files-table.molecule.type'
import {
  deleteTransactionFileThunk,
  getUploadedTransactionFilesThunk,
  processFileTransactionThunk,
  sendInitiatorOtpThunk,
  uploadTransactionFileThunk,
  verifyInitiatorOtpThunk,
} from '../thunk/transaction.thunk'

export type getAllTransactionsState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  transactions: TransactionPropType[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadedTransactionFiles: UploadedFilePropType[]
  selectedTransactionId: string
}

export const initialState: getAllTransactionsState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  transactions: [],
  uploadedTransactionFiles: [],
  selectedTransactionId: '',
}

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState: initialState,
  reducers: {
    resetTransaction: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
    },
    setSelectedTransactionId: (state, action: PayloadAction<string>) => {
      state.selectedTransactionId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUploadedTransactionFilesThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getUploadedTransactionFilesThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.uploadedTransactionFiles = action.payload
      })
      .addCase(getUploadedTransactionFilesThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(sendInitiatorOtpThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(sendInitiatorOtpThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('otp code', action.payload)
      })
      .addCase(sendInitiatorOtpThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        console.log('otp send failed', action.payload)
      })
      .addCase(verifyInitiatorOtpThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(verifyInitiatorOtpThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('otp code verify', action.payload)
      })
      .addCase(verifyInitiatorOtpThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        console.log('otp code verification failed', action.payload)
      })
      .addCase(processFileTransactionThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(processFileTransactionThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('transaction success process', action.payload)
      })
      .addCase(processFileTransactionThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        console.log('transaction process failed', action.payload)
      })
      .addCase(uploadTransactionFileThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(uploadTransactionFileThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('upload success process', action.payload)
      })
      .addCase(uploadTransactionFileThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        console.log('upload process failed', action.payload)
      })
      .addCase(deleteTransactionFileThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(deleteTransactionFileThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        console.log('delete success process', action.payload)
      })
      .addCase(deleteTransactionFileThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        console.log('delete process failed', action.payload)
      })
  },
})

export const { resetTransaction, setSelectedTransactionId } = transactionSlice.actions
export default transactionSlice.reducer
