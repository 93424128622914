import React from 'react'
import { IconPropsType } from '../types'

const DownwardDecreasingIcon = ({ width = 16, height = 16 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.5 1.91L14.09 0.5L2.5 12.09V5.5H0.5V15.5H10.5V13.5H3.91L15.5 1.91Z'
        fill='#717171'
      />
    </svg>
  )
}

export default DownwardDecreasingIcon
