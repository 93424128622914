import { PhoneNumberType } from '../../features/phone-number/thunks/phone-number.thunk'
import { APP_CONSTANTS } from '../../repository/constants/constants'
import { UserEntityType } from '../../types/models/user-model.types'

/**
 * Local storage service
 */

export function set(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value)
  } catch {
    console.warn('Error reading from local storage')
  }
}

export function get(key: string) {
  try {
    const item = window.localStorage.getItem(key)
    return item
  } catch {
    return null
  }
}

export const LocalStorageService = {
  getPreviousRoute: () => {
    const previousRoute = get(APP_CONSTANTS.STORAGE_KEY.PREVIOUS_ROUTE)
    return {
      previousRoute,
    }
  },

  clearAuthenticatedState: () => {
    try {
      localStorage.removeItem(APP_CONSTANTS.STORAGE_KEY.CURRENT_USER)
      localStorage.removeItem(APP_CONSTANTS.STORAGE_KEY.USER_AUTHENTICATED)
    } catch (e) {
      console.warn('Error removing from local storage')
    }
  },
  saveUserAuthenticatedState(userData: UserEntityType) {
    set(APP_CONSTANTS.STORAGE_KEY.CURRENT_USER, JSON.stringify(userData))
    set(APP_CONSTANTS.STORAGE_KEY.USER_AUTHENTICATED, 'true')
  },

  storePhoneCodes(phoneCodes: PhoneNumberType[]) {
    set(APP_CONSTANTS.STORAGE_KEY.PHONE_CODES, JSON.stringify(phoneCodes))
  },

  getStoredPhoneCodes: () => {
    return JSON.parse(get(APP_CONSTANTS.STORAGE_KEY.PHONE_CODES) as string)
  },

  storeCurrentUser: (user: UserEntityType) => {
    set(APP_CONSTANTS.STORAGE_KEY.CURRENT_USER, JSON.stringify(user))
  },

  getCurrentUser: () => {
    const _user = get(APP_CONSTANTS.STORAGE_KEY.CURRENT_USER)
    try {
      const user = JSON.parse(_user as string)
      return user
    } catch {
      return null
    }
  },
}
