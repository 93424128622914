import { publicApiRequest } from '../../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../../lib/utils/addTokenReq'
import {
  GetOrganisationsResponseTypes,
  OrganizationResponseTypes,
  organisationProviderRequestTypes,
  organisationUserRequestType,
} from '../../../types/organisation.type'

/**
 * Organisation service
 */

export interface organisationRequestType {
  organisationId: string
  token: string
}

export type EditOrganisationProfileInfoType = Omit<
  OrganizationResponseTypes,
  | 'organisationId'
  | 'email'
  | 'phoneNumber'
  | 'status'
  | 'coverPicture'
  | 'profilePicture'
  | 'updatedAt'
  | 'createdAt'
>

export interface uploadOrganisationProfileInfoData {
  jsonData: EditOrganisationProfileInfoType
  imageData: string
  coverData: string
  token: string
}
export interface organisationRequestType {
  organisationId: string
  token: string
}
export interface refillRequestType {
  refillId: string
  token: string
}

export interface extendedOrganisationRequestType extends organisationRequestType {
  email: string
}

export class OrganisationService {
  /**
   * Get organisation Information
   * @returns Promise<any>
   */

  public async getAllOrganisations(token: string) {
    return await (await publicApiRequest()).get('/profile/organisations', addTokenToHeaders(token))
  }

  public async deleteOrganisation(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).delete(
      `/auth/organisations/delete-organisation/${data.organisationId}`,
      addTokenToHeaders(data.token),
    )
  }
  public async suspendOrganisation(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).patch(
      `/auth/organisations/suspend-organisation/${data.organisationId}`,
      addTokenToHeaders(data.token),
    )
  }
  public async reactivateOrganisation(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).patch(
      `/auth/organisations/reactivate-organisation/${data.organisationId}`,
      addTokenToHeaders(data.token),
    )
  }

  public async singleOrganisationDetails(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).get(`/profile/organisation/${data.organisationId}`, addTokenToHeaders(data.token))
  }

  public async getOrganisationUsers(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).get(
      `/auth/users/get-organisation-users/${data.organisationId}`,
      addTokenToHeaders(data.token),
    )
  }
  public async getOrganisationNumberOfUsers(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).get(
      `/auth/organisations/number-of-users/${data.organisationId}`,
      addTokenToHeaders(data.token),
    )
  }
  public async getOrganisationOwner(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).get(`/profile/owner/${data.organisationId}`, addTokenToHeaders(data.token))
  }
  public async getOrganisationWalletDetails(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).get(`/transaction/org-wallet-details/${data.organisationId}`, addTokenToHeaders(data.token))
  }
  public async getOrganisationServiceProviders(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).get(
      `/transaction/get-org-service-providers/${data.organisationId}`,
      addTokenToHeaders(data.token),
    )
  }
  public async deleteOrganisationServiceProviders(data: organisationProviderRequestTypes) {
    return await (
      await publicApiRequest()
    ).delete(
      `/transaction/delete-service-provider/${data.providerId}`,
      addTokenToHeaders(data.token),
    )
  }
  public async deleteOrganisationUser(data: organisationUserRequestType) {
    return await (
      await publicApiRequest()
    ).delete(` /auth/users/remove-org-user/${data.userId}`, addTokenToHeaders(data.token))
  }

  public async editOrganisationCoverPhoto(data: uploadOrganisationProfileInfoData) {
    const formData = new FormData()

    formData.append('jsonData', JSON.stringify(data.jsonData))
    formData.append('image', data.imageData)
    formData.append('cover', data.coverData)
    return await (
      await publicApiRequest()
    ).patch('/profile/edit-organisation', formData, addTokenToHeaders(data.token))
  }
  public async getOrganisationTransactions(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).get(
      `/transaction/${data.organisationId}`,

      addTokenToHeaders(data.token),
    )
  }

  public async getUnapprovedOrganisations(token: string) {
    return await (
      await publicApiRequest()
    ).get('/auth/organisations/organisation-request', addTokenToHeaders(token ?? ''))
  }
  public async deleteUnapprovedOrganisations(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).delete(
      `/auth/organisations/delete-organisation-request/${data.organisationId}`,
      addTokenToHeaders(data.token ?? ''),
    )
  }
  public async approvedOrganisation(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).put(
      `/auth/organisations/approve-organisation/${data.organisationId}`,
      data,
      addTokenToHeaders(data.token ?? ''),
    )
  }
  public async getOrganisations(token: string) {
    return await (
      await publicApiRequest()
    ).get<GetOrganisationsResponseTypes>('/auth/organisations', addTokenToHeaders(token))
  }
}
