import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../../../lib/utils/addTokenReq'

/**
 * manage user target adm service
 */
export class TargetService {
  /**
   * set target
   * @returns Promise<any>
   */
  public async setTarget(data: { target: string; token: string }) {
    return await (
      await publicApiRequest()
    ).patch('/transaction/set-target', data, addTokenToHeaders(data.token ?? ''))
  }

  /**
   * get target
   * @returns Promise<any>
   */
  public async getTarget(token: string, date: string) {
    return await (
      await publicApiRequest()
    ).get(`/transaction/get-target/${date}`, addTokenToHeaders(token))
  }
}
