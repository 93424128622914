import React from 'react'
import { IconPropsType } from '../types'

const TickIcon = ({ width = 10, height = 8, color = '#4777BE' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.125 7.56934L0.0927734 4.53223L0.532227 4.09277L3.125 6.68066L9.46777 0.342773L9.90723 0.782227L3.125 7.56934Z'
        fill={color}
      />
    </svg>
  )
}

export default TickIcon
