/* eslint-disable */

import { ApiRequestErrorType } from '../../types/api/api.types'
import { InternalServerError } from '../hooks/axios-instance'

export const getExceptionPayload = (
  ex: any,
):
  | ApiRequestErrorType
  | {
      message: string
      code: number
    } => {
  console.log('====================================')
  console.log({ ex })
  console.log('====================================')

  if ((ex as { code: string }).code === 'ERR_NETWORK') {
    return {
      message: 'Network Error. Please check your internet connection and try again.',
      code: -501,
    }
  }

  if (typeof ex !== 'object' || !ex) {
    return InternalServerError
  }

  const typeofException = ex as ApiRequestErrorType

  if (
    (ex.hasOwnProperty('response') &&
      typeof typeofException.response.data.description === 'string') ||
    typeof typeofException.response.data.message === 'string'
  ) {
    return {
      message:
        typeofException.response.data.message ??
        typeofException.response.data.description ??
        ex.response.data ??
        ex.response.data.message,
      code: typeofException.response.status,
    }
  }

  return InternalServerError
}
