import { createSlice } from '@reduxjs/toolkit'
// import { OrganizationResponseTypes } from '../../../types/organization.type'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { deleteOrganisationThunk } from '../thunk/organisation.thunk'
// import { OrganizationResponseTypes } from '../../../types/organisation.type'

export type deleteOrganisationState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
}

export const initialState: deleteOrganisationState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
}

const deleteOrganisationSlice = createSlice({
  name: 'deleteOrganisationSlice',
  initialState: initialState,
  reducers: {
    resetDeleteOrganisationState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
      // (state.organizations = {} as OrganizationResponseTypes)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteOrganisationThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(deleteOrganisationThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description

        // state.requestResponse.data = action.payload.description
      })
      .addCase(deleteOrganisationThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetDeleteOrganisationState } = deleteOrganisationSlice.actions
export default deleteOrganisationSlice.reducer
