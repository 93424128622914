import React from 'react'
import { IconPropsType } from '../types'

const FilterIcon = ({ width = 18, height = 12 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 12C7.71667 12 7.47933 11.904 7.288 11.712C7.096 11.5207 7 11.2833 7 11C7 10.7167 7.096 10.4793 7.288 10.288C7.47933 10.096 7.71667 10 8 10H10C10.2833 10 10.521 10.096 10.713 10.288C10.9043 10.4793 11 10.7167 11 11C11 11.2833 10.9043 11.5207 10.713 11.712C10.521 11.904 10.2833 12 10 12H8ZM1 2C0.716667 2 0.479333 1.90433 0.288 1.713C0.0960001 1.521 0 1.28333 0 1C0 0.716667 0.0960001 0.479 0.288 0.287C0.479333 0.0956668 0.716667 0 1 0H17C17.2833 0 17.5207 0.0956668 17.712 0.287C17.904 0.479 18 0.716667 18 1C18 1.28333 17.904 1.521 17.712 1.713C17.5207 1.90433 17.2833 2 17 2H1ZM4 7C3.71667 7 3.479 6.904 3.287 6.712C3.09567 6.52067 3 6.28333 3 6C3 5.71667 3.09567 5.479 3.287 5.287C3.479 5.09567 3.71667 5 4 5H14C14.2833 5 14.5207 5.09567 14.712 5.287C14.904 5.479 15 5.71667 15 6C15 6.28333 14.904 6.52067 14.712 6.712C14.5207 6.904 14.2833 7 14 7H4Z'
        fill='#202325'
      />
    </svg>
  )
}

export default FilterIcon
