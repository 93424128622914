import React from 'react'
import { IconPropsType } from '../types'

const CashIcon = ({ width = 56, height = 41, color = '#007fb6' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 56 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33 23C30.9167 23 29.1458 22.2708 27.6875 20.8125C26.2292 19.3542 25.5 17.5833 25.5 15.5C25.5 13.4167 26.2292 11.6458 27.6875 10.1875C29.1458 8.72917 30.9167 8 33 8C35.0833 8 36.8542 8.72917 38.3125 10.1875C39.7708 11.6458 40.5 13.4167 40.5 15.5C40.5 17.5833 39.7708 19.3542 38.3125 20.8125C36.8542 22.2708 35.0833 23 33 23ZM15.5 30.5C14.125 30.5 12.9475 30.01 11.9675 29.03C10.9875 28.05 10.4983 26.8733 10.5 25.5V5.5C10.5 4.125 10.99 2.9475 11.97 1.9675C12.95 0.987504 14.1267 0.498338 15.5 0.500004H50.5C51.875 0.500004 53.0525 0.990004 54.0325 1.97C55.0125 2.95 55.5017 4.12667 55.5 5.5V25.5C55.5 26.875 55.01 28.0525 54.03 29.0325C53.05 30.0125 51.8733 30.5017 50.5 30.5H15.5ZM20.5 25.5H45.5C45.5 24.125 45.99 22.9475 46.97 21.9675C47.95 20.9875 49.1267 20.4983 50.5 20.5V10.5C49.125 10.5 47.9475 10.01 46.9675 9.03C45.9875 8.05 45.4983 6.87334 45.5 5.5H20.5C20.5 6.875 20.01 8.0525 19.03 9.0325C18.05 10.0125 16.8733 10.5017 15.5 10.5V20.5C16.875 20.5 18.0525 20.99 19.0325 21.97C20.0125 22.95 20.5017 24.1267 20.5 25.5ZM5.5 40.5C4.125 40.5 2.9475 40.01 1.9675 39.03C0.987504 38.05 0.498338 36.8733 0.500004 35.5V10.5C0.500004 9.79167 0.740004 9.1975 1.22 8.7175C1.7 8.2375 2.29334 7.99834 3 8C3.70834 8 4.3025 8.24 4.7825 8.72C5.2625 9.2 5.50167 9.79334 5.5 10.5V35.5H45.5C46.2083 35.5 46.8025 35.74 47.2825 36.22C47.7625 36.7 48.0017 37.2933 48 38C48 38.7083 47.76 39.3025 47.28 39.7825C46.8 40.2625 46.2067 40.5017 45.5 40.5H5.5Z'
        fill={color}
      />
    </svg>
  )
}

export default CashIcon
