import React from 'react'

// general imports
import { IconPropsType } from '../types'

const EyeIcon = ({
  width = 32,
  height = 32,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={`${stroke}`}
      {...props}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.66659 7.02668L4.37325 5.33334L26.6666 27.6267L24.9733 29.3333L20.8666 25.2267C19.3333 25.7333 17.7066 26 15.9999 26C9.33325 26 3.63992 21.8533 1.33325 16C2.25325 13.6533 3.71992 11.5867 5.58659 9.94668L2.66659 7.02668ZM15.9999 12C17.0608 12 18.0782 12.4214 18.8283 13.1716C19.5785 13.9217 19.9999 14.9391 19.9999 16C20.0006 16.4541 19.9239 16.905 19.7733 17.3333L14.6666 12.2267C15.0949 12.076 15.5458 11.9993 15.9999 12ZM15.9999 6.00001C22.6666 6.00001 28.3599 10.1467 30.6666 16C29.5784 18.7643 27.7292 21.1636 25.3333 22.92L23.4399 21.0133C25.2838 19.7379 26.7709 18.0121 27.7599 16C26.682 13.7999 25.0085 11.9464 22.9296 10.65C20.8507 9.35366 18.4499 8.66652 15.9999 8.66668C14.5466 8.66668 13.1199 8.90668 11.7866 9.33334L9.73325 7.29334C11.6533 6.46668 13.7733 6.00001 15.9999 6.00001ZM4.23992 16C5.31784 18.2001 6.99135 20.0537 9.07022 21.35C11.1491 22.6464 13.55 23.3335 15.9999 23.3333C16.9199 23.3333 17.8266 23.24 18.6666 23.0533L15.6266 20C14.6988 19.9006 13.833 19.4865 13.1732 18.8267C12.5134 18.1669 12.0994 17.3011 11.9999 16.3733L7.46659 11.8267C6.14659 12.96 5.03992 14.3733 4.23992 16Z'
        fill={color}
      />
    </svg>
  )
}

export default EyeIcon
