import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import { ExpensesServiceAdmin } from '../../../services/api/admin/expenses/expenses.service'
import { ApiRequestErrorType } from '../../../types/api/api.types'

const expensesService = new ExpensesServiceAdmin()

export const getExpensesThunk = createAsyncThunk('/transaction/expenses', async (token: string) => {
  try {
    const response = await expensesService.getExpenses(token)
    return response.data
  } catch (err) {
    return isRejectedWithValue(getExceptionPayload(err) as ApiRequestErrorType)
  }
})
