import * as React from 'react'

// general imports
import { IconPropsType } from '../types'

function ArrowRightIcon({ width = 24, height = 24 }: IconPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <path
          d='M3 12L22 12'
          stroke='#9F9F9F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 5L22 12L15 19'
          stroke='#9F9F9F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export default ArrowRightIcon
