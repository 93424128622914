import React from 'react'
import { IconPropsType } from '../types'

const StopIcon = ({ width = 10, height = 10 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 0C7.75 0 10 2.25 10 5C10 7.75 7.75 10 5 10C2.25 10 0 7.75 0 5C0 2.25 2.25 0 5 0ZM5 1C4.05 1 3.2 1.3 2.55 1.85L8.15 7.45C8.65 6.75 9 5.9 9 5C9 2.8 7.2 1 5 1ZM7.45 8.15L1.85 2.55C1.3 3.2 1 4.05 1 5C1 7.2 2.8 9 5 9C5.95 9 6.8 8.7 7.45 8.15Z'
        fill='#FF4E4E'
      />
    </svg>
  )
}

export default StopIcon
