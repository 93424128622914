// general imports
import { IconPropsType } from '../types'

const DownwardArrowIcon = ({ width = 15, height = 14 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.1667 7.00008L11.3442 6.17758L8.08332 9.43258V2.33341H6.91666L6.91666 9.43258L3.66166 6.17175L2.83332 7.00008L7.49999 11.6667L12.1667 7.00008Z'
        fill='#D30B0B'
      />
    </svg>
  )
}

export default DownwardArrowIcon
