import { ReactElement, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../lib/hooks/index'
import { APP_CONSTANTS } from '../../repository/constants/constants'
import { LocalStorageService } from '../../services/storage/local-storage.service'
import { TokenPrimeService } from '../../services/token/token.service'
import { AppRouteType, GuardFunc } from '../../types/router.types'

function GuardController(props: { route: AppRouteType }) {
  const navigate = useNavigate()

  useEffect(() => {
    async function checkTokenExpiration() {
      const tokenServicePrime = new TokenPrimeService()
      const expirationTimeMs = Date.now() + 24 * 60 * 60 * 1000
      const tokenData = await tokenServicePrime.getTokenPairToCookie()
      const tokenExpires = tokenData?.description?.tokenExpires.toString()
      const timeUntilExpiration = expirationTimeMs - parseInt(tokenExpires)

      if (timeUntilExpiration < 0) {
        LocalStorageService.clearAuthenticatedState()
        tokenServicePrime.clearTokenPairToCookie()
        navigate('/login')
      }
    }

    checkTokenExpiration()
  }, [navigate])

  const { route } = props
  // eslint-disable-next-line
  let Component: any = <route.component />

  if (
    location.pathname !== APP_CONSTANTS.APP_PATHS.LOGIN &&
    location.pathname !== APP_CONSTANTS.APP_PATHS.REGISTER &&
    location.pathname !== APP_CONSTANTS.APP_PATHS.FORGOT_PASS &&
    location.pathname !== APP_CONSTANTS.APP_PATHS.VERIFY_PIN
  ) {
    localStorage.setItem(APP_CONSTANTS.STORAGE_KEY.PREVIOUS_ROUTE, location.pathname)
  }

  if (route.guards && route.guards.length > 0) {
    const caughtByGuard = 'guard-caught'
    try {
      route.guards.forEach((guard: GuardFunc) => {
        if (typeof guard === 'function') {
          const g = guard({
            state: useAppSelector((state) => state),
            location: useLocation(),
          })
          if (typeof g === 'string' && window.location.pathname !== g) {
            Component = <Navigate replace to={g} />
          } else if (typeof g === 'string' && window.location.pathname === g) {
            return Component
          } else if (typeof g === 'boolean' && !g) {
            Component = <Navigate replace to={APP_CONSTANTS.APP_PATHS.LOGIN} />
          }
        }
      })
      // eslint-disable-next-line
    } catch (e: any) {
      if (e?.message !== caughtByGuard) {
        throw e
      }
    }
  }

  // eslint-disable-next-line
  return Component as ReactElement<any, any>
}

export default GuardController
