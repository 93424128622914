import React from 'react'
import { IconPropsType } from '../types'

const SuspendIcon = ({ width = 20, height = 20 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.16669 7.49998C9.16669 8.88331 8.05002 9.99998 6.66669 9.99998C5.28335 9.99998 4.16669 8.88331 4.16669 7.49998C4.16669 6.11665 5.28335 4.99998 6.66669 4.99998C8.05002 4.99998 9.16669 6.11665 9.16669 7.49998ZM11.6667 16.6666H1.66669V15C1.66669 13.1583 3.90835 11.6666 6.66669 11.6666C9.42502 11.6666 11.6667 13.1583 11.6667 15M5.83335 7.49998C5.83335 7.95831 6.20835 8.33331 6.66669 8.33331C7.12502 8.33331 7.50002 7.95831 7.50002 7.49998C7.50002 7.04165 7.12502 6.66665 6.66669 6.66665C6.20835 6.66665 5.83335 7.04165 5.83335 7.49998ZM3.33335 15H10C10 14.0833 8.50835 13.3333 6.66669 13.3333C4.82502 13.3333 3.33335 14.0833 3.33335 15ZM18.3334 9.99998V11.6666H10.8334V9.99998M18.3334 6.66665V8.33331H10.8334V6.66665M18.3334 3.33331V4.99998H10.8334V3.33331H18.3334Z'
        fill='#FD8721'
      />
    </svg>
  )
}

export default SuspendIcon
