import { createAsyncThunk } from '@reduxjs/toolkit'
import { getExceptionPayload } from '../../../../lib/utils/get-exception-payload'
import { BeneficiaryService } from '../../../../services/api/initiator/beneficiary.service'
import { ApiRequestErrorType } from '../../../../types/api/api.types'
import { reqBeneficiaryDataType } from '../../../../types/initiator.types'

const beneficiaryService = new BeneficiaryService()

export const getBeneficiariesThunk = createAsyncThunk(
  '/transaction/get-beneficiaries',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await beneficiaryService.getBeneficiaries(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const createBeneficiaryThunk = createAsyncThunk(
  '/transaction/create-beneficiary',
  async (data: reqBeneficiaryDataType, { rejectWithValue }) => {
    try {
      const response = await beneficiaryService.createBeneficiary(data)
      console.log('response', response)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getAllBeneficiariesThunk = createAsyncThunk(
  '/transaction/get-all-beneficiaries',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await beneficiaryService.getAllBeneficiaries(token)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteBeneficiaryThunk = createAsyncThunk(
  '/transaction/delete-beneficiary',
  async (data: reqBeneficiaryDataType, { rejectWithValue }) => {
    try {
      const response = await beneficiaryService.deleteBeneficiary(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteBeneficiariesThunk = createAsyncThunk(
  '/transaction/beneficiaries',
  async (data: { checkedBeneficiaries: string[]; token: string }, { rejectWithValue }) => {
    try {
      const response = await beneficiaryService.deleteBeneficiaries(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const updateBeneficiaryThunk = createAsyncThunk(
  '/transaction/update-beneficiaries',
  async (data: reqBeneficiaryDataType, { rejectWithValue }) => {
    try {
      const response = await beneficiaryService.updateBeneficiary(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getBeneficiariesByOrgEmailThunk = createAsyncThunk(
  '/transaction/get-beneficiaries-by-org-email',
  async (data: reqBeneficiaryDataType, { rejectWithValue }) => {
    try {
      const response = await beneficiaryService.getBeneficiariesByOrgEmail(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
