import { createSlice } from '@reduxjs/toolkit'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { TotalBalancePropType } from '../../../types/organisms/total-balance-organism.type'
import {
  getProcessingRefillsThunk,
  getRefillsThunk,
  getWalletBalanceThunk,
  processOrgRefillsThunk,
  processSpecificRefillsThunk,
  processingRefillsThunk,
} from '../thunk/wallet.thunk'

export type getWalletBalanceState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestResponse: ApiRequestDataType<any>
  totalBalance: TotalBalancePropType
  organisationRefills: walletRefillsType[]
  processingRefills: walletRefillsType[]
  processRefills: unknown
}

export const initialState: getWalletBalanceState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  organisationRefills: [] as walletRefillsType[],
  processingRefills: [] as walletRefillsType[],
  processRefills: null,
  totalBalance: {
    currentAccounts: [],
    allAcountAmount: 0,
  },
}

export interface walletRefillsType {
  accountNumber: string
  amount: number
  date: string
  id: string
  status: string
  charges: number
  balance: number
}
const getWalletBalanceSlice = createSlice({
  name: 'getWallectBalanceSlice',
  initialState: initialState,
  reducers: {
    resetWalletState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
      state.totalBalance.allAcountAmount = 0
      state.totalBalance.currentAccounts = []
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getWalletBalanceThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getWalletBalanceThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.totalBalance.allAcountAmount = action.payload.balance
        console.log('fufilled', action.payload)
      })
      .addCase(getWalletBalanceThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
        console.log('rejected', action.payload)
      })

      // get wallet refills
      .addCase(getRefillsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getRefillsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.organisationRefills = action.payload
        // console.log('refil', action.payload)
      })
      .addCase(getRefillsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
        // console.log('rejected', action.payload)
      })

      // get process refils
      .addCase(getProcessingRefillsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getProcessingRefillsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        state.processingRefills = action.payload

        // console.log('fufilled', action.payload)
      })
      .addCase(getProcessingRefillsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
        // console.log('rejected', action.payload)
      })

      // process refils
      .addCase(processingRefillsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(processingRefillsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        // console.log('fufilled', action.payload)
      })
      .addCase(processingRefillsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
        // console.log('rejected', action.payload)
      })

      // specific refill
      .addCase(processSpecificRefillsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(processSpecificRefillsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        // console.log('fufilled', action.payload)
      })
      .addCase(processSpecificRefillsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
        // console.log('rejected', action.payload)
      })

      // org refill
      .addCase(processOrgRefillsThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(processOrgRefillsThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        // console.log('fufilled', action.payload)
      })
      .addCase(processOrgRefillsThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
        // console.log('rejected', action.payload)
      })
  },
})

export const { resetWalletState } = getWalletBalanceSlice.actions
export default getWalletBalanceSlice.reducer
