// general imports
import { IconPropsType } from '../types'

const LogoutIcon = ({ width = 48, height = 48, color = '#D30B0B' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M10 12V2M10 2L7 5M10 2L13 5'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LogoutIcon
