import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import {
  TransactionService,
  addAutomaticTransactionTypes,
  addAutomaticTransactionTypesV2,
  addManualTransactionTypes,
  addManualTransactionTypesV2,
} from '../../../services/api/transaction.service'
import { ApiRequestErrorType } from '../../../types/api/api.types'
// import {
//   LoginDataType,
//   RegistrationDataType,
//   ResetPasswordDataType,
// } from '../../../types/auth.types'

const transactionService = new TransactionService()

// export type ConfirmationRequestType = Pick<RegistrationDataType, 'email' | 'name'>

export const getAllTransactionsThunk = createAsyncThunk(
  '/transactions',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getAllTransactions(token)
      // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const getTransactionThunk = createAsyncThunk(
  '/transaction',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getTransaction(token)
      // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const geAllBeneficiariesThunk = createAsyncThunk(
  '/transaction/beneficiaries',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getAllBeneficiaries(token)
      // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const addAutomaticTransactionThunk = createAsyncThunk(
  '/transaction/beneficiariesId',
  async (data: addAutomaticTransactionTypes, { rejectWithValue }) => {
    try {
      const response = await transactionService.addAutomaticTransaction(data)
      // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const addAutomaticTransactionV2Thunk = createAsyncThunk(
  '/transaction/v2/beneficiariesId',
  async (data: addAutomaticTransactionTypesV2, { rejectWithValue }) => {
    try {
      const response = await transactionService.addAutomaticTransactionV2(data)
      // // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const addManualTransactionThunk = createAsyncThunk(
  '/transaction/manual',
  async (data: addManualTransactionTypes, { rejectWithValue }) => {
    try {
      const response = await transactionService.addManualTransaction(data)
      // // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const addManualTransactionV2Thunk = createAsyncThunk(
  '/transaction/v2/manual',
  async (data: addManualTransactionTypesV2, { rejectWithValue }) => {
    try {
      const response = await transactionService.addManualTransactionV2(data)
      // // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const postSinglePaymentThunk = createAsyncThunk(
  '/transaction/request-payment',
  async (post, { rejectWithValue }) => {
    try {
      const response = await transactionService.singlePaymentTransaction()
      // // (response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getTransactionHistoryThunk = createAsyncThunk(
  '/transaction/fetch-transaction-history',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getTransactionHistory(token)
      // // (response?.data)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getUpcomingBillThunk = createAsyncThunk(
  '/transaction/upcoming-bill',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await transactionService.getUpcomingBill(token)
      // // (response?.data)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const validateTransactionThunk = createAsyncThunk(
  '/transaction/approve/{transactionId}',
  async (data: { transactionId: string; token: string; type: string }, { rejectWithValue }) => {
    try {
      const response = await transactionService.validateTransaction(data)
      // // (response?.data)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const cancelTransactionThunk = createAsyncThunk(
  '/transaction/cancel/{transactionId}',
  async (data: { transactionId: string; token: string; type: string }, { rejectWithValue }) => {
    try {
      const response = await transactionService.cancelTransaction(data)
      // // (response?.data)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteTransactionThunk = createAsyncThunk(
  '/transaction/delete/{transactionId}',
  async (data: { transactionId: string; token: string; type: string }, { rejectWithValue }) => {
    try {
      const response = await transactionService.deleteTransaction(data)
      // // (response?.data)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const downloadTransactionThunk = createAsyncThunk(
  '/transaction/generate-pdf/',
  async (
    data: { transactionId: string; token: string; fileName?: string; index?: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await transactionService.downloadTransaction(data)
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)

      // link to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = `${data.fileName ? data.fileName : 'Transaction-' + data.transactionId}-${
        data.index ? '0' + data.index : ''
      }.pdf`
      // a.setAttribute('download', `Invoice-${data.transactionId}.pdf`)
      document.body.appendChild(a)
      a.click()

      // Releasing the object URL
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const downloadBulkTransactionThunk = createAsyncThunk(
  '/transaction/generate-bulk-pdf/',
  async (
    data: { transactionId: string; token: string; fileName?: string; index?: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await transactionService.downloadBulkTransaction(data)
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)

      // link to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = `${data.fileName ? data.fileName : 'Transaction-' + data.transactionId}-${
        data.index ? '0' + data.index : ''
      }.pdf`
      // a.setAttribute('download', `Invoice-${data.transactionId}.pdf`)
      document.body.appendChild(a)
      a.click()

      // Releasing the object URL
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export interface DownloadAllData {
  transactionId: string
  token: string
  fileName?: string
  index?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const downloadAllTransactionThunk = createAsyncThunk(
  '/transaction/download-all-file',
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: DownloadAllData[],
    { rejectWithValue },
  ) => {
    try {
      await Promise.all(
        data.map((data, index) => {
          downloadTransactionThunk({ transactionId: data.transactionId, token: data.token, index })

          // ('INDEX OF DOWNLOAD' + index)
          // const response = await transactionService.downloadTransaction(data)
          // // // (response?.data)

          // const blob = new Blob([response.data])
          // const url = window.URL.createObjectURL(blob)

          // // link to trigger the download
          // const a = document.createElement('a')
          // a.href = url
          // a.download = data.fileName || 'Downloaded Transaction'
          // document.body.appendChild(a)
          // a.click()

          // // Releasing the object URL
          // window.URL.revokeObjectURL(url)
          // document.body.removeChild(a)
        }),
      )
      // ('All files downloaded successfully')
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

// ///////

export const exportAdminCSVThunk = createAsyncThunk(
  '/transaction/export-csv',
  async (data: { token: string; fileName?: string; index?: number }, { rejectWithValue }) => {
    try {
      const response = await transactionService.exportAdminCSV(data.token)
      // // (response?.data)
      // if (!response.data.ok) throw new Error('Failed to download file')
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)

      // link to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = data.fileName || `Transaction List  ${data.index ? ` - 0${data.index}` : ''}`
      document.body.appendChild(a)
      a.click()

      // Releasing the object URL
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const exportSuperAdminCSVThunk = createAsyncThunk(
  '/profile/export-org-csv',
  async (data: { token: string; fileName?: string; index?: number }, { rejectWithValue }) => {
    try {
      const response = await transactionService.exportSuperAdminCSV(data.token)
      // (response)
      // if (!response.data.ok) throw new Error('Failed to download file')
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)

      // link to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = data.fileName || `Transaction List  ${data.index ? ` - 0${data.index}` : ''}`
      document.body.appendChild(a)
      a.click()

      // Releasing the object URL
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
// export const emailVerificationCodeThunk = createAsyncThunk(
//   '/auth/verify-email',
//   async (data: OtpVerificationType, { rejectWithValue }) => {
//     try {
//       const response = await authService.emailVerificationCode(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const createUserAccountThunk = createAsyncThunk(
//   '/auth/create-account',
//   async (data: Omit<RegistrationDataType, 'confirmPassword'>, { rejectWithValue }) => {
//     try {
//       const response = await authService.createUserAccount(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const resendEmailConfirmationCode = createAsyncThunk(
//   '/auth/resend-email-verification',
//   async (data: ConfirmationRequestType, { rejectWithValue }) => {
//     try {
//       const response = await authService.resendEmailConfirmationCode(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const verifyOtpAfterLoginThunk = createAsyncThunk(
//   '/auth/otp-login',
//   async (data: OtpVerificationType, { rejectWithValue }) => {
//     try {
//       const response = await authService.verifyOTPAfterLogin(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const loginUserThunk = createAsyncThunk(
//   '/auth/login',
//   async (data: LoginDataType, { rejectWithValue }) => {
//     try {
//       const response = await authService.loginuser(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const resendOptCodeThunk = createAsyncThunk(
//   '/auth/resendOtp',
//   async (data: LoginDataType, { rejectWithValue }) => {
//     try {
//       const response = await authService.loginuser(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const forgotPasswordThunk = createAsyncThunk(
//   '/auth/forgot-password',
//   async (data: ConfirmationRequestType, { rejectWithValue }) => {
//     try {
//       const response = await authService.forgotPassword(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const resetPasswordThunk = createAsyncThunk(
//   '/auth/resetPassword',
//   async (data: ResetPasswordDataType, { rejectWithValue }) => {
//     try {
//       const response = await authService.resetPassword(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )

// export const verifyResetOtpCodeThunk = createAsyncThunk(
//   '/auth/verify-reset-otp',
//   async (data: OtpVerificationType, { rejectWithValue }) => {
//     try {
//       const response = await authService.verifyResetOtpCode(data)
//       return response?.data
//     } catch (ex) {
//       return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
//     }
//   },
// )
