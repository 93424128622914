// general imports
import { IconPropsType } from '../types'

const ArrowsIcon = ({ width = 10, height = 9, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.29403 8.65767L4.52699 7.89915L7.19034 5.2358H0.5V4.12784H7.19034L4.52699 1.46875L5.29403 0.705966L9.26989 4.68182L5.29403 8.65767Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowsIcon
