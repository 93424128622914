import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../lib/utils/addTokenReq'
import { organisationRequestType, refillRequestType } from './organisation/organisation.service'

export class WalletService {
  public async getWalletBalance(token: string) {
    return await (await publicApiRequest()).get('/transaction/wallet', addTokenToHeaders(token))
  }
  public async refillWallet(data: { token: string; amount: string; accountNumber: string }) {
    const { token, accountNumber, amount } = data
    return await (
      await publicApiRequest()
    ).post('/transaction/refill-wallet', { accountNumber, amount }, addTokenToHeaders(token))
  }

  public async getRefills(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/wallet-refills', addTokenToHeaders(token))
  }
  public async getProcessingRefills(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/processing-refills', addTokenToHeaders(token))
  }
  public async processingRefills(token: string) {
    return await (
      await publicApiRequest()
    ).patch('/transaction/process-refills', token, addTokenToHeaders(token))
  }
  // orgRefills
  public async processOrgRefills(data: organisationRequestType) {
    return await (
      await publicApiRequest()
    ).patch(
      `/transaction/process-organisation-refills/${data.organisationId}`,
      data,
      addTokenToHeaders(data.token ?? ''),
    )
  }

  public async processSpecificRefills(data: refillRequestType) {
    return await (
      await publicApiRequest()
    ).patch(
      `/transaction/process-refill/${data.refillId}`,
      data,
      addTokenToHeaders(data.token ?? ''),
    )
  }
}
