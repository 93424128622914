import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { TransactionHistoryDataType } from '../../../types/transaction.types'
import {
  cancelTransactionThunk,
  deleteTransactionThunk,
  exportAdminCSVThunk,
  exportSuperAdminCSVThunk,
  getTransactionHistoryThunk,
  validateTransactionThunk,
} from '../thunks/transactions.thunk'
export type TransactionHistoryState = {
  transactions: TransactionHistoryDataType[]
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestResponse: ApiRequestDataType<any>
}

const initialState: TransactionHistoryState = {
  transactions: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
    error: undefined,
    success: false,
  },
}

const transactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {
    resetTransactionHistoryState: (state) => {
      state.transactions = []
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
    },
    resetTransactionHistoryReqRes: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
      state.requestResponse.data = null
      state.requestResponse.success = false
    },
    setTransactionHistory: (state, action: PayloadAction<TransactionHistoryDataType[]>) => {
      state.transactions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionHistoryThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getTransactionHistoryThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.transactions = action.payload
      })
      .addCase(getTransactionHistoryThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(exportAdminCSVThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(exportAdminCSVThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        // state.transactions = action.payload
        console.log(action.payload)
      })
      .addCase(exportAdminCSVThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(exportSuperAdminCSVThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(exportSuperAdminCSVThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        // state.transactions = action.payload
        console.log(action.payload)
      })
      .addCase(exportSuperAdminCSVThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(validateTransactionThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
        state.isLoading = true
      })
      .addCase(validateTransactionThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.requestResponse.success = true
        state.isLoading = false
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(validateTransactionThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.requestResponse.success = false
        state.isLoading = false
      })
      .addCase(cancelTransactionThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
        state.isLoading = true
      })
      .addCase(cancelTransactionThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.requestResponse.success = true
        state.isLoading = false
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(cancelTransactionThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.requestResponse.success = false
        state.isLoading = false
      })
      .addCase(deleteTransactionThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
        state.isLoading = true
      })
      .addCase(deleteTransactionThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.requestResponse.success = true
        state.isLoading = false
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(deleteTransactionThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
        state.requestResponse.success = false
        state.isLoading = false
      })
  },
})

export const {
  resetTransactionHistoryState,
  resetTransactionHistoryReqRes,
  setTransactionHistory,
} = transactionHistorySlice.actions

export default transactionHistorySlice.reducer
