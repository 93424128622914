import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api/api.types'
import { RegistrationDataType } from '../../../../types/auth.types'
import { OverviewDataType } from '../../../../types/overview.type'
import { getSuperAdminOverviewhunk } from '../thunk/overview.thunk'

export type RegistrationState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: RegistrationDataType[] | any
  overviewData: OverviewDataType | null
}

export const initialState: RegistrationState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  users: [],
  overviewData: null,
}

const superAdminDashboardSlice = createSlice({
  name: 'superAdminDashboardSlice',
  initialState: initialState,
  reducers: {
    resetSuperAdminDashboardState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
    setSuperAdminDashboard: (state, action) => {
      state.users = { ...state.users, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuperAdminOverviewhunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
        state.requestResponse.data = null
      })
      .addCase(getSuperAdminOverviewhunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.overviewData = action.payload
      })
      .addCase(getSuperAdminOverviewhunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetSuperAdminDashboardState, setSuperAdminDashboard } =
  superAdminDashboardSlice.actions
export default superAdminDashboardSlice.reducer
