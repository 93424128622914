import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import {
  SuperAdminOverviewService,
  dateOverview,
} from '../../../../services/api/super-admin/dashboard/overview.service'

import { getExceptionPayload } from '../../../../lib/utils/get-exception-payload'
import { ApiRequestErrorType } from '../../../../types/api/api.types'

const SuperAdminOverview = new SuperAdminOverviewService()

export const getSuperAdminOverviewhunk = createAsyncThunk(
  '/transaction/overview',
  async (data: dateOverview, { rejectWithValue }) => {
    try {
      const response = await SuperAdminOverview.getTransactionOverview(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
