import { publicApiRequest } from '../../../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../../../lib/utils/addTokenReq'

export interface dateOverview {
  startDate: string
  endDate: string
  token: string
}

/**
 * manage dashboar overview super adm service
 */

export class SuperAdminOverviewService {
  /**
   * Get list of transactions on super admin dashboard
   * @returns Promise<any>
   */
  public async getTransactionOverview(data: dateOverview) {
    return await (
      await publicApiRequest()
    ).patch(
      '/transaction/overview',
      {
        startDate: data.startDate,
        endDate: data.endDate,
      },
      addTokenToHeaders(data.token),
    )
  }
}
