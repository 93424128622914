import { IconPropsType } from '../types'

const GreenOptionsIcon = ({ width = 26, height = 26 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.41665 13.0003H5.42748M13 13.0003H13.0108M20.5833 13.0003H20.5941M6.49998 13.0003C6.49998 13.5986 6.01495 14.0837 5.41665 14.0837C4.81834 14.0837 4.33331 13.5986 4.33331 13.0003C4.33331 12.402 4.81834 11.917 5.41665 11.917C6.01495 11.917 6.49998 12.402 6.49998 13.0003ZM14.0833 13.0003C14.0833 13.5986 13.5983 14.0837 13 14.0837C12.4017 14.0837 11.9166 13.5986 11.9166 13.0003C11.9166 12.402 12.4017 11.917 13 11.917C13.5983 11.917 14.0833 12.402 14.0833 13.0003ZM21.6666 13.0003C21.6666 13.5986 21.1816 14.0837 20.5833 14.0837C19.985 14.0837 19.5 13.5986 19.5 13.0003C19.5 12.402 19.985 11.917 20.5833 11.917C21.1816 11.917 21.6666 12.402 21.6666 13.0003Z'
        stroke='#009fe3'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default GreenOptionsIcon
