import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import {
  OrganisationService,
  organisationRequestType,
  uploadOrganisationProfileInfoData,
} from '../../../services/api/organisation/organisation.service'
import { ApiRequestErrorType } from '../../../types/api/api.types'
import {
  organisationProviderRequestTypes,
  organisationUserRequestType,
} from '../../../types/organisation.type'

const organisationService = new OrganisationService()

export const getAllOrganisationsThunk = createAsyncThunk(
  '/auth/organisations',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await organisationService.getAllOrganisations(token)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const getUnapprovedOrganisationsThunk = createAsyncThunk(
  '/auth/organisations-request',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await organisationService.getUnapprovedOrganisations(token)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const deleteOrganisationThunk = createAsyncThunk(
  '/auth/organisations/delete-organization/userId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.deleteOrganisation(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const deleteUnapprovedOrganisationsThunk = createAsyncThunk(
  '/auth/organisations/delete-organization-request/orgId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.deleteUnapprovedOrganisations(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const approvedOrganisationThunk = createAsyncThunk(
  '/auth/approve-organisation/OrgId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.approvedOrganisation(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const suspendOrganisationThunk = createAsyncThunk(
  '/auth/organisations/suspend-organisation/userId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.suspendOrganisation(data)
      console.log('This is the suspension call')
      console.log(response?.data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const singleOrganisationDetailsThunk = createAsyncThunk(
  '/profile/organisation/organisationId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.singleOrganisationDetails(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getOrganisationUsersThunk = createAsyncThunk(
  '/auth/users/get-organisation-users/orgId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.getOrganisationUsers(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const getOrganisationNumberOfUsersThunk = createAsyncThunk(
  '/auth/organisations/number-of-users/orgId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.getOrganisationNumberOfUsers(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const getOrganisationOwnerThunk = createAsyncThunk(
  '/profile/owner/orgId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.getOrganisationOwner(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const getOrganisationTransactionsThunk = createAsyncThunk(
  '/transaction/id',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.getOrganisationTransactions(data)
      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const getOrganisationWalletDetailsThunk = createAsyncThunk(
  '/transaction/org-wallet-detail/orgId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.getOrganisationWalletDetails(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const getOrganisationServiceProvidersThunk = createAsyncThunk(
  '/transaction/get-org-service-providers/orgId',
  async (data: organisationRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.getOrganisationServiceProviders(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const deleteOrganisationServiceProvidersThunk = createAsyncThunk(
  '/transaction/delete-service-provider/orgId',
  async (data: organisationProviderRequestTypes, { rejectWithValue }) => {
    try {
      const response = await organisationService.deleteOrganisationServiceProviders(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const deleteOrganisationUserThunk = createAsyncThunk(
  ' /auth/users/remove-org-user/id',
  async (data: organisationUserRequestType, { rejectWithValue }) => {
    try {
      const response = await organisationService.deleteOrganisationUser(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const editOrganisationCoverPhotoThunk = createAsyncThunk(
  '/profile/edit-organisation',
  async (data: uploadOrganisationProfileInfoData, { rejectWithValue }) => {
    try {
      const response = await organisationService.editOrganisationCoverPhoto(data)
      // console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
export const getOrganisationsThunk = createAsyncThunk(
  '/auth/organisation',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await organisationService.getOrganisations(token)
      console.log(response?.data)

      return response?.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
