import { createSlice } from '@reduxjs/toolkit'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { TransactionCard } from '../../../types/molecules/recent-transactions-component-organism.type'
import { getTransactionThunk } from '../thunks/transactions.thunk'

export type getTransactionsState = {
  requestResponse: ApiRequestDataType
  recentTransactions: TransactionCard[]
}

export const initialState: getTransactionsState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  recentTransactions: [] as TransactionCard[],
}

const getTransactionsSlice = createSlice({
  name: 'getTransactionsSlice',
  initialState: initialState,
  reducers: {
    resetGetTransactionsState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
    },
    updateData: (state, action) => {
      state.recentTransactions = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTransactionThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(getTransactionThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
        state.recentTransactions = action.payload
        console.log(action.payload)
      })
      .addCase(getTransactionThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.data = action.payload
        state.requestResponse.error = {} as StoredErrorResponseType
      })
  },
})

export const { resetGetTransactionsState, updateData } = getTransactionsSlice.actions
export default getTransactionsSlice.reducer
